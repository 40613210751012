import {EventEmitter, Injectable} from '@angular/core';
import {OrderHistoryReport, ReportService} from '@common/shared/services/Report/report.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {StateType, StateTypeStrings} from '@common/trade/models/trade-state-enum';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Trader} from '@common/trader/models/trader';
import {translate} from '@common/locale/servises/translator.service';

@Injectable({
  providedIn: 'root'
})

export class TradeHistoryService {

  private updateArrayOrdersHistory: EventEmitter<OrderHistoryReport[]> = new EventEmitter<OrderHistoryReport[]>();
  private _reports: OrderHistoryReport[] = [];
  private _tradeHistoryAll: OrderHistoryReport[] = [];

  constructor(private reportService: ReportService,
              private symbolStorageService: SymbolStorageService,
              private trader: Trader) {
  }

  get UpdateArrayOrdersHistory() {
    return this.updateArrayOrdersHistory;
  }

  get OrdersHistory(): OrderHistoryReport[] {
    return this._reports;
  }
  get OrdersForOpen(): OrderHistoryReport[] {
    return this._tradeHistoryAll;
  }

  public destroyArray() {
    this._reports = [];
    this._tradeHistoryAll = [];
    this.reportService.resetPages();
  }

  public run(typePlatform: string, orderRows?: number, goToPage?: boolean) {
    this.reportService.cfaLoadOrderPage(typePlatform, goToPage, orderRows).then(async () => {

      const reports = this.reportService.OrderHistoryReports;

      this._tradeHistoryAll = reports;

      const data = await this.checkingForIdenticalOrderId(reports);

      this._reports = data.sort((a, b) => Date.parse(a.commandTime) < Date.parse(b.commandTime) ? 1 : -1);

      this.updateArrayOrdersHistory.emit(this._reports);

    });
  }

  private async checkingForIdenticalOrderId(array: OrderHistoryReport[]): Promise<OrderHistoryReport[]> {
    let identicalOrders = [];
    const temp = [];
    const tmpArray = [];
    const itemCheck = (item: OrderHistoryReport) => {
      if (tmpArray.indexOf(item.orderID) === -1) {
        tmpArray.push(item.orderID);
        return true;
      }
      return false;
    };

    array.filter((item) => itemCheck(item)).forEach((e) => {
      identicalOrders = array.filter( item => item.orderID === e.orderID)
                             .sort((a, b) => Date.parse(a.commandTime) < Date.parse(b.commandTime) ? 1 : -1);

      if (identicalOrders.length > 1) {
        const s = this.createSummaryOrder(identicalOrders);
        temp.push(s);
      } else {
        temp.push(e);
      }
    });

    return temp;
  }

  private sumProp(array: OrderHistoryReport[], key: string, all = true): number {
    let total = 0;
    for ( const item of array) {
      if (!all && item.stateAction === 'CLOSE MARKET') {
        total += item[key];
      }

      if (all) {
        total += item[key];
      }
    }

    return total;
  }

  private createSummaryOrder(identicalOrders: OrderHistoryReport[]): OrderHistoryReport {
    const temp = identicalOrders[0];

    return {
      clientOrderID: temp.clientOrderID,
      commandMargin: temp.commandMargin,
      commandOriginID: temp.commandOriginID,
      commandPrice: temp.commandPrice,
      commandSenderID: temp.commandSenderID,
      commandTime: temp.commandTime,
      commandTypeID: temp.commandTypeID,
      commandVolume: temp.commandVolume,
      comment: temp.comment,
      dealID: temp.dealID,
      expirationTime: temp.expirationTime,
      margin: temp.margin,
      openPrice: temp.openPrice,
      orderID: temp.orderID,
      orderTypeID: temp.orderTypeID,
      stopLoss: temp.stopLoss,
      symbolID: temp.symbolID,
      takeProfit: temp.takeProfit,
      traderID: temp.traderID,
      trailingStop: temp.trailingStop,
      volume: this.sumProp(identicalOrders, 'volume', false),
      stateAction: temp.stateAction,
      profit: this.sumProp(identicalOrders, 'profit'),
      transactionFee: this.sumProp(identicalOrders, 'transactionFee'),
      swaps: this.sumProp(identicalOrders, 'swaps'),
    };
  }

  public getSymbolName(symbolID: number): string {
    return this.symbolStorageService.findSymbolById(symbolID).SymbolName;
  }

  public getOrderType(tradeType: number): string {
    if (tradeType === 1) {
      return translate('TradeModule_TradeTypeStrings_Sell');
    } else if (tradeType === 2) {
      return translate('TradeModule_TradeTypeStrings_Buy');
    } else {
      return 'Unsupported Type';
    }
  }

  public OpenTime(date: Date): Date {
    return date;
  }

  public BaseCurrency(symbolName: string): string {
    return symbolName.substr(0, 3);
  }

  public showVolume(v: number, symbolID: number) {
    const symbol = this.symbolStorageService.findSymbolById(symbolID);
    return OperationsWithVolume.convertFromVolumeToLots(v, symbol.ContractSize, this.trader.LotDelimeter);
  }

  public ShowValue(value: number): boolean {
    return value !== 0;
  }

  public ShowValueMargin(value: number): boolean {
    return value > 0;
  }

  orderTypeIsBuy(tradeType: number): boolean {
    if (tradeType === 1) {
      return false;
    } else if (tradeType === 2) {
      return true;
    }
  }

  // дополнительная проверка если профит положительный, а pips отрицательный то он передается без минуса, и наоборот
  public checkingPipsResult(profit: number, openPrice: number, closePrice: number, symbolID: number) {
    if ( profit !== 0) {
      const pips = this.calculatePips(openPrice, closePrice, symbolID);
      if (profit > 0) {
        return Math.abs(pips);
      } else {
        return Math.abs(pips) - Math.abs(pips) * 2;
      }
    } else {
      return 0;
    }

  }

  private calculatePips(openPrice: number, closePrice: number, symbolId: number): number {
    return (closePrice - openPrice) * Math.pow(10, this.symbolStorageService.findSymbolById(symbolId).PipDecimalPlaces);
  }

  public getTotal(profit: number, order: OrderHistoryReport): number {
    const expenses = Math.abs(order.transactionFee) - order.swaps;
    return profit - expenses;
  }

  public getTotalIsOpen(profit: number, orderArray: OrderHistoryReport[]): number {
    let expenses = 0;
    orderArray.forEach((e: OrderHistoryReport) => {
      expenses = expenses + e.transactionFee + e.swaps;
    });
    return profit - expenses;
  }

  // условие для подробного ордера, какие данные показывать, даже если они нулевые

  public ShowValueForIsOpen(value: string): boolean {
    return value === 'CLOSE MARKET';
  }

  public getCommandSign(volume: number): string {
    if (volume > 0) {
      return '+';
    } else {
      return '';
    }
  }

  public getStateName(type: StateType): string {
    return StateTypeStrings[type];
  }

}
