import {Component, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';
import {LocaleModule} from '@common/locale/locale.module';
import {ConnectionService} from 'ng-connection-service';
import {Environment} from '@common/environment';

@Component({
  selector: 'app-internet-connection',
  templateUrl: './internet-connection.component.html',
  styleUrls: ['./internet-connection.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LocaleModule
  ]
})

export class InternetConnectionComponent implements OnInit {

  private _noInternetConnection = false;
  private _isConnectedInternet = true;
  private isConnectedInternet = true;

  constructor(private connectionService: ConnectionService) {
  }

  ngOnInit() {

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnectedInternet = isConnected.hasNetworkConnection;
      if (this.isConnectedInternet) {
        this._noInternetConnection = false;
        setTimeout(() => this._isConnectedInternet = true, 3000);
      } else {
        this._noInternetConnection = true;
        this._isConnectedInternet = false;
      }
    });

  }

  public get noInternetConnection(): boolean {
    return this._noInternetConnection;
  }

  public get IsConnectedInternet() {
    return this._isConnectedInternet;
  }

  public get IsMobile(): boolean {
    return Environment.IsMobileVersion;
  }
}
