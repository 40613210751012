import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {Trader} from '@common/trader/models/trader';
import {TradeAction} from '@common/actions/trade.action';
import {PriceDirection} from '@common/symbol/models/last-quote-info';
import {TradeType} from '@common/trade/models/trade-type';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {Symbol} from '@common/symbol/models/symbol';
import {OpenOrderForm} from '@common/trade/components/open-order-form/open-order-form';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Settings} from '@common/trader/models/settings';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {Environment} from '@common/environment';
import {ChartService} from '@common/charting/services/chart.service';
import {environment} from '../../../../environments/environment';
import {QuotesComponent} from '@common/quote-board/components/quotes/quotes.component';
import {SettingsService} from '@common/trader/services/settings.service';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {AppConfig} from '@common/configuration/app-config';
import {SymbolInfoService} from '@common/shared/services/symbol-info.service';
import {TradeService} from '@common/trade/services/trade.service';
import {IColorPickerEvent} from '@chartiq-core/components/color-picker/color-picker.component';

@Component({
  selector: '[app-symbol]',
  templateUrl: './symbol.component.html',
  styleUrls: ['./symbol.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SymbolComponent extends OpenOrderForm implements OnInit, OnDestroy {
  private priceSubscription: ISmartSubscription;
  private symbolChangeSubscription: ISmartSubscription;
  private oneClickAmountChangeSubscription: ISmartSubscription;
  private logger = LoggerFactory.getLogger('SymbolComponent');

  private _isLoading = false;

  private _textIcon: string;

  public appName = '';

  get IsLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set symbolModel(v: Symbol) {
    this.checkSubscribePrice(v);
    this.Symbol = v;
    this.countSymbols.emit();
  }

  @Output() countSymbols: EventEmitter<void> = new EventEmitter<void>();

  // при прокрутке таблицы символов идет проверка на подписку изменения цен, если её нет, то подписывается
  private checkSubscribePrice(v: Symbol) {
    this.symbolService.checkSubscribePrice(v);
  }

  constructor(private chartService: ChartService,
              private detector: ChangeDetectorRef,
              private symbolSelector: SymbolSelectService,
              private trader: Trader,
              protected tradeAction: TradeAction,
              settings: Settings,
              private elRef: ElementRef,
              private quotesComponent: QuotesComponent,
              private settingsService: SettingsService,
              private symbolService: SymbolService,
              private appConfig: AppConfig,
              private symbolInfoService: SymbolInfoService,
              private tradeService: TradeService) {
    super(settings);
    this.oneClickAmountChangeSubscription = this.settings.OneClickAmountChanged.subscribe(() => this.detector.detectChanges());

    this.appName = this.appConfig.Settings.common.AppName;
  }

  public get Amount(): number | string {
    if (this.amount === '') {
      return this.amount;
    }

    if (this.Symbol.LastQuote !== undefined) {
      return this.amount || this.tradeService.getVolumeForOpenOrders(this.Symbol, this.Symbol.LastQuote.Bid );
    }
  }

  public set Amount(v: number | string) {

    if (v === undefined || this.Amount === v) {
      return;
    }

    this.amount = v.toString().trim();

    if (v !== '' && !isNaN(Number(v))) {
      this.order.Volume = Number(v);
    }
  }

  public async openChart(event: MouseEvent) {
    event.stopPropagation();
    await this.chartService.setSymbol(this.SymbolName);
  }

  public get IsOpenChartAvailable(): boolean {
    return environment.chartCountAvailable !== undefined && environment.chartCountAvailable.length === 1 && !this.IsMobileVersion;
  }

  public onClick(event: MouseEvent) {
    this.toggleForm();
  }

  public async onSellClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this._isLoading = true;
    await this.onTradeButtonClick(TradeType.Sell);
    this._isLoading = false;
    this.detector.detectChanges();
  }

  public async onBuyClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    this._isLoading = true;
    await this.onTradeButtonClick(TradeType.Buy);
    this._isLoading = false;
    this.detector.detectChanges();
  }

  public async onTradeButtonClick(type: TradeType) {
    await this.openOrder(type);
  }

  private async openOrder(type: TradeType) {
    this.TradeType = type;
    const order = this.compileOrder(this.tradeService);

    await this.tradeAction.openOrder(order.deepCopy());
    this.clearOrder(order);
  }

  private clearOrder(order: TradeDTO) {
    order.StopLoss = 0;
    order.TakeProfit = 0;
    order.OpenPrice = 0;
    order.Volume = 0;
    order.Type = undefined;
  }

  public toggleForm() {
    if (this.IsSelected) {
      this.symbolSelector.reset();
    } else {
      this.symbolSelector.selectSymbol(this.symbolModel);
    }
  }

  public async toggleFavorite(event: MouseEvent) {
    event.stopPropagation();
    await this.symbolSelector.toggleFavorites(this.symbolModel.SymbolId);

    // при нажатии на иконку звездочки список фаворит обновляется и отображает актуальные значения
    this.quotesComponent.addInFavorite();
  }

  public getColor(direction: PriceDirection) {
    return {
      'red-color': direction.IsDown,
      'green-color': direction.IsUp,
    };
  }

  public ngOnInit(): void {
    this.subscribeToPriceChange();
    this.symbolChangeSubscription = this.symbolSelector.SymbolChange.subscribe(() => this.detector.detectChanges());
    this.setDefaultAmount();

    this._textIcon = this.Symbol.SymbolName['0'].toUpperCase();

    if (this.IsZeTradex && this.Symbol.getSymbolIconPath() === undefined) {
      this.Symbol.setSymbolIconPath(this.symbolInfoService.getSymbolIcon(this.Symbol));
    }
  }

  private setDefaultAmount() {
  }

  private subscribeToPriceChange() {
    if (this.priceSubscription) {
      this.logger.warn(`${this.symbolModel.SymbolName} already has subscription`);
      return;
    }

    this.priceSubscription = this.symbolModel.PriceChange.subscribe(() => this.onQuote());
    this.logger.info('Subscribed to price change');

    this.detector.detectChanges();
  }

  private onQuote(): void {
    this.detector.detectChanges();
    this.logger.debug('Emit detectChanges');
    // this.changePriceSymbolsConsoleLog();

    if (this.symbolModel.LastPrice !== null && this.appConfig.Settings.showSymbolPercentChangeDay !== undefined && this.appConfig.Settings.showSymbolPercentChangeDay) {
      this.percentChange();
    }
  }

  // метод логирует измиение цен для символов
  private changePriceSymbolsConsoleLog() {
    const data = new Date();
    console.log('SymbolName:', this.Symbol.SymbolName, '/', 'SymbolID:', this.Symbol.SymbolId, '/', 'Bid:', this.Symbol.LastQuote.Bid,
      '/', 'Ask:', '/', this.Symbol.LastQuote.Ask, 'ServerTime:', this.Symbol.LastQuote.ServerTime, '/', 'TimeReceipt', data.toUTCString());
  }

  public ngOnDestroy(): void {
    this.unsubscribeFormPriceChange();
    this.symbolChangeSubscription.unsubscribe();
    this.oneClickAmountChangeSubscription.unsubscribe();
    this.detector.detach();
  }

  private unsubscribeFormPriceChange() {
    if (this.priceSubscription) {
      this.priceSubscription.unsubscribe();
      this.priceSubscription = null;
    } else {
      this.logger.warn(`${this.symbolModel.SymbolName} has no subscription`);
    }
  }

  public onFormMouseDown(event: MouseEvent) {
    this.elRef.nativeElement.classList.add('block-move');
    event.stopPropagation();
  }

  public onFormMouseUp(event: MouseEvent) {
    this.elRef.nativeElement.classList.remove('block-move');
  }

  public get IsReadOnlyAccount(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  public get IsSelected(): boolean {
    return this.symbolSelector.CurrentSymbol === this.symbolModel;
  }

  public get SymbolName(): string {
    return this.symbolModel.SymbolName;
  }

  public get SymbolIconPath(): string {
    return this.symbolModel.getSymbolIconPath();
  }

  get TextIcon(): string {
    return this._textIcon;
  }

  public get SymbolId(): number {
    return this.symbolModel.SymbolId;
  }

  public get Bid(): PriceDirection {
    if (!this.symbolModel) {
      return PriceDirection.empty();
    }
    return this.symbolModel.LastQuoteInfo.Bid;
  }

  public get Ask(): PriceDirection {
    if (!this.symbolModel) {
      return PriceDirection.empty();
    }
    return this.symbolModel.LastQuoteInfo.Ask;
  }

  public get Spread(): number {
    if (!this.symbolModel || !this.symbolModel.LastQuote) {
      return 0;
    }
    return this.symbolModel.LastQuote.Spread / this.symbolModel.PipSize;
  }

  public get IsFavorite(): boolean {
    return this.symbolSelector.isFavorite(this.symbolModel.SymbolId);
  }

  public get PipDecimalPlaces(): number {
    return this.symbolModel.PipDecimalPlaces;
  }

  public get symbolModel(): Symbol {
    return this.Symbol;
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  // при включении торговать в лотах идет конвертация

  public showStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.Symbol.TradingStep, this.Symbol.ContractSize, this.trader.LotDelimeter);
    }
    return this.Symbol.TradingStep;
  }

  public get DecimalPlaces(): number {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.numberOfDigitsAfterDot(this.showStep());
    }
    return this.symbolModel.VolumeDecimalPlaces;
  }

  public getShowSymbolPercentChangDay(): boolean {
    if (this.appConfig.Settings.showSymbolPercentChangeDay !== undefined) {
      return this.appConfig.Settings.showSymbolPercentChangeDay;
    } else {
      return false;
    }
  }

  public getPercentChangeFormatted(symbol: Symbol): string {
    return `${Math.abs(Number(OperationsWithVolume.setFormatPercent(symbol.PercentChange)))}%`;
  }

  private percentChange() {
    const avgValue = (this.symbolModel.Ask + this.symbolModel.Bid) / 2;
    this.symbolModel.PercentChange = OperationsWithVolume.percentChange(this.symbolModel.LastPrice, avgValue);
  }

  get IsMobileVersion(): boolean {
    return Environment.IsMobileVersion;
  }

  public get IsZeTradex() {
    return this.appConfig.Settings.common.AppName === 'Zetradex';
  }

  public getColorTextForPercentChange(): string {
    if (this.Symbol.PercentChange > 0) {
      return 'positive-percent-change-value';
    }

    if (this.Symbol.PercentChange < 0) {
      return 'negative-percent-change-value';
    }

    return '';
  }

  public get ClassForMobileVersion(): string {
    if (this.IsMobileVersion) {
      if (this.IsZeTradex) {
        return 'z-trade-buttons-mobile';
      }
      return 'trade-buttons-mobile';
    }
    return '';
  }

}
