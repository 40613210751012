import {Component, OnDestroy, OnInit} from '@angular/core';
import {Environment} from '@common/environment';
import {LocaleModule} from '@common/locale/locale.module';
import {NgClass, NgIf} from '@angular/common';
import {TerminalService} from '@common/trade/services/terminal.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-state-server-connection',
  templateUrl: './state-server-connection.component.html',
  styleUrls: ['./state-server-connection.component.scss'],
  standalone: true,
  imports: [
    LocaleModule,
    NgIf,
    NgClass
  ]
})

export class StateServerConnectionComponent implements OnInit, OnDestroy {

  private _isConnectedToTheServer = false;
  private _isShowNotificationStateConnectionServer = true;
  private stateConnectionServerSubscribe: Subscription;

  constructor(private terminalService: TerminalService) {
  }

  ngOnInit() {
    this.stateConnectionServerSubscribe = this.terminalService.StateConnectionServerEvent().subscribe((state: string) => {
      this._isShowNotificationStateConnectionServer = false;
      if (state === 'no') {
        this._isConnectedToTheServer = false;
      }

      if (state === 'yes') {
        this._isConnectedToTheServer = true;
        setTimeout(() => this._isShowNotificationStateConnectionServer = true, 3000);
      }
    });
  }

  ngOnDestroy() {
    if (this.stateConnectionServerSubscribe) {
      this.stateConnectionServerSubscribe.unsubscribe();
      this.stateConnectionServerSubscribe = undefined;
    }
  }

  public get IsConnectedToTheServer(): boolean {
    return this._isConnectedToTheServer;
  }

  public get IsShowNotificationStateConnectionServer() {
    return this._isShowNotificationStateConnectionServer;
  }

  public get IsMobile(): boolean {
    return Environment.IsMobileVersion;
  }

  public getColorBg(): string {
    if (this.IsConnectedToTheServer) {
      return 'connect-server';
    }
    return 'no-connect-server';
  }

}
