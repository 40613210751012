import {QuoteTick} from '@common/symbol/models/quote-tick';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Notification_ItemTick} from '../../connection/classes.g';
import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Symbol} from '@common/symbol/models/symbol';
import {AppConfig} from '@common/configuration/app-config';
import {Injectable} from '@angular/core';
import {Environment} from '@common/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteMessageProcessor extends AbstractMessageProcessor {
  private logSymbolDelay = new Map<string, boolean>();
  private arrivaLogger = LoggerFactory.getLogger('Arriva');

  public constructor(serverConnection: ServerInteractionService,
                     private symbolStorage: SymbolStorageService, private config: AppConfig) {
    super(serverConnection);
    this.logger = LoggerFactory.getLogger('QuoteMessageProcessor');
  }

  public subscribe(): void {
    const subscription = this.serverConnection.on(Notification_ItemTick.GetMessageType(),
      (answer) => this.onQuoteHandle(answer),
      error => this.logger.error('Quote handling error:', error));

    this.saveSubscription(Notification_ItemTick.GetMessageType(), subscription);
    this.logger.info(`Subscribed for ${Notification_ItemTick.GetMessageType()}`);
  }

  private async onQuoteHandle(tick: Notification_ItemTick): Promise<void> {
    if (!tick) {
      throw new Error('Incorrect Item Tick');
    }
    try {
      const symbolId = Number(tick.ItemID);
      const symbol = this.findSymbolById(symbolId);

      if (!symbol) {
        this.logger.warn('Skipping quote as no symbol with id ' + symbolId + ' was found.');
        return;
      }

      const parsedTick = await this.parseQuote(tick, symbol);

      await this.tickRoutine(parsedTick, symbol);
    } catch (e) {
      this.logger.warn(e);
    }
  }

  private findSymbolById(id: number): Symbol {
    return this.symbolStorage.findSymbolById(id);
  }

  private async parseQuote(tick: Notification_ItemTick, symbol: Symbol): Promise<QuoteTick> {
    const p2p = Number(tick.PriceToPointMul);

    const tickP2p = p2p ? p2p : Math.pow(10, symbol.DecimalPlaces);

    if (Environment.IsMobileVersion && Environment.ChartType === 'tvlight') {
      return new QuoteTick(
        symbol.SymbolId,
        Number(tick.Bid),
        Number(tick.Ask),
        Number(tick.MarketDepthBid),
        Number(tick.MarketDepthAsk),
        tickP2p,
        Number(tick.ConversionToUSD),
        new Date(tick.ServerTime + 'Z') //  котировки приходят к нам в UTC дату тут получаем уже локальную поэтому добавляем Z
        // иначе облегченный чарт не переключает минутки из-за разности с UTC
      );
    } else {
      // стандартный тв чарт сам разруливает разность таймзон
      return new QuoteTick(
        symbol.SymbolId,
        Number(tick.Bid),
        Number(tick.Ask),
        Number(tick.MarketDepthBid),
        Number(tick.MarketDepthAsk),
        tickP2p,
        Number(tick.ConversionToUSD),
        new Date(tick.ServerTime)
      );
    }


  }

  private async tickRoutine(quoteTick: QuoteTick, symbol: Symbol): Promise<void> {
    try {
      this.logDelay(quoteTick, symbol);

      await symbol.quoteTick(quoteTick);
    }
    catch (e) {
      this.logger.error(e);
      return;
    }
  }

  private async logDelay(quote: QuoteTick, symbol: Symbol) {
    if(!this.checkSymbolLogging(symbol.SymbolName)) return;

    const timezoneCurrentTime = new Date();
    const gmtCurrentTimeString = timezoneCurrentTime.toLocaleString("en-US", {timeZone: "GMT"});
    const currentTime = new Date(gmtCurrentTimeString);
    currentTime.setMilliseconds(timezoneCurrentTime.getMilliseconds());

    const quoteSendTime = quote.ServerTime;

    // @ts-ignore
    const delay = Math.abs(currentTime - quoteSendTime);

    if(!this.checkDelayLogging(delay)) return;
  }

  private checkSymbolLogging(symbolName: string): boolean {
    const symbolFlag = this.logSymbolDelay.get(symbolName);

    if(symbolFlag != undefined) return symbolFlag;

    const quotesSettings = this.config.Settings.quotes;

    const result = quotesSettings && quotesSettings.DelayLogging && quotesSettings.DelayCheckSymbols && quotesSettings.DelayCheckSymbols.indexOf(symbolName) != -1;

    this.logSymbolDelay.set(symbolName, result);

    return result;
  }

  private checkDelayLogging(delay: number): boolean {
      if (this.config.Settings.quotes.DelayLogThresholdMs <= delay) return true;
      else return false;
  }
}
