
export class OperationsWithVolume {

  // Дополнительная проверка полученного значения (т.к. если 0.14 * 100 000 = 14 000.000000002) и идет округления либо к большему, либо к меньшему числу
  public static checkVolume(volume: number, minLength: number = 2): number {

    if (minLength === 0) {
      minLength = 2;
    }

    const number = volume.toString().split('.');
    if (number.length > 1) {
      const secondNumber = number[1].split('');
      if (secondNumber.length > minLength) {
        if (secondNumber[0] === '0' && secondNumber[minLength - 1] === '0') {
          return Math.floor(volume);
        } else {
          return Math.ceil(volume);
        }
      } else {
        return volume;
      }
    } else {
      return volume;
    }
  }

  public static roundingToHundredths(volume: number): number {
    const number = volume.toString().split('.');
    if (number.length > 1) {
      const secondNumber = number[1].split('');
      if (secondNumber.length > 2) {
        return Math.round(volume * 100) / 100;
      } else {
        return volume;
      }
    } else {
      return volume;
    }
  }

  public static convertFromVolumeToLots(v: number, contractSize: number, delimiter: number = 1): number {
    return (v / contractSize) * delimiter;
  }


  public static convertFromLotToVolume(v: number, contractSize: number, delimiter: number = 1): number {
    return (v * contractSize) / delimiter;
  }

  // метод добавляет две цифры после точки если это необходимо
  public static setFormatPercent(value: number) {
    const f = (x: number) => this.numberOfDigitsAfterDot(x);

    if (value !== null) {
      if (f(value) === 1) {
        return value.toFixed(2);
      }
      return value;
    }

    return 0;
  }

  public static percentChange (lastPrice: number, avgPrice: number): number {
    if (lastPrice === 0) {
      return null;
    }
    return Math.round((((avgPrice * 100) / lastPrice) - 100) * 100) / 100;
  }

  public static priceChange (lastPrice: number, currentPrice: number): number {
    return Math.round((currentPrice - lastPrice) * 10000) / 10000;
  }

  // метод добавляет нули после запятой в зависимости от количества символов после
  public static getPrice(currenPrice: number, decimalPlaces: number): string {
    const f = (x: number) => this.numberOfDigitsAfterDot(x);
    if (f(currenPrice) > 2) {
      const g = f(currenPrice);
      return currenPrice.toFixed(decimalPlaces);
      // return currenPrice.toFixed(g);
    }
    return currenPrice.toFixed(2);
  }

   // метод добавляет нули после для отображения десятков
  public static adjustingValue(value: number): string | number {
    if (this.numberOfDigitsAfterDot(value) === 2) {
      return value.toFixed(this.numberOfDigitsAfterDot(value));
    } else if (this.numberOfDigitsAfterDot(value) > 0) {
      const g = this.numberOfDigitsAfterDot(value) + 1;
      return value.toFixed(g);
    }

    return value;

  }
  public static numberOfDigitsAfterDot(value: number): number {
    return ((value.toString().includes('.')) ? (value.toString().split('.').pop().length) : (0));
  }

  public static toFixedHard(number: number, x: number) {
    return parseFloat(number.toFixed(x + 1).slice(0, -1));
  }

  public static remainderOfDivision(volume: number, step: number): number {
    const count = OperationsWithVolume.numberOfDigitsAfterDot(step);
    let multiplyBy = 1;

    for (let i = 0; i < count; i++) {
      multiplyBy = multiplyBy * 10;
    }

    const tempVolume = OperationsWithVolume.checkVolume(volume * multiplyBy, 1) ;
    const tempStep = OperationsWithVolume.checkVolume(step * multiplyBy, 1)  ;

    return tempVolume % tempStep;
  }
}
