<div class="table-row">
  <div class="states-toggle">
    <div class="trade-type-marker" [ngClass]="getBorderColor()">

    </div>
    <div class="show-states-button" (click)="toggle()">
      {{Icon}}
    </div>
  </div>
  <div class="order-info">
    <div [ngClass]="getTradeClass()" class="main-info">
      #{{TradeId}} {{SymbolName}} <l-unit [key]="TradeType"></l-unit>
    </div>
    <div class="sub-info">
      <l-date [date]="OpenTime"></l-date>
    </div>
  </div>
  <div class="open-price-info">
    <div class="main-info">
      {{OpenPrice | price: Symbol}}
    </div>
    <div class="sub-info">
      <l-unit [key]="TradeType"></l-unit>
    </div>
  </div>
  <div class="volume-info">
    <div class="main-info" *ngIf="Volume">
      {{Volume | money:DecimalPlacesForMoney}} {{BaseCurrency}}
    </div>
  </div>
  <div class="margin-info">
    <div class="main-info" *ngIf="Margin">
      {{Margin | money}}
    </div>
  </div>
  <div class="sl-info">
    <div class="main-info" *ngIf="StopLoss">
      {{StopLoss }}
    </div>
  </div>
  <div class="tp-info">
    <div class="main-info" *ngIf="TakeProfit">
      {{TakeProfit }}
    </div>
  </div>
  <div class="ts-info">
    <div class="main-info" *ngIf="TrailingStop">
      {{TrailingStop }} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
    </div>
    <div class="sub-info" *ngIf="TrailingStop && PriceTrailingStop" >
      {{ PriceTrailingStop | price: Symbol }}
    </div>
  </div>
  <div class="price-info">
    <div class="main-info" *ngIf="ClosePrice">
      {{ClosePrice | price: Symbol}}
    </div>
    <div class="sub-info" [ngClass]="getPipsClasses()" *ngIf="ClosePrice">
      {{Pips | pips}} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
    </div>
  </div>
  <div class="pnl-info upl-info">
    <div class="main-info profit" *ngIf="IsTradeOpen">
      <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_UPLLabel'"></l-unit>: </span>
      <span class="float-right" *ngIf="UPL == 0" >--</span>
      <span class="float-right" *ngIf="UPL !== 0" [ngClass]="getUPLClasses()">{{UPL | money}}</span>
    </div>
    <div class="main-info profit" *ngIf="!IsTradeOpen">
      <span *ngIf="Profit > 0"><l-unit [key]="'TradeModule_OpenTradeLineComponent_ProfitLabel'"></l-unit>: </span>
      <span *ngIf="Profit < 0"><l-unit [key]="'TradeModule_OpenTradeLineComponent_LossLabel'"></l-unit>: </span>
      <span class="float-right" [ngClass]="getProfitClasses()">{{Profit | money}}</span>
    </div>
    <div class="main-info swap" *ngIf="Storage">
      <span><l-unit [key]="'TradeModule_TradeStateLineComponent_StorageLabel'"></l-unit>: </span>
      <span class="float-right" [ngClass]="getStorageClasses()">{{Storage | money}}</span>
    </div>
    <div class="main-info trans-fee" *ngIf="TransactionFee">
      <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_TransFee'"></l-unit>: </span>
      <span class="float-right" [ngClass]="getTransactionFeeClasses()">{{TransactionFee | money}}</span>
    </div>
    <div class="main-info trans-fee" *ngIf="SubaccountFee">
      <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_SubaccFee'"></l-unit>: </span>
      <span class="float-right" [ngClass]="getSubaccountFeeClasses()">{{SubaccountFee | money}}</span>
    </div>
  </div>
  <div class="total-info">
    <div class="main-info" [ngClass]="getTotalClasses()">
      {{Total | money}}
    </div>
  </div>

  <div class="buttons-group">
    <a class="row-options-action" (click)="toggleVisibilityLineCharts()" *ngIf="!IsReadOnlyForCarbona">
      <span class="row-options-icon row-options-icon-visibility" [class.row-options-icon-visibility-off]="!getVisibility()"></span>
    </a>
    <a class="row-options-action" (click)="modifyTrade($event)" *ngIf="!IsReadOnly">
      <span class="row-options-icon row-options-icon-edit"></span>
    </a>
    <a class="row-options-action clickable-button" (click)="openChart()" [hidden]="!IsOpenChartVisible">
      <span class="row-options-icon row-options-icon-chart"></span>
    </a>
    <a class="row-options-action clickable-button"  id="lib-trade-line-close-order" (click)="closeTrade($event)">
      <span class="row-options-icon row-options-icon-close" *ngIf="!IsReadOnly"></span>
    </a>
  </div>
</div>

<div class="states" *ngIf="IsOpened">
  <div class="additional-block-border" [ngClass]="getBorderColor()">

  </div>
  <div class="table-row" *ngFor="let state of States; let i = index; trackBy: trackStateById">
    <div></div>
    <div class="order-info">
      <div class="main-info">
        <l-unit [key]="getStateName(state.StateType)"></l-unit>
      </div>
      <div class="sub-info">
        <l-date [date]="state.CommandTime"></l-date>
      </div>
    </div>
    <div class="open-price-info">
      <div class="main-info">
        {{state.OpenPrice | price: Symbol}}
      </div>
      <div class="sub-info">
        <l-unit [key]="getTradeTypeName(state.OrderType)"></l-unit>
      </div>
    </div>
    <div class="volume-info">
      <div class="main-info">
        <span *ngIf="!Settings.UseLotTrading">{{state.Volume | money}}</span>
        <span *ngIf="Settings.UseLotTrading"> {{convertVolume(state.Volume, state.Symbol.ContractSize) }}</span>
      </div>
      <div class="sub-info">
        <span *ngIf="!Settings.UseLotTrading">{{getCommandSign(state.CommandVolume)}}{{state.CommandVolume | money}}</span>
        <span *ngIf="Settings.UseLotTrading"> {{getCommandSign(state.CommandVolume)}} {{convertVolume(state.CommandVolume, state.Symbol.ContractSize) }}</span>
      </div>
    </div>
    <div class="margin-info">
      <div class="main-info">
        {{state.Margin | money}}
      </div>
      <div class="sub-info" *ngIf="state.CommandMargin">
        {{getCommandSign(state.CommandMargin)}}{{state.CommandMargin | money}}
      </div>
    </div>
    <div class="sl-info">
      <div class="main-info" *ngIf="state.StopLoss">
        {{state.StopLoss }}
      </div>
    </div>
    <div class="tp-info">
      <div class="main-info" *ngIf="state.TakeProfit">
        {{state.TakeProfit }}
      </div>
    </div>
    <div class="ts-info">
      <div class="main-info" *ngIf="state.TrailingStop">
        {{state.TrailingStop }} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
      </div>
    </div>
    <div class="price-info">
      <div class="main-info" *ngIf="shouldShowPrice(state)">
        {{state.CommandPrice | price: Symbol}}
      </div>
      <div class="sub-info" [ngClass]="getStateProfitClasses(state)" *ngIf="shouldShowPrice(state)">
        {{state.ProfitPips | pips}} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
      </div>
    </div>
    <div class="pnl-info">
      <div class="main-info profit" *ngIf="state.Profit">
        <span *ngIf="state.Profit >= 0"><l-unit [key]="'TradeModule_TradeStateLineComponent_ProfitLabel'"></l-unit>: </span>
        <span *ngIf="state.Profit < 0"><l-unit [key]="'TradeModule_TradeStateLineComponent_LossLabel'"></l-unit>: </span>
        <span class="float-right" *ngIf="i == States.length - 1" [ngClass]="getProfitClasses()">{{state.Profit | money}}</span>
        <span class="float-right" *ngIf="i != States.length - 1" [ngClass]="getStateProfitClasses(state)">{{state.Profit | money}}</span>
      </div>
      <div class="main-info swap" *ngIf="state.Storage">
        <span *ngIf="state.Storage"><l-unit [key]="'TradeModule_TradeStateLineComponent_StorageLabel'"></l-unit>: </span>
        <span class="float-right" [ngClass]="getStateStorageClasses(state)">{{state.Storage | money}}</span>
      </div>
      <div class="main-info trans-fee" *ngIf="state.TransactionFee">
        <span><l-unit [key]="'TradeModule_TradeStateLineComponent_TransFee'"></l-unit>: </span>
        <span class="float-right" [ngClass]="getStateTransactionFeeClasses(state)">{{state.TransactionFee}}</span>
      </div>
      <div class="main-info trans-fee" *ngIf="state.SubaccountFee">
        <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_SubaccFee'"></l-unit>: </span>
        <span class="float-right" [ngClass]="getStateSubaccountFeeClasses(state)">{{state.SubaccountFee}}</span>
      </div>
    </div>
    <div></div>
    <div></div>
  </div>
</div>
