import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import * as moment from 'moment';
import {Settings} from '@common/trader/models/settings';
import {SettingsService} from '@common/trader/services/settings.service';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {FormsModule, NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {AlertChangesPriceService, AlertPricesObj, Condition, ConditionEnum} from '@common/shared/services/alert-changes-price.service';
import {LocaleModule} from '@common/locale/locale.module';
import {SharedModule} from '@common/shared/shared.module';
import {NgForOf, NgIf} from '@angular/common';


@Component({
  selector: 'app-alert-price-form',
  templateUrl: './alert-price-form.component.html',
  styleUrls: ['./alert-price-form.component.scss'],
  imports: [
    LocaleModule,
    FormsModule,
    SharedModule,
    NgForOf,
    NgIf
  ],
  standalone: true
})
export class AlertPriceFormComponent implements OnInit {

  private _symbol: Symbol;
  private _prices: number;
  private _textAreaAlertPrice = '';
  private showPicker = false;
  private _dateExpiration = new Date();
  private _triggerMode = 'Only';
  protected _disabled: boolean;

  @Input() public appName: string;

  private currentConditions: ConditionEnum = ConditionEnum.MoreBid;

  private conditions: Condition[] = [
    { code: ConditionEnum.MoreBid, name: 'Bid <'},
    { code: ConditionEnum.LessBid, name: 'Bid >'},
    { code: ConditionEnum.MoreAsk, name: 'Ask <'},
    { code: ConditionEnum.LessAsk, name: 'Ask >'},
  ];

  min = 0;
  max = Number.MAX_VALUE;

  get Condition(): ConditionEnum {
    return this.currentConditions;
  }

  set Condition(v: ConditionEnum) {
    this.currentConditions = v;
  }

  get Conditions(): Condition[] {
    return this.conditions;
  }

  get TriggerMode(): string {
    return this._triggerMode;
  }

  set TriggerMode(value: string) {
    this._triggerMode = value;
  }

  public changeTriggerMode(v: string) {
    this.TriggerMode = v;
  }

  @Input()
  public set symbol(v: Symbol) {
    this._symbol = v;
    this.checkSubscribePrice(v);
  }

  @Input()
  private set prices(v: number) {
    this._prices = v;
  }

  get Prices() {
    return this._prices;
  }

  set Prices(v) {
    this._prices = v;

    this._textAreaAlertPrice = this.valueTextAreaAlertPrice();
  }

  get TextAreaAlertPrice(): string {
    return this._textAreaAlertPrice;
  }

  set TextAreaAlertPrice( v: string) {
    this._textAreaAlertPrice = v;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  public changeVolume(prices) {
    this._prices = prices;

    this._textAreaAlertPrice = this.valueTextAreaAlertPrice();
  }

  @ViewChild('alertForm') alertForm: NgForm;


  constructor(private settingsService: SettingsService,
              protected settings: Settings,
              private symbolService: SymbolService,
              protected router: Router,
              private alertChangesPriceService: AlertChangesPriceService) {
  }

  public get DateExpiration(): string {
    return moment(this._dateExpiration).format('DD MMM \'YY, HH:mm');
  }

  public get Symbol(): Symbol {
    return this._symbol;
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public get ShowPicker(): boolean {
    return this.showPicker;
  }

  ngOnInit() {
    this._dateExpiration = new Date(this._dateExpiration.setDate(this._dateExpiration.getDate() + 1));
    this._textAreaAlertPrice = this.valueTextAreaAlertPrice();
  }

  public onDateClick(event: MouseEvent) {
    // event.stopPropagation();
    this.openDateTimePicker();
  }

  private openDateTimePicker() {
    this.showPicker = true;
  }

  public closeDateTimePicker() {
    this.showPicker = false;
  }

  public onSelectDate(date: Date) {
    this._dateExpiration = date;
    this.closeDateTimePicker();
  }

  // при прокрутке таблицы символов идет проверка на подписку изминения цен, если её нет, то подписывается
  private checkSubscribePrice(v: Symbol): void {
    if (v.LastQuote === undefined) {
      this.Prices = 0;
      this.symbolService.subscribeToSymbol(v.SymbolId).then(() => {
        this.Prices = v.LastPrice;
      });
    } else {
      this.Prices = v.LastPrice;
    }
    // проверка на подписку для конвертирующей валюты
    if (!v.ProfitCalcSymbol.getCCConvertSymbol().getIsValidQuote()) {
      const convertSymbol = v.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'];
      console.log( `Subscription for converting currency ${convertSymbol._symbolMetadata._symbolName}`);
      this.subscribeToConvertSymbol(convertSymbol._symbolId).then();
    }
  }

  // подписка на конвертирующую валюту
  private async subscribeToConvertSymbol(symbolId: number): Promise<void> {
    await this.symbolService.subscribeToSymbol(symbolId);
  }

  public close() {
    this.router.navigate(['/terminal', {outlet: {modal: null}}]).then();

    if (document.fullscreenElement !== null) {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          const chart = document.documentElement.querySelector('#tradingview_11111');
          if (chart.lastElementChild.requestFullscreen) {
            chart.lastElementChild.requestFullscreen().then();
          }
        });
      }
    }
  }

  public valueTextAreaAlertPrice(): string {
    if (!this._symbol) {
      return '';
    }
    return `${this._symbol.SymbolName} crossing ${this.Prices}`;
  }

  public createAlertPrice() {
    const messageAlertPrice = this.alertForm.form.controls.messageAlertPrice.value;
    const alertName = this.alertForm.form.controls.alertName.value;

    const obj: AlertPricesObj = {
      alertId: Date.now(),
      symbolId: this._symbol.SymbolId,
      symbolName: this._symbol.SymbolName,
      prices: this._prices,
      priceRange: this.alertChangesPriceService.createPriceRange(this._prices, this._symbol.TickSize),
      trigger: this._triggerMode,
      conditions: this.currentConditions,
      expiration: this._dateExpiration,
      alertName: alertName,
      message: messageAlertPrice,
    };

   this.close();

    this.alertChangesPriceService.createNewAlertPrices(obj);
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

}
