import {EventEmitter, Injectable} from '@angular/core';
import {ReportCommandSender} from '@common/communication/command-sender/report-command-sender';
import {OperationsWithJsonObject} from '@common/trade/utils/operations-with-json-object';

export interface OrderHistoryReport {
  clientOrderID: number;
  commandMargin: number;
  commandOriginID: number;
  commandPrice: number;
  commandSenderID: number;
  commandTime: string;
  commandTypeID: number;
  commandVolume: number;
  comment: string;
  dealID: number;
  expirationTime: string;
  margin: number;
  openPrice: number;
  orderID: number;
  orderTypeID: number;
  stopLoss: number;
  symbolID: number;
  takeProfit: number;
  traderID: number;
  trailingStop: number;
  volume: number;
  stateAction: string;
  profit: number;
  transactionFee: number;
  swaps: number;
}

export interface TransferHistoryReport {
  operation: string;
  incomeAmount: number;
  commandTime: string;
}

export interface ChallengeSettings {
  dailyDrawdownPercent: null | number;
  dailyDrawdownValue: null | number;
  endDate: null | string;
  initialBalance: null | number;
  profitTargetPercent: null | number;
  profitTargetValue: null |number;
  startDate: null |string;
  totalDrawdownPercent: null | number;
  totalDrawdownValue: null |number;
  tradeDayStartBalanceAC: null | number;
  tradeDayStartEquityAC: null | number;
  closeReason: null | string;
  onCloseBalanceAC: null | number;
  onCloseEquityAC: null | number;
  closeDate: string | null;
}

export interface ChallengeEODSnapshots {
  traderEODSnapshots: [];
}

export interface GainSummary {
  totalGain: null | number;
  todayGain: null | number;
  totalWireOut: null | number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public CFA_ORDER_ROWS = 20;
  public CFA_TRANSFER_ROWS = 20;

  private orderHistoryReports: OrderHistoryReport[];
  private orderPages = 0;
  private orderReportLoadEmitter: EventEmitter<void> = new EventEmitter<void>();

  private transferHistoryReports: TransferHistoryReport[];
  private transferPages = 0;
  private transferReportLoadEmitter: EventEmitter<void> = new EventEmitter<void>();

  // private traderGainSummery: GainSummary;

  public get OrderHistoryReports(): OrderHistoryReport[] {
    return this.orderHistoryReports;
  }

  public get OrderReportLoadEmitter(): EventEmitter<void> {
    return this.orderReportLoadEmitter;
  }

  public get TransferHistoryReports(): TransferHistoryReport[] {
    return this.transferHistoryReports;
  }

  public get TransferReportLoadEmitter(): EventEmitter<void> {
    return this.transferReportLoadEmitter;
  }

  // public get TraderGainSummery(): GainSummary {
  //   return this.traderGainSummery;
  // }

  constructor(private reportCommandSender: ReportCommandSender) {
    this.orderHistoryReports = [];
    this.transferHistoryReports = [];
  }

  public async cfaLoadOrderPage(typePlatform = '', goToPage: boolean = false, orderRows?: number) {

    if (!goToPage) {
      this.orderPages++;
    } else {
      this.orderPages = 1;
    }

    if (orderRows) {
      this.CFA_ORDER_ROWS = orderRows;
    }

    if (typePlatform === 'UpdateStoriesTrader') {
      this.orderPages = 1;
    }

    await this.getOrderHistory(this.orderPages, goToPage, typePlatform);
  }

  private async getOrderHistory(pages: number, goToPage: boolean, typePlatform: string) {
    await this.reportCommandSender.traderOrderHistoryGet(pages, this.CFA_ORDER_ROWS).then((answer) => {

      if (OperationsWithJsonObject.testJSON(answer.Result)) {
        const result = JSON.parse(answer.Result)['traderOrderHistoryReport'];
        if (result) {

          if (goToPage || typePlatform === 'UpdateStoriesTrader') {
            this.orderHistoryReports = [];
          }

          result.forEach((jsonReport: OrderHistoryReport) => {
            const report: OrderHistoryReport = {
              clientOrderID: jsonReport.clientOrderID,
              commandMargin: jsonReport.commandMargin,
              commandOriginID: jsonReport.commandOriginID,
              commandPrice: jsonReport.commandPrice,
              commandSenderID: jsonReport.commandSenderID,
              commandTime: jsonReport.commandTime,
              commandTypeID: jsonReport.commandTypeID,
              commandVolume: jsonReport.commandVolume,
              comment: jsonReport.comment,
              openPrice: jsonReport.openPrice,
              stopLoss: jsonReport.stopLoss,
              symbolID: jsonReport.symbolID,
              trailingStop: jsonReport.trailingStop,
              expirationTime: jsonReport.expirationTime,
              volume: jsonReport.volume,
              dealID: jsonReport.dealID,
              orderID: jsonReport.orderID,
              orderTypeID: jsonReport.orderTypeID,
              takeProfit: jsonReport.takeProfit,
              traderID: jsonReport.traderID,
              margin: jsonReport.margin,
              stateAction: jsonReport.stateAction,
              profit: jsonReport.profit,
              transactionFee: jsonReport.transactionFee,
              swaps: jsonReport.swaps
            };

            this.orderHistoryReports.push(report);
          });

          this.orderReportLoadEmitter.emit();
        }

      } else {
        console.error('Response error, invalid jason object', 404);
      }

    }).catch((error) => {
      console.log('error', 23, error);
    });
  }

  public async cfaLoadTransferPage(typePlatform = '', orderRows?: number) {
    this.transferPages++;

    if (orderRows) {
      this.CFA_TRANSFER_ROWS = orderRows;
    }

    await this.getTransferHistory(this.transferPages);
  }

  private async getTransferHistory(pages: number) {
    await this.reportCommandSender.traderTransferHistoryGet(pages, this.CFA_TRANSFER_ROWS).then((answer) => {
      if (OperationsWithJsonObject.testJSON(answer.Result)) {
        const result = JSON.parse(answer.Result)['traderTransferHistoryReport'];
        if (result ) {
          result.forEach((jsonReport: TransferHistoryReport) => {
            const report: TransferHistoryReport = {
              operation: jsonReport.operation,
              incomeAmount: jsonReport.incomeAmount,
              commandTime: jsonReport.commandTime
            };

            this.transferHistoryReports.push(report);
          });

          this.transferReportLoadEmitter.emit();
        }
      } else {
        console.error('Response error, invalid jason object', 404);
      }

    }).catch((error) => {
      console.log('error', 23, error);
    });
  }

  public clearReports() {
    this.orderHistoryReports = [];
    this.orderPages = 0;

    this.transferHistoryReports = [];
    this.transferPages = 0;
  }

  public resetPages() {
    this.orderPages = 0;
  }

  public clearTransferHistoryReport() {
    this.transferHistoryReports = [];
    this.transferPages = 0;
  }

  public async loadTraderGainSummary(): Promise<GainSummary> {
    /*   const a = new Promise<string>((resolve) => {
         this.reportCommandSender.TCTokenGet().then((answer) => {
           console.log(answer);
         });
       });*/

    return new Promise<GainSummary>((resolve) => {
      this.reportCommandSender.traderGainSummaryGet().then((answer) => {
        let traderGainSummery: GainSummary;

        if (OperationsWithJsonObject.testJSON(answer.Result)) {
          const jsonResult = JSON.parse(answer.Result);

          traderGainSummery = {
            todayGain: jsonResult['traderGainSummary'].todayGain,
            totalGain: jsonResult['traderGainSummary'].totalGain,
            totalWireOut: jsonResult['traderGainSummary'].totalWireOut,
          };

        } else {
          traderGainSummery = {
            todayGain: 0,
            totalGain: 0,
            totalWireOut: 0,
          };
        }
        resolve(traderGainSummery);
      });
    });

  }

  public async BlackThornAuthorize(
    orderNumber: string,
    orderAmount: string,
    orderCurrency: string,
    orderDescription: string,
    successURL: string,
    cancelURL: string,
    customerName: string): Promise<string> {


    return new Promise<string>((resolve) => {
      this.reportCommandSender.blachThornAuthorize(
        orderNumber,
        orderAmount,
        orderCurrency,
        orderDescription,
        successURL,
        cancelURL,
        customerName)
        .then((answer) => {
          /*        const jsonResult = JSON.parse(answer.Result);

                  console.log(jsonResult);*/

          resolve(answer.Result);
        });
    });

  }

  // запрос в репорт центр для получения данных о Challenge

  public async loadTraderChallengeSettings(): Promise<ChallengeSettings> {
    return new Promise<ChallengeSettings>((resolve) => {
      this.reportCommandSender.traderChallengeSettings().then((answer) => {
        let challengeSetting: ChallengeSettings;
        if (OperationsWithJsonObject.testJSON(answer.Result)) {
          const jsonResult = JSON.parse(answer.Result);
          challengeSetting = {
            dailyDrawdownPercent: jsonResult['traderTransferHistoryReport'].dailyDrawdownPercent,
            dailyDrawdownValue: jsonResult['traderTransferHistoryReport'].dailyDrawdownValue,
            endDate: jsonResult['traderTransferHistoryReport'].endDate,
            initialBalance: jsonResult['traderTransferHistoryReport'].initialBalance,
            profitTargetPercent: jsonResult['traderTransferHistoryReport'].profitTargetPercent,
            profitTargetValue: jsonResult['traderTransferHistoryReport'].profitTargetValue,
            startDate: jsonResult['traderTransferHistoryReport'].startDate,
            totalDrawdownPercent: jsonResult['traderTransferHistoryReport'].totalDrawdownPercent,
            totalDrawdownValue: jsonResult['traderTransferHistoryReport'].totalDrawdownValue,
            tradeDayStartBalanceAC: jsonResult['traderTransferHistoryReport'].tradeDayStartBalanceAC,
            tradeDayStartEquityAC: jsonResult['traderTransferHistoryReport'].tradeDayStartEquityAC,
            closeReason: jsonResult['traderTransferHistoryReport'].closeReason,
            onCloseBalanceAC: jsonResult['traderTransferHistoryReport'].onCloseBalanceAC,
            onCloseEquityAC: jsonResult['traderTransferHistoryReport'].onCloseEquityAC,
            closeDate: jsonResult['traderTransferHistoryReport'].closeDate
          };
        } else {
          challengeSetting = undefined;
        }

        console.log('ChallengeSetting', challengeSetting);

        resolve(challengeSetting);

      });
    });
  }

  public async loadTraderChallengeEODSnapshot(): Promise<ChallengeEODSnapshots> {
    return new Promise<ChallengeEODSnapshots>((resolve) => {
      this.reportCommandSender.traderChallengeEODSnapshot().then((answer) => {
        let traderEODSnapshots: ChallengeEODSnapshots;

        if (OperationsWithJsonObject.testJSON(answer.Result)) {
          const jsonResult = JSON.parse(answer.Result);
          traderEODSnapshots = {
            traderEODSnapshots: jsonResult.traderEODSnapshots
          };
        } else {
          traderEODSnapshots = undefined;
        }

        console.log('TraderEODSnapshots', traderEODSnapshots);

        resolve(traderEODSnapshots);
      });
    });
  }
}
