import {Component} from '@angular/core';
import {ApplicationService} from '@common/application.service';
import {Environment} from '@common/environment';
import {Trader} from '@common/trader/models/trader';
import {TerminalService} from '@common/trade/services/terminal.service';
import {LocationPositionService} from '@common/trade/services/location-position.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title = 'desktop';
  private _isConnected = true;

  public get IsTesting(): boolean {
    return Environment.IsTesting;
  }

  public get IsConnected(): boolean {
    return this._isConnected;
  }

  public constructor(private app: ApplicationService,
                     private trader: Trader,
                     private guidPageService: TerminalService,
                     private locationPositionService: LocationPositionService) {

    this.trader.ConnectionChangedEmitter.subscribe(val => {
      this._isConnected = val;
    });

    this.guidPageService.createItem();
    this.locationPositionService.init();
  }

}
