import {ChartService} from '@common/charting/services/chart.service';
import {EventEmitter, Injectable} from '@angular/core';
import {
  ChartData,
  ChartingLibraryWidgetOptions,
  ContextMenuItem, DrawingEventType,
  EmptyCallback,
  IBasicDataFeed,
  IChartingLibraryWidget,
  IChartWidgetApi,
  IOrderLineAdapter,
  LanguageCode,
  PlusClickParams,
  ThemeName,
  TradingTerminalWidgetOptions,
  widget
} from '@tradingview/charting_library';
import {TradingViewDatafeed} from '@common/trading-view-charts/utils/trading-view-datafeed';
import {AppConfig} from '@common/configuration/app-config';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {ChartStrings} from '@common/charting/models/chart-strings';
import {Trader} from '@common/trader/models/trader';
import {Router} from '@angular/router';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Bar, ResolutionString} from '@tradingview/charting_library/datafeed-api';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {SettingsService} from '@common/trader/services/settings.service';
import {ContextMenuActionType, IChartContextMenu, IChartContextMenuItem} from '@chartiq-core/trade-from-chart/models/trade-from-chart-interfaces';
import {OneClickTradeStrategy} from '@common/actions/strategies/trade/one-click-trade.strategy';
import {ChartContextMenuComposerService} from '@chartiq-core/trade-from-chart/services/chart-context-menu-composer.service';
import {TradeType} from '@common/trade/models/trade-type';
import {Environment} from '@common/environment';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Trade} from '@common/trade/models/trade';
import {TradeService} from '@common/trade/services/trade.service';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {ThemeService} from '@common/shared/services/theme';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {TradeAction} from '@common/actions/trade.action';
import {SessionStorageService} from '@common/auth/services/session-storage.service';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Theme} from '@common/shared/services/theme/symbols';
import { Subscription } from 'rxjs';
import {TerminalService} from '@common/trade/services/terminal.service';

export interface PlusClickEventInterface {
  paramsContextMenu: IChartContextMenu;
  paramsPosition: PlusClickParams;
}

@Injectable({
  providedIn: 'root'
})
export class TvChartService extends ChartService {

  private logger = LoggerFactory.getLogger('TvChartService');
  public containerId = 'tradingview_11111';
  private libraryPath = '/assets/charting_library/';
  private defaultStyle: string;
  private tvWidget: IChartingLibraryWidget;
  private _isCurrentLayoutSaved = false;
  public OnChartChanged: SmartEmitter<void> = new SmartEmitter<void>();
  public OnDrawingEvent: SmartEmitter<DrawingEventType> = new SmartEmitter<DrawingEventType>();
  public ExternalOnChartReady: EventEmitter<void> = new EventEmitter<void>();
  private symbol: Symbol;
  private linesHorizontal = new Map();
  private orderLine = false;
  private visibility = true;
  private arrayOpenOrders = [];
  public firstEnterMobile = true;
  private lastTickBar: Bar;
  private symbolLastTick: string;
  private _tvWidgetChartReady = false;
  public savedChartChangeTheme: EventEmitter<void> = new EventEmitter<void>();
  private linesAskBid = new Map();
  private showAskBidLineChart = false;
  public changeSymbolForMobile: EventEmitter<string> = new EventEmitter<string>();
  private _isRenderLogs = false;
  private themeChangeSubscribe: Subscription;
  public onPlusClickEvent: EventEmitter<PlusClickEventInterface> = new EventEmitter<PlusClickEventInterface>();
  private askButton: HTMLElement;
  private bidButton: HTMLElement;
  private sellButton: HTMLElement;
  private buyButton: HTMLElement;
  private visibilityButton: HTMLElement;
  private buttonDropDown: HTMLElement;
  private onOffAskBidButton: HTMLElement;
  private spreedButton: HTMLElement;
  private alertPriceChangeButton: HTMLElement;
  private IFrameBody: HTMLIFrameElement;
  private selectedStyle = 'color: #2962ff';
  private _classNameForCustomButton = 'customButtonDarkTheme';
  private _buyButton = 'buyButton';
  private _sellButton = 'sellButton';
  private _dropDownButton = 'dropDownButton';

  public get IsCurrentLayoutSaved(): boolean {
    return this._isCurrentLayoutSaved;
  }

  public set IsCurrentLayoutSaved(value: boolean) {
    this._isCurrentLayoutSaved = value;
  }

  set TvWidgetChartReady(value: boolean) {
    this._tvWidgetChartReady = value;
  }

  protected constructor(private datafeed: TradingViewDatafeed,
                        private trader: Trader,
                        private router: Router,
                        private symbolStorage: SymbolStorageService,
                        private appConfig: AppConfig,
                        private settingsService: SettingsService,
                        private oneClickTradeStrategy: OneClickTradeStrategy,
                        private chartContextMenuComposerService: ChartContextMenuComposerService,
                        private symbolService: SymbolService,
                        private readonly tradeStorage: TradeStorage,
                        private tradeService: TradeService,
                        private themeService: ThemeService,
                        private tradeAction: TradeAction,
                        private sessionStorageService: SessionStorageService,
                        private localStorageService: LocalStorageService,
                        private terminalService: TerminalService) {
    super();
  }

  public configureChart(symbol?: string, resolution?: ResolutionString, lang?: string, type = 'Default', saved_data?: ChartData ) {
    const defaultSymbol = this.appConfig.resolveDefaultSymbol(this.trader.TradeGroupId);

    this.logger.debug(`Configure chart | symbol: ${defaultSymbol}`);

    this.symbolName = symbol || defaultSymbol;
    this.interval = resolution || this.interval;

    this.getSymbolNameIntervalForTrader(defaultSymbol, 'chartSymbolNameForTrader', resolution);

    this.sessionStorageService.saveDataInStorage('currentIntervalForChart', JSON.stringify(this.intervalForSave(this.interval)));

    this.language = this.checkingLanguageCode(lang || this.language);

    const options = type === 'Default' ? this.configureTVWidget(saved_data) : this.configureTVWidgetCFA(saved_data);

    this.tvWidget = new widget(options);

    let priceDirection: number;

    if (this.appConfig.Settings.charts !== undefined && this.appConfig.Settings.charts.PriceDirection !== undefined) {
      priceDirection = this.appConfig.Settings.charts.PriceDirection;
    } else {
      priceDirection = PriceDirection.Bid;
    }

    this.PriceDirection = priceDirection;

    this.themeChangeSubscribe = this.themeService.getThemeChange().subscribe((value) => {
      if (!this._tvWidgetChartReady) {
        if (document.getElementById(this.containerId) !== null && document.getElementById(this.containerId).getElementsByTagName('iframe')[0] !== undefined) {
          const loadingIndicator = document.getElementById(this.containerId).getElementsByTagName('iframe')[0].contentWindow.document.querySelector('.loading-indicator');
          loadingIndicator.removeAttribute('style');
          if (value.category === 'dark') {
            loadingIndicator.setAttribute('style', 'background-color: rgb(19, 23, 34)');
          } else {
            loadingIndicator.setAttribute('style', 'background: #fff');
          }
        }
      } else {
        this.eventThemeChange(value);
      }

    });

    if (type === 'Default') {
      this.tvWidget.headerReady().then(async () => await this.onHeaderReady());
    }

    this.tvWidget.onChartReady(async () => await this.onReady());
  }

  public destroy(): void {
    if (this.tvWidget) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  public themeChangeUnsubscribe() {
    this.themeChangeSubscribe.unsubscribe();
  }

  private configureTVWidgetCFA(saved_data?): ChartingLibraryWidgetOptions {
    this.logger.debug(`Configure widget | symbolName: ${this.symbolName}`);
    this.logger.debug(`Configure widget | interval: ${this.interval}`);
    this.logger.debug(`Configure widget | language: ${this.language}`);
    this.logger.debug(`Configure widget | PriceDirection: ${this.PriceDirection}`);


    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.symbolName,
      datafeed: this.datafeed as IBasicDataFeed, // new (window as any).Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
      interval: this.interval as ResolutionString,
      container: this.containerId,
      library_path: this.libraryPath,
      locale: <LanguageCode>this.language,
      disabled_features: [
        'header_widget',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_resolutions',
        'show_interval_dialog_on_key_press',
        'header_chart_type',
        'header_settings',
        'header_indicators',
        'header_compare',
        'header_undo_redo',
        'header_screenshot',
        'header_fullscreen_button',
        'border_around_the_chart',
        'header_saveload',
        'left_toolbar',
        'control_bar',
        'timeframes_toolbar',
        'legend_widget',
        'edit_buttons_in_legend',
        'show_hide_button_in_legend',
        'format_button_in_legend',
        'delete_button_in_legend',
        'context_menus',
        'pane_context_menu',
        'scales_context_menu',
        'legend_context_menu',
        'main_series_scale_menu',
        'display_market_status',
        'remove_library_container_border',
        'property_pages',
        'show_chart_property_page',
        'chart_property_page_scales',
        'chart_property_page_trading',
        'chart_property_page_right_margin_editor',
        'countdown',
        'dont_show_boolean_study_arguments',
        'hide_last_na_study_output',
        'symbol_info',
        'timezone_menu',
        'snapshot_trading_drawings',
        'source_selection_markers',
        'go_to_date',
        'adaptive_logo',
        'show_dom_first_time',
        'hide_left_toolbar_by_default',
        'chart_style_hilo',
        'pricescale_currency'
      ],
      /*     disabled_features: ['header_widget', 'legend_widget', 'timezone_menu', 'show_chart_property_page'
                        , 'header_chart_type', 'header_resolutions', 'control_bar'
                        , 'symbol_info', 'adaptive_logo', 'go_to_date', 'main_series_scale_menu', 'border_around_the_chart'


                        , 'edit_buttons_in_legend', 'use_localstorage_for_settings', 'header_compare'
                        , 'compare_symbol', 'left_toolbar', 'header_symbol_search', 'symbol_search_hot_key'
                        , ' header_settings', 'header_compare', 'header_undo_redo', 'header_screenshot', 'header_fullscreen_button'
                        , 'header_indicators'],*/
      /*enabled_features: ['dont_show_boolean_study_arguments', 'hide_last_na_study_output'],*/
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      theme: 'light',
      timezone: 'Etc/UTC',
      debug: AppConfig.settings.charts.DebugEnabled,
      overrides: {
        'mainSeriesProperties.candleStyle.upColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.downColor': this.getDownColor(),

        'mainSeriesProperties.candleStyle.borderUpColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.borderDownColor': this.getDownColor(),

        'mainSeriesProperties.candleStyle.wickUpColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.wickDownColor': this.getDownColor(),

        'scalesProperties.backgroundColor': this.getUpColor(),
        'scalesProperties.lineColor': this.getUpColor(),
        'scalesProperties.textColor': this.getUpColor(),

        'mainSeriesProperties.style': 1,

        'volumePaneSize': 'tiny'
      },
      saved_data: saved_data // Apply saved layout
    };

    return widgetOptions;
  }

  private configureTVWidget(saved_data?: ChartData): ChartingLibraryWidgetOptions | TradingTerminalWidgetOptions {
    this.logger.debug(`Configure widget | symbolName: ${this.symbolName}`);
    this.logger.debug(`Configure widget | interval: ${this.interval}`);
    this.logger.debug(`Configure widget | language: ${this.language}`);
    this.logger.debug(`Configure widget | PriceDirection: ${this.PriceDirection}`);

    const widgetOptions: ChartingLibraryWidgetOptions | TradingTerminalWidgetOptions = {
      symbol: this.symbolName,
      datafeed: this.datafeed as IBasicDataFeed, // new (window as any).Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
      // datafeed: new UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
      interval: this.interval as ResolutionString,
      container: this.containerId,
      library_path: this.libraryPath,
      locale: <LanguageCode>this.language,
      disabled_features: ['edit_buttons_in_legend', 'use_localstorage_for_settings', 'header_compare', 'go_to_date', 'header_quick_search'],
      enabled_features: [ 'dont_show_boolean_study_arguments',
                          'hide_last_na_study_output',
                          'show_symbol_logos',
                          'show_symbol_logo_in_legend',
                          'hide_price_scale_global_last_bar_value',
                          'side_toolbar_in_fullscreen_mode',
                          'header_in_fullscreen_mode'],
      client_id: 'tradingview.com',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      header_widget_buttons_mode: 'adaptive',
      timezone: 'Etc/UTC',
      debug: AppConfig.settings.charts.DebugEnabled,
      // charts_storage_url: 'https://saveload.tradingview.com',
      // charts_storage_api_version: '1.0',

      additional_symbol_info_fields: [
        { title: ChartStrings.LeverageMarginRequirements, propertyName: 'margin_rate' },
        { title: ChartStrings.MinCommissionCommission, propertyName: 'min_fee_fee' },
        { title: ChartStrings.ContractSize, propertyName: 'contract_size' },
        { title: ChartStrings.MinMaxVolume, propertyName: 'min_max_volume' },
        { title: ChartStrings.TradingStep, propertyName: 'trading_step' },
        { title: ChartStrings.RolloverBuySell, propertyName: 'rollover_buy_sell' },
        { title: ChartStrings.PipValue, propertyName: 'pip_value' },
      ],
      overrides: {
        'mainSeriesProperties.candleStyle.upColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.downColor': this.getDownColor(),

        'mainSeriesProperties.candleStyle.borderUpColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.borderDownColor': this.getDownColor(),

        'mainSeriesProperties.candleStyle.wickUpColor': this.getUpColor(),
        'mainSeriesProperties.candleStyle.wickDownColor': this.getDownColor(),

        'scalesProperties.backgroundColor': this.getUpColor(),
        'scalesProperties.lineColor': this.getUpColor(), // this
        'scalesProperties.textColor': this.getUpColor(), // this

        'mainSeriesProperties.style': 1,

        'volumePaneSize': 'tiny',
      },
      saved_data: saved_data, // Apply saved layout

      custom_css_url: 'css/style.css'
    };

    const activeTheme = localStorage.getItem('theme');
    if (activeTheme !== null) {
      if (activeTheme === 'tvZetradexDarkTheme' || activeTheme === 'tvDefaultTheme' || activeTheme === 'tvDarkTheme') {
        widgetOptions.theme = 'dark';
      } else if (activeTheme === 'tvZetradexLightTheme' || activeTheme === 'tvLightTheme') {
          widgetOptions.theme = 'light';
          this._classNameForCustomButton = 'customButtonLightTheme';
      }
    } else {
        widgetOptions.theme = 'dark';
    }

    if (this.appConfig.Settings.carbonTheme) {
      widgetOptions.overrides = {
        ...widgetOptions.overrides,
        // задает стили цвета для фона графика и сетки
        'paneProperties.background': '#0e0d0d',
        'paneProperties.vertGridProperties.color': '#0e0d0d',
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.horzGridProperties.color': '#0e0d0d',
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.crossHairProperties.color': '#989898',
        'paneProperties.crossHairProperties.width': 1,
        'paneProperties.crossHairProperties.style': 2,

        'scalesProperties.lineColor': '#fff',
        'scalesProperties.textColor': '#fff',
        'scalesProperties.backgroundColor' : '#ffffff',

      };
    }

    if (this.appConfig.Settings.common.AppName === 'Zetradex' && Environment.IsMobileVersion) {
      widgetOptions.disabled_features = ['edit_buttons_in_legend', 'use_localstorage_for_settings', 'header_compare', 'go_to_date', 'header_symbol_search'];
    }

    if (Environment.IsMobileVersion) {
      widgetOptions.enabled_features = ['chart_crosshair_menu', 'dont_show_boolean_study_arguments', 'hide_last_na_study_output', 'show_symbol_logos', 'show_symbol_logo_in_legend', 'hide_price_scale_global_last_bar_value'];
    }

    return widgetOptions;
  }

  private getUpColor(): string {
    return this.appConfig.Settings.common.AppName === 'Zetradex' ? '#089981' : '#33ba86';
  }

  private getDownColor(): string {
    return this.appConfig.Settings.common.AppName === 'Zetradex' ? '#f23645' : '#ee5615';
  }

  private checkShowSessionDisplay() {
    if (this.symbol.SymbolInfo.session_display === undefined) {
      this.IFrameBody.classList.add('hidden-session-display');
    }
  }

  private hiddenLoaderIndicator() {
    const loadingIndicator = document.getElementById(this.containerId).getElementsByTagName('iframe')[0].contentWindow.document.querySelector('.loading-indicator');
    loadingIndicator.removeAttribute('style');
    loadingIndicator.setAttribute('style', 'display: none');
  }

  private async onReady() {
    this.TvWidgetChartReady = true;
    this.hiddenLoaderIndicator();
    this.IFrameBody = document.getElementById(this.containerId).getElementsByTagName('iframe')[0].contentWindow.document.querySelector('.chart-page');

    if (this.terminalService.checkYouNeedAnActivityCheck()) {
      this.IFrameBody.addEventListener('mousemove', this.terminalService.onEventIFrameBody.bind(this.terminalService), {capture: true});
    }

    this.tvWidget.subscribe('onTick', (tick: Bar) => this.onTick(tick));
    this.setCurrentSymbolVariable(this.ActiveChart.symbol());
    this.checkShowSessionDisplay();
    this.terminalService.setTitle(this.symbolName);

    this.ActiveChart.onSymbolChanged().subscribe(null, () => {
      this.IFrameBody.classList.remove('hidden-session-display');
      this.datafeed.resetData(true);

      this.logger.debug('Symbol changed: ' + this.ActiveChart.symbol());
      console.log('Symbol changed: ' + this.ActiveChart.symbol());

      this.setCurrentSymbolVariable(this.ActiveChart.symbol());
      this.checkShowSessionDisplay();

      // на мобильной платформе не всегда срабатывает изменения символа при переходе с Quotes (возможно кэш сервиса графика и из-за того,
      // что передаются старые настройки графика для отображения линий и индикаторов) поэтому при переходе идет присвоение
      // символа ActiveChart, при переключении символа на самом графике присвоение не срабатывает по условию проверки if

      if (Environment.IsMobileVersion && this.firstEnterMobile) {
        this.firstEnterMobile = false;
        this.ActiveChart.setSymbol(this.symbolName, () => {});
      }

      this.symbolName = this.ActiveChart.symbol();
      this.OnChartChanged.emit();
      this.saveInStorageSymbolNameForTrader(this.symbolName, this.localStorageService, 'chartSymbolNameForTrader');
      this.saveInStorageSymbolNameForTrader(this.symbolName, this.sessionStorageService, 'chartSymbolNameForTrader');

      if (this.showOrderLineChartsForMobile()) {
        this.runOrderLine();
      }

      this.spreadShowLive();

      this.changeSymbolForMobile.emit(this.symbolName);
      this.terminalService.setTitle(this.symbolName);
    });

    this.ActiveChart.onIntervalChanged().subscribe(null,
      (interval: string) => {
      this.changeIntervalCharts(interval);
      });

    if (!this.IsReadOnlyAccount) {
      this.initializeContextMenu();
    }

    // Subscribing to layout change in order to save TV layout and studies
    this.tvWidget.subscribe('toggle_sidebar', async () => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('toggle_header', async () => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('drawing', async params => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('drawing_event', async (id, eventType) => {
      this.OnDrawingEvent.emit(eventType);
    });
    this.tvWidget.subscribe('undo_redo_state_changed', async state => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('add_compare', async () => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('onSelectedLineToolChanged', async () => {
      this.OnChartChanged.emit();
    });
    this.tvWidget.subscribe('layout_changed', async () => {
      this.OnChartChanged.emit();
    });

    /*this._tvWidget.activeChart().onVisibleRangeChanged().subscribe(null, async () => {
      await this.saveLayout();
    });*/

    // Saving studies
    this.tvWidget.subscribe('study', async params => {
      this.OnChartChanged.emit();
    });

    this.tvWidget.subscribe('onPlusClick', async params => {
      this.plusClickEvent(params);
    });

    this.onChartReady.emit(this.containerId);
    this.ExternalOnChartReady.emit();

    if (this.showOrderLineChartsForMobile()) {
      this.tradeStorage.changeTradesArray.subscribe(() => {

        if (this.ActiveChart !== null) {
          const openTradWithSymbolIsNotActive = this.tradeStorage.OpenedTrades.find((e) => e.Symbol.SymbolName === this.ActiveChart.symbol());
          const symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());

          if ( (symbol === undefined || !symbol.IsActive) && openTradWithSymbolIsNotActive === undefined) {
            this.setSymbol(this.appConfig.resolveDefaultSymbol(this.trader.TradeGroupId));
          } else {
            this.orderLineForOpenOrders();
          }
        }
      });
    }

    // this.themeService.getThemeChange().subscribe((value) => {
    //   console.log(65);
    //   this.eventThemeChange(value);
    // });

    this.settingsService.Settings.UseTradingChangedEmitter.subscribe(() => {
      if (this.tvWidget) {
        this.orderLineForOpenOrders();
      }
    });

    // подписка на изменения zoom чарта
    this.ActiveChart.getTimeScale().barSpacingChanged().subscribe(this, this.changeSpacing);
  }

  private changeSpacing(newBarSpacing: number) {
    console.log('barSpacingChanged -', newBarSpacing);
  }

  private eventThemeChange(value: Theme) {
    if (this.tvWidget !== null) {
      if (value.category === 'dark' ) {
        this.changeColorTheme('dark', 'customButtonDarkTheme');
        this.changeColorThemeSpread('dark');
        this.changColorAlertPriceChangeButton('dark');

        if (this.appConfig.Settings.common.AppName === 'Zetradex') {
          this.changeColorBtnBuySell(this.buyButton, 'dark');
          this.changeColorBtnBuySell(this.sellButton, 'dark');

          if (this.buyButton.children.length === 1 ) {
            this.buyButton.removeChild(this.buyButton.lastChild);
            this.buyButton.append(this.iconCreateElement('dark'));
          }
          if (this.sellButton.children.length === 1 ) {
            this.sellButton.removeChild(this.sellButton.lastChild);
            this.sellButton.append(this.iconCreateElement('dark'));
          }

        }
      } else {
        this.changeColorTheme('light', 'customButtonLightTheme');
        this.changeColorThemeSpread('light');
        this.changColorAlertPriceChangeButton('light');
        if (this.appConfig.Settings.common.AppName === 'Zetradex') {
          this.changeColorBtnBuySell(this.buyButton, 'light');
          this.changeColorBtnBuySell(this.sellButton, 'light');

          if (this.buyButton.children.length === 1 ) {
            this.buyButton.removeChild(this.buyButton.lastChild);
            this.buyButton.append(this.iconCreateElement('light'));
          }
          if (this.sellButton.children.length === 1 ) {
            this.sellButton.removeChild(this.sellButton.lastChild);
            this.sellButton.append(this.iconCreateElement('light'));
          }
        }
      }

      if (this.PriceDirection === PriceDirection.Ask) {
        this.askButton.setAttribute('style', this.selectedStyle);
        this.bidButton.removeAttribute('style');
      } else {
        this.askButton.removeAttribute('style');
        this.bidButton.setAttribute('style', this.selectedStyle);
      }

      this.savedChartChangeTheme.emit();
      this.lineForAskBidInChart();

      if (this.showOrderLineChartsForMobile()) {
        this.orderLineForOpenOrders(value.category);
      }
    }

  }

  private changeIntervalCharts(interval: string) {
    this.sessionStorageService.saveDataInStorage('currentIntervalForChart', JSON.stringify(this.intervalForSave(interval)));

    if (['5S', '1', '5', '15', '30', '60', '240', '1D', 'D', '1W', 'W', '1M', 'M'].indexOf(interval) >= 0) {
      this.interval = interval;
      this.reset();
    } else {
      interval = this.FixNotSupportedInterval(interval as ResolutionString);
      this.interval = interval;
      this.ActiveChart.setResolution(interval as ResolutionString, () => {
        this.reset();
      });
    }

    this.saveInStorageSymbolNameForTrader(this.symbolName, this.localStorageService, 'chartSymbolNameForTrader',  this.interval);
    this.saveInStorageSymbolNameForTrader(this.symbolName, this.sessionStorageService, 'chartSymbolNameForTrader',  this.interval);

    this.logger.debug('interval changed: ' + interval);

    this.OnChartChanged.emit();
    this.ExternalOnChartReady.emit();
  }

  private runOrderLine() {
    setTimeout(() => {
      if (this.ActiveChart !== null) {
        this.orderLineForOpenOrders();
      }
    }, 2200);
  }

  // удаление всех линий на графике
  private delLineForCharts(map: Map<any, any>) {
    if (map.size !== 0) {
      map.forEach((value, key) => {
        map.get(key).remove();
        map.delete(key);
      });
    }
  }

  // обновление ордера при изменении линий на графике
  private async modificationOpenOrder(volume: number, typeModification: string, trade: Trade) {

    if (typeModification === undefined) {
      this.orderLineForOpenOrders();
      return;
    }

    const currentPrice = this.priceDirection === 0 ? trade.CalculateObject.Symbol.LastQuote.Bid :  trade.CalculateObject.Symbol.LastQuote.Ask;

    const tradeDTO = new TradeDTO().readFromTrade(trade);

    if (typeModification === 'SL' || typeModification === 'SL Limit' || typeModification === 'SL Stop') {
      tradeDTO.StopLoss = volume;
    } else if (typeModification === 'TP' || typeModification === 'TP Limit' || typeModification === 'TP Stop') {
      tradeDTO.TakeProfit = volume;
    } else if (typeModification === 'TS' || typeModification === 'TS Limit' || typeModification === 'TS Stop') {
      if (volume < this.appConfig.Settings.trading.MinStopPipsInterrupt) {
        this.orderLineForOpenOrders();
        return;
      }
      tradeDTO.TrailingStop = volume;
    } else if (typeModification === 'TS Close') {
      tradeDTO.TrailingStop = volume;
    } else {
      if (this.appConfig.Settings.synchronousMovementOfOrderLines !== undefined && this.appConfig.Settings.synchronousMovementOfOrderLines) {

        if (trade.CalculateObject.Type === 2 || trade.CalculateObject.Type === 5 || trade.CalculateObject.Type === 3 || trade.CalculateObject.Type === 4 ) {

          const differences = Number(Math.abs(tradeDTO.OpenPrice - volume).toFixed(tradeDTO.Symbol.DecimalPlaces));

          if (tradeDTO.OpenPrice < volume) {
            if (tradeDTO.TakeProfit !== 0) {
              tradeDTO.TakeProfit = tradeDTO.TakeProfit + differences;
            }
            if (tradeDTO.StopLoss !== 0) {
              tradeDTO.StopLoss = tradeDTO.StopLoss + differences;
            }
          } else {
            if (tradeDTO.TakeProfit !== 0) {
              tradeDTO.TakeProfit = tradeDTO.TakeProfit - differences;
            }
            if (tradeDTO.StopLoss !== 0) {
              tradeDTO.StopLoss = tradeDTO.StopLoss - differences;
            }
          }
        }
      }

      tradeDTO.OpenPrice = volume;

      if (((trade.CalculateObject.Type === 2 || trade.CalculateObject.Type === 5) && currentPrice > volume) ||
          ((trade.CalculateObject.Type === 3 || trade.CalculateObject.Type === 4) && currentPrice < volume)) {
        this.orderLineForOpenOrders();
        return;
      }
    }

    const answer = await this.tradeService.updatePosition(tradeDTO);

    if (answer === undefined) {
      this.orderLineForOpenOrders();
    }
  }

  private async closeOrder(trade: Trade) {
    if (!this.settingsService.Settings.OneClickTrading) {
      await this.router.navigate(['/terminal', {outlets: {modal: ['close', trade.TradeId]}}]);
    } else {
      const tradeDTO = new TradeDTO().readFromTrade(trade);
      const answer = await this.tradeService.closeTrade(tradeDTO);
      if (answer === undefined) {
        this.orderLineForOpenOrders();
      }
    }

  }

  private getColorForLine(typeLine?: string, themeMode?: string): string {
    const pink = 'rgb(120,12,182)';
    const red = 'rgb(255,0,0)';
    const blue = 'rgb(0,0,255)';
    const white = 'rgb(250,250,250)';
    const black = 'rgb(0,0,0)';
    const orange = 'rgb(182,109,12)';
    const turquoise = 'rgb(17,170,190)';
    const green = 'rgb(51,186,134)';
    const lRed = 'rgb(238,86,21)';

    if (typeLine === 'Buy' || typeLine === 'Sell') {
      if (this.appConfig.Settings.common.AppName === 'Zetradex') {
        if (Environment.IsMobileVersion && this.tvWidget.getTheme() === 'dark') {
          return white;
        }
        return black;
      }
      if (typeLine === 'Sell') {
        return lRed;
      }
      return green;
    }

    if (typeLine === 'Order') {
      if (this.appConfig.Settings.common.AppName === 'Zetradex') {
        if (themeMode) {
          if (themeMode === 'dark') {
            return white;
          }

        } else {
          if (this.tvWidget.getTheme() === 'dark') {
            return white;
          }
        }

        return black;
      }
      return turquoise;
    }

    if (typeLine === 'SL') {
      if (this.appConfig.Settings.common.AppName === 'Zetradex') {
        return red;
      }
      return orange;
    }

    if (typeLine === 'TP') {
      return blue;
    }

    if (typeLine === 'TS') {
      return pink;
    }

    if (this.appConfig.Settings.common.AppName === 'Zetradex') {
      if (themeMode) {
        if (themeMode === 'dark') {
          return black;
        }

      } else {
        if (this.tvWidget.getTheme() === 'dark') {
          if (Environment.IsMobileVersion) {
            return white;
          }
          return black;
        }
      }
    }

    return white;
  }

  // отрисовка линий на графике
  private orderLineForOpenOrders(themeMode?: string) {
    this.delLineForCharts(this.linesHorizontal);
    const decimalPlaces = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol()).DecimalPlaces;
    this.arrayOpenOrders = this.tradeStorage.OpenedTrades.filter((e) => e.Symbol.SymbolName.toLowerCase() === this.ActiveChart.symbol().toLowerCase());
    let positionLineLength = 100;
    let changeTypeSLTP: string;
    const colorWhite = this.getColorForLine('', themeMode);
    const white = 'rgb(255,255,255)';
    const colorTextForLightMode = '#131722';
    const colorBuyTypes = this.getColorForLine('Buy', themeMode);
    const colorSellTypes = this.getColorForLine('Sell', themeMode);
    const colorOrder = this.getColorForLine('Order', themeMode);
    const colorSL = this.getColorForLine('SL');
    const colorTP = this.getColorForLine('TP');
    const colorTS = this.getColorForLine('TS');
    let colorTypeOrder: string;
    let labelTypeOrder: string;
    let labelTypeOrderSL: string;
    let labelTypeOrderTP: string;
    let labelTypeOrderTS: string;

    const isUseLotTrading = this.settingsService.Settings.UseLotTrading;
    const onTradesChangedOrder = (volumeChanged: string | number, trade: Trade, type: string) => {
      if (type === 'Close') {
        this.closeOrder(trade).then();
      } else {
        this.modificationOpenOrder(Number(volumeChanged), type, trade).then();
      }
    };

    const modifyTrade = (trade: Trade) => {
      if (Environment.Platform === 'tv-desktop') {
        this.tradeAction.modifyTrade(trade);
      }

      if (Environment.Platform === 'tv-mobile') {
        this.tradeAction.modifyTradeMobile(trade);
      }
    };

    const setVolumeTrade = (trade: Trade): string => {
      if (isUseLotTrading) {
        return `${OperationsWithVolume.convertFromVolumeToLots(trade.Volume, trade.Symbol.ContractSize)} lot`;
      } else {
        return `${trade.Volume} amt.`;
      }
    };

    const textDataSLTPTS = (pips: number, profit?: number) => {
      if (profit) {
        return ` ${pips} pip(s) \n Value: ${profit} USD `;
      }
      return ` ${pips} pip(s)`;
    };

    const checkedType = (order: Trade) => {
      if (order.Type === 0) {
        colorTypeOrder = colorSellTypes;
        labelTypeOrder = 'Sell';
        labelTypeOrderSL = 'SL';
        labelTypeOrderTP = 'TP';
        labelTypeOrderTS = 'TS';
      } else if (order.Type === 1) {
        colorTypeOrder = colorBuyTypes;
        labelTypeOrder = 'Buy';
        labelTypeOrderSL = 'SL';
        labelTypeOrderTP = 'TP';
        labelTypeOrderTS = 'TS';
      } else if (order.Type === 2) {
        colorTypeOrder = colorSellTypes;
        labelTypeOrder = 'Sell Limit';
        labelTypeOrderSL = 'SL Limit';
        labelTypeOrderTP = 'TP Limit';
        labelTypeOrderTS = 'TS Limit';
      } else if (order.Type === 3) {
        colorTypeOrder = colorSellTypes;
        labelTypeOrder = 'Sell Stop';
        labelTypeOrderSL = 'SL Stop';
        labelTypeOrderTP = 'TP Stop';
        labelTypeOrderTS = 'TS Stop';
      } else  if (order.Type === 4) {
        colorTypeOrder = colorBuyTypes;
        labelTypeOrder = 'Buy Limit';
        labelTypeOrderSL = 'SL Limit';
        labelTypeOrderTP = 'TP Limit';
        labelTypeOrderTS = 'TS Limit';
      } else if (order.Type === 5) {
        colorTypeOrder = colorBuyTypes;
        labelTypeOrder = 'Buy Stop';
        labelTypeOrderSL = 'SL Stop';
        labelTypeOrderTP = 'TP Stop';
        labelTypeOrderTS = 'TS Stop';
      }
    };

    const createOrderOPHorizontalLine = (e: Trade, lineLength?: number): IOrderLineAdapter => {
      return this.tvWidget.chart().createOrderLine()
        .setText(`${labelTypeOrder}/#${e.TradeId}`)
        .setLineLength(lineLength ? lineLength : positionLineLength)
        .setLineStyle(0)
        .setQuantity(`@ ${setVolumeTrade(e)} ✎`)
        .setLineColor(colorOrder)
        .setBodyBackgroundColor(white)
        .setBodyBorderColor(colorOrder)
        .setBodyTextColor(colorTypeOrder)
        .setQuantityBorderColor(colorOrder)
        .setQuantityTextColor(colorWhite)
        .setQuantityBackgroundColor(colorOrder)
        .setPrice(e.OpenPrice)
        .setCancelTooltip('Close order')
        .onCancel( function () { onTradesChangedOrder(e.OpenPrice, e, 'Close'); })
        .onModify(function () { modifyTrade(e); });
    };

    const createOrderHorizontalLineForMobile = (e: Trade, type: string, color: string, typePrice: string): IOrderLineAdapter => {
      let price: number;
      let text = `@ ${setVolumeTrade(e)}`;
      switch (typePrice) {
        case 'Open':
          price = e.OpenPrice;
        break;
        case 'SL':
          price = e.StopLoss;
          break;
        case 'TP':
          price = e.TakeProfit;
          break;
        case 'TS':
          price = e.PriceTrailingStop;
          text = ` ${e.TrailingStop} pip(s)`;
          break;
      }

      let colorText = colorWhite;

      if (this.tvWidget.getTheme() === 'light') {
        colorText = colorTextForLightMode;
      }

      const line = this.tvWidget.chart().createOrderLine({disableUndo: false})
        .setText(`${type}`)
        .setQuantity(text)
        .setLineLength(positionLineLength)
        .setLineStyle(0)
        .setLineColor(color)
        .setBodyBackgroundColor('rgba(17,170,190,0)')
        .setBodyBorderColor('rgba(17,170,190,0)')
        .setBodyTextColor(colorTypeOrder)
        .setQuantityBorderColor('rgba(17,170,190,0)')
        .setQuantityTextColor(colorText)
        .setQuantityBackgroundColor('rgba(17,170,190,0)')
        .setPrice(price);

      if (typePrice !== 'Open' ) {
        line.onMove(function () {
          this.setText(type);
          onTradesChangedOrder(this.getPrice().toFixed(decimalPlaces), e, type); })
          .onMoving(function () {
            this.setText(type + '/' + '#' + e.TradeId + textDataSLTPTS(
              CustomNumber.roundForPlace(e.ProfitCalculator.calculatePips(e.OpenPrice, this.getPrice()), 2),
              CustomNumber.roundForPlace(e.ProfitCalculator.calculateProfit(e.Volume, e.OpenPrice, this.getPrice()), 2))); });
      }

      return line;
    };

    const createOrderSLTPHorizontalLine = (e: Trade, colorType: string, type: string, price: number): IOrderLineAdapter => {
      const pips = CustomNumber.roundForPlace(e.ProfitCalculator.calculatePips(e.OpenPrice, price), 2);
      const profit = CustomNumber.roundForPlace(e.ProfitCalculator.calculateProfit(e.Volume, e.OpenPrice, price), 2);
      const text = type + '/' + '#' + e.TradeId;
      const textTooltip = textDataSLTPTS(pips, profit);
      return this.tvWidget.chart().createOrderLine({disableUndo: false})
        .setText(text)
        .setLineLength(positionLineLength)
        .setLineStyle(0)
        .setQuantity(`@ ${price}`)
        .setLineColor(colorType)
        .setBodyBackgroundColor(white)
        .setBodyBorderColor(colorType)
        .setBodyTextColor(colorTypeOrder)
        .setQuantityBorderColor(colorType)
        .setQuantityTextColor(white)
        .setQuantityBackgroundColor(colorType)
        .setCancelTooltip(`Cancel ${ type }`)
        .setModifyTooltip(`Modify ${ type }`)
        .setTooltip(textTooltip)
        .onMove(function () { this.setText(text);
          this.setTooltip(textTooltip);
          this.setQuantity(`@ ${this.getPrice()}`);
          onTradesChangedOrder(this.getPrice().toFixed(decimalPlaces), e, type); })
        .onMoving(function () { this.setText(labelTypeOrderSL + '/' + '#' + e.TradeId + textDataSLTPTS(
          CustomNumber.roundForPlace(e.ProfitCalculator.calculatePips(e.OpenPrice, this.getPrice()), 2),
          CustomNumber.roundForPlace(e.ProfitCalculator.calculateProfit(e.Volume, e.OpenPrice, this.getPrice()), 2)));
          this.setQuantity(`@ ${this.getPrice()}`); })
        .onCancel( function () { onTradesChangedOrder(0, e, type); })
        .setPrice(price);
    };

    const createOrderTSHorizontalLine = (e: Trade, colorType: string, type: string, price: number): IOrderLineAdapter => {
      const text = type + '/' + '#' + e.TradeId;
      return this.tvWidget.chart().createOrderLine({disableUndo: false})
        .setText(text)
        .setLineLength(positionLineLength)
        .setLineStyle(0)
        .setQuantity(` ${e.TrailingStop} pip(s)`)
        .setLineColor(colorType)
        .setBodyBackgroundColor(white)
        .setBodyBorderColor(colorType)
        .setBodyTextColor(colorTypeOrder)
        .setQuantityBorderColor(colorType)
        .setQuantityTextColor(white)
        .setQuantityBackgroundColor(colorType)
        .setCancelTooltip(`Cancel ${ type }`)
        .setModifyTooltip(`Modify ${ type }`)
        .onMove(function () {
          this.setQuantity(` ${CustomNumber.roundForPlace(Math.abs(e.ProfitCalculator.calculatePips(e.LastPriceTrailingStop, this.getPrice())), 0)} pip(s)`);
          onTradesChangedOrder(CustomNumber.roundForPlace(Math.abs(e.ProfitCalculator.calculatePips(e.LastPriceTrailingStop, this.getPrice())), 0), e, type); })
        .onMoving(function () {
          this.setQuantity(` ${CustomNumber.roundForPlace(Math.abs(e.ProfitCalculator.calculatePips(e.LastPriceTrailingStop, this.getPrice())), 0)} pip(s)`); })
        .onCancel( function () { onTradesChangedOrder(0, e, 'TS Close'); })
        .setPrice(price);
    };

    const changeOrderOPHorizontalLine = (line: IOrderLineAdapter, e: Trade) => {
      line
        .onMoving(function () { line.setText(changeTypeSLTP + '/' + '#' + e.TradeId + textDataSLTPTS(
          CustomNumber.roundForPlace(e.ProfitCalculator.calculatePips(e.OpenPrice, line.getPrice()), 2),
          CustomNumber.roundForPlace(e.ProfitCalculator.calculateProfit(e.Volume, e.OpenPrice, line.getPrice()), 2)));
          this.setQuantity(`@ ${this.getPrice()}`);

            if (e.Type === 0 ) {
              if (e.OpenPrice < line.getPrice()) {
                changeTypeSLTP = 'SL';
                changColorLine(line, colorSL);
              }

              if (e.OpenPrice > line.getPrice() ) {
                changeTypeSLTP = 'TP';
                changColorLine(line, colorTP);
              }

            } else {
              if (e.OpenPrice > line.getPrice() ) {
                changeTypeSLTP = 'SL';
                changColorLine(line, colorSL);
              }

              if (e.OpenPrice < line.getPrice()) {
                changeTypeSLTP = 'TP';
                changColorLine(line, colorTP);
              }
            }
        });
    };

    const changColorLine = (line: IOrderLineAdapter, colorStyle: string) => {
      line.setLineColor(colorStyle)
        .setBodyBorderColor(colorStyle)
        .setQuantityBorderColor(colorStyle)
        .setQuantityBackgroundColor(colorStyle);
    };

    const changOrderLine = (line: IOrderLineAdapter, e: Trade) => {
      checkedType(e);
      changeOrderOPHorizontalLine(line, e);
      const orderOPHorizontalLineDuplicate = createOrderOPHorizontalLine(e, line.getLineLength());
      addLinesHorizontal(e.TradeId, 'Open Price Duplicate', orderOPHorizontalLineDuplicate);
    };

    const addLinesHorizontal = (tradeId: number, type: string, orderLine: IOrderLineAdapter) => { this.linesHorizontal.set(`#${tradeId} ${type}`, orderLine); };

    // перед отрисовкой линий ордеров на графике, идет проверка на наличие массива ордеров для текущего символа и проверки видимого диапазона для символа
    // (нулевые показатели говорят о том что массив свечей для графика пришел пустой и может вызвать ошибку в библиотеке графика)
    const visibleRange = this.tvWidget.activeChart().getVisibleRange();



    if (!this._isRenderLogs) {
      this._isRenderLogs = true;
      console.log('visibleRange', 23, visibleRange);
      console.log('ArrayOpenOrders', 23, this.arrayOpenOrders);
      setTimeout(() => this._isRenderLogs = false, 3000);
    }


    if (this.arrayOpenOrders.length > 0 && (visibleRange.from !== 0 || visibleRange.to !== 0)) {
      this.visibility = false;
      this.orderLine = true;
      this.changeVisibilityButton(false);

      this.arrayOpenOrders.forEach((e: Trade) => {

        if (e.changeVisibility.observers.length === 0 ) {
          e.changeVisibility.subscribe(() => {
            this.orderLineForOpenOrders();
          } );
        }

        if (e.changePriceTrailingStop.observers.length === 0 ) {
          e.changePriceTrailingStop.subscribe(() => {
            this.orderLineForOpenOrders();
          } );
        }

        // в зависимости от типа ордера присваивается соответсвующая имя и цвет
        checkedType(e);

        if (e.VisibilityLine) {
          let orderOPHorizontalLine: IOrderLineAdapter;

          if (Environment.Platform === 'tv-desktop') {
            orderOPHorizontalLine = createOrderOPHorizontalLine(e);
            let isMoving = false;
            if (e.Type === 2 || e.Type === 3 || e.Type === 4 || e.Type === 5 ) {
              orderOPHorizontalLine
                .setCancelTooltip('Cancel order')
                .onMove(function () {
                  onTradesChangedOrder(orderOPHorizontalLine.getPrice().toFixed(decimalPlaces), e, 'Pending ' );
                  this.setQuantity(`@ ${this.getPrice()}`); })
                .onMoving( function () { this.setQuantity(`@ ${this.getPrice()}`);
                });
            } else {
              if (e.StopLoss === 0 || e.TakeProfit === 0) {
                orderOPHorizontalLine
                  .onMove(function () {
                    onTradesChangedOrder(orderOPHorizontalLine.getPrice().toFixed(decimalPlaces), e, changeTypeSLTP );
                    if (changeTypeSLTP !== undefined) {
                      orderOPHorizontalLine.setQuantity(`@ ${this.getPrice()}`);
                    }
                  })
                  .onMoving(function () {
                    if (!isMoving) {
                      if (e.Type === 0 ) {
                        if (e.OpenPrice < orderOPHorizontalLine.getPrice() && e.StopLoss === 0 ) {
                          changeTypeSLTP = 'SL';
                          changOrderLine(this, e);
                        }
                        if (e.OpenPrice > orderOPHorizontalLine.getPrice() && e.TakeProfit === 0 ) {
                          changeTypeSLTP = 'TP';
                          changOrderLine(this, e);
                        }
                      } else {
                        if (e.OpenPrice > orderOPHorizontalLine.getPrice() && e.StopLoss === 0 ) {
                          changeTypeSLTP = 'SL';
                          changOrderLine(this, e);
                        }
                        if (e.OpenPrice < orderOPHorizontalLine.getPrice() && e.TakeProfit === 0 ) {
                          changeTypeSLTP = 'TP';
                          changOrderLine(this, e);
                        }
                      }
                      isMoving = true;
                    }
                  });
              }
            }
          } else {
            orderOPHorizontalLine = createOrderHorizontalLineForMobile(e, labelTypeOrder, colorOrder, 'Open');
          }

          addLinesHorizontal(e.TradeId, 'Open Price', orderOPHorizontalLine);

          if (e.StopLoss !== 0 ) {
            let orderSLHorizontalLine: IOrderLineAdapter;
            if (Environment.Platform === 'tv-desktop') {
              orderSLHorizontalLine = createOrderSLTPHorizontalLine(e, colorSL, labelTypeOrderSL, e.StopLoss );
            } else {
              orderSLHorizontalLine = createOrderHorizontalLineForMobile(e, labelTypeOrderSL, colorSL, 'SL');
            }
            addLinesHorizontal(e.TradeId, 'SL', orderSLHorizontalLine);
          }

          if (e.TakeProfit !== 0 ) {
            let orderTPHorizontalLine: IOrderLineAdapter;
            if (Environment.Platform === 'tv-desktop') {
              orderTPHorizontalLine = createOrderSLTPHorizontalLine(e, colorTP, labelTypeOrderTP, e.TakeProfit );
            } else {
              orderTPHorizontalLine = createOrderHorizontalLineForMobile(e, labelTypeOrderTP, colorTP, 'TP');
            }
            addLinesHorizontal(e.TradeId, 'TP', orderTPHorizontalLine);
          }

          if (e.TrailingStop !== 0 && e.PriceTrailingStop) {
            let orderTSHorizontalLine: IOrderLineAdapter;
            if (Environment.Platform === 'tv-desktop') {
              orderTSHorizontalLine = createOrderTSHorizontalLine(e, colorTS, labelTypeOrderTS, e.PriceTrailingStop );
            } else {
              orderTSHorizontalLine = createOrderHorizontalLineForMobile(e, labelTypeOrderTS, colorTS, 'TS');
            }
            addLinesHorizontal(e.TradeId, 'TS', orderTSHorizontalLine);
          }

          positionLineLength = positionLineLength - 26;

          if (positionLineLength < 0 ) {
            positionLineLength = 90;
          }
        }
      });
      this.checkVisibilityOrdersForCharts(this.arrayOpenOrders);
    } else {
      this.orderLine = false;
      this.changeVisibilityButton(true);
    }
  }

  private lineForAskBidInChart() {
    if ( this.showAskBidLineChart) {
      this.delLineForCharts(this.linesAskBid);
      const symbol: Symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());
      const nameLine = this.PriceDirection === 1 ? 'Ask' : 'Bid';
      const colorLine = this.tvWidget.getTheme() === 'dark' ? 'rgb(255,255,255)' : 'rgba(4,35,159,0.62)';
      let price: number;
      switch (this.PriceDirection) {
        case 0:
          price = symbol.LastQuote.Bid;
          break;
        case 1:
          price = symbol.LastQuote.Ask;
          break;
      }

      const createLineAskBidForChart = ( color: string): IOrderLineAdapter => {
        return this.tvWidget.chart().createOrderLine()
          .setText(`${nameLine}`)
          .setLineLength(0)
          .setLineStyle(1)
          .setLineColor(color)
          .setBodyBackgroundColor('rgba(17,170,190,0)')
          .setBodyBorderColor('rgba(17,170,190,0)')
          .setBodyTextColor(color)
          .setQuantityBorderColor('rgba(17,170,190,0)')
          .setQuantityTextColor('rgba(17,170,190,0)')
          .setQuantityBackgroundColor('rgba(17,170,190,0)')
          .setPrice(price);
      };

      const addLines = ( name: string, line: IOrderLineAdapter) => { this.linesAskBid.set(`#${name}`, line); };

      const askBidLine: IOrderLineAdapter = createLineAskBidForChart( colorLine );

      addLines(nameLine, askBidLine);
    }

  }

  private FixNotSupportedInterval(interval: string): string {

    if (interval.includes('D')) {
      const clearString = interval.replace('D', '');
      const castedInterval = Number(clearString);
      if (castedInterval > 30) {
        return 'M' as ResolutionString;
      } else if (castedInterval > 7) {
        return 'W' as ResolutionString;
      } else {
        return 'D' ;
      }
    } else if (interval.includes('W')) {
      const clearString = interval.replace('W', '');
      const castedInterval = Number(clearString);
      if (castedInterval > 4) {
        return 'M' ;
      } else {
        return 'W' as ResolutionString;
      }
    } else if (interval.includes('M')) {
      return 'M' as ResolutionString;
    } else {
      const castedInterval = Number(interval);
      if (castedInterval > 240) {
        return '240' as ResolutionString;
      } else if (castedInterval > 60) {
        return '60' as ResolutionString;
      } else if (castedInterval > 30) {
        return '30' as ResolutionString;
      } else if (castedInterval > 15) {
        return '15' as ResolutionString;
      } else if (castedInterval > 5) {
        return '5' as ResolutionString;
      } else {
        return '1' as ResolutionString;
      }
    }
  }

  private get IsReadOnlyAccount() {
    return this.trader.IsReadOnlyAccount;
  }

  private onTick(bar: Bar) {
    if (this.showAskBidLineChart) {
      this.lineForAskBidInChart();
    }

    this.spreadShowLive();

    const currentTickBar = bar;
    const currentSymbolTick = this.ActiveChart.symbol();
    let stepPercent = 0;

    if (this.symbolLastTick === undefined) {
      this.symbolLastTick = this.ActiveChart.symbol();
    }

    // при переключении символов проверяем их на соответствие с symbolLastTick или проверка на новую свечку, чтобы обновить данные
    if (this.symbolLastTick !== currentSymbolTick || (this.lastTickBar !== undefined && this.lastTickBar.open !== currentTickBar.open)) {
      this.lastTickBar = undefined;
      this.symbolLastTick = this.ActiveChart.symbol();
    }

    // проверка на новую свечку и далее высчитываем один процент и в зависимости от движения свечи высчитываем на сколько процентов она изменилась
    if (this.lastTickBar === undefined) {

      const onePercent = currentTickBar.open / 100;

      if (currentTickBar.open < currentTickBar.high) {
        stepPercent = (currentTickBar.high / onePercent) - 100;
      }

      if (currentTickBar.high > currentTickBar.close) {
        stepPercent = 100 - (currentTickBar.low / onePercent);
      }

    } else {

      // если свеча старая процент высчитывается на основе данных предыдущей свечи
      if (currentTickBar.open < currentTickBar.high) {
        const onePercent = this.lastTickBar.high / 100;
        stepPercent = (currentTickBar.high / onePercent) - 100;
      }

      if (currentTickBar.high > currentTickBar.close) {
        const onePercent = this.lastTickBar.low / 100;
        stepPercent = 100 - (currentTickBar.low / onePercent);
      }

    }

    // проверка на сколько процентов были изменения, если больше 20% то идет запись в log, или же обновляется this.lastTickBar
    if (Number(stepPercent.toFixed(2)) < 20) {
      this.lastTickBar = bar;
    } else {
      console.log( `Symbol ${this.ActiveChart.symbol()} : ${JSON.stringify(bar)}`);
      this.logger.info(`Symbol ${this.ActiveChart.symbol()} : ${JSON.stringify(bar)}`);
    }

  }

  private async onHeaderReady() {

    if (this.appConfig.Settings.alertPriceChange !== undefined && this.appConfig.Settings.alertPriceChange && Environment.Platform !== 'tv-mobile' ) {
      this.alertPriceChangeButton = this.addButton(
        'left',
        ChartStrings.OpenAlertChartButtonTooltip,
        '',
        () => this.alertPriceChange(),
        false,
        'PriceChangeButton'
      );
    }

    this.askButton = this.addButton(
      'left',
      ChartStrings.OpenAskChartButtonTooltip,
      ChartStrings.OpenAskChartButtonTitle,
      () => this.PriceDirection = PriceDirection.Ask,
      false,
      'PriceDirectionAsk'
    );

    this.bidButton = this.addButton(
      'left',
      ChartStrings.OpenBidChartButtonTooltip,
      ChartStrings.OpenBidChartButtonTitle,
      () => this.PriceDirection = PriceDirection.Bid,
      false,
      'PriceDirectionBid'
    );

    if (this.showLineAskBid()) {
      this.onOffAskBidButton = this.addButton(
        'left',
        ChartStrings.ShowHideAskBidLineOnChartButtonTooltip,
        '',
        () => this.showHiddenLineAskBid(),
        false,
        'OnOffAskBidShowLine'
      );
    }

    // отображения кнопок для ордеров в самом чарте
    // для мобильной версии кнопки покупки и продаж не создаются в графике

    if (this.showOrderLineChartsForMobile()) {
      this.visibilityButton = this.addButton(
        'left',
        ChartStrings.VisibilityButtonTooltip,
        '',
        () => this.visibilityLineCharts('click'),
        false,
        'Visibility'
      );
    }

    if (Environment.Platform !== 'tv-mobile' && !this.IsReadOnlyAccount) {

      this.sellButton = this.addButton(
        'left',
        ChartStrings.OrderSellButtonTooltip,
        ChartStrings.OrderSellButtonTitle,
        () => this.buttonClickAction(TradeType.Sell, 0, this.settingsService.Settings.OneClickTrading),
        true,
        'Sell'
      );

      this.spreedButton = this.addButton(
        'left',
        'Spread symbol.',
        '',
        () => {},
        true,
        'Spread'
      );

      this.buyButton = this.addButton(
        'left',
        ChartStrings.OrderBuyButtonTooltip,
        ChartStrings.OrderBuyButtonTitle,
        () => this.buttonClickAction(TradeType.Buy, 0, this.settingsService.Settings.OneClickTrading),
        true,
        'Buy'
      );

      // подписка на изменения торгов в один клик для модификации кнопок открытия ордеров
      this.settingsService.Settings.OneClickModeChanged.subscribe((val) => {
        if (val) {

          if (this.buyButton.children.length === 0 ) {
            this.buyButton.append(this.iconCreateElement(this.tvWidget.getTheme()));
          }

          if (this.sellButton.children.length === 0 ) {
            this.sellButton.append(this.iconCreateElement(this.tvWidget.getTheme()));
          }

        } else {
          if (this.buyButton.children.length === 1 ) {
            this.buyButton.removeChild(this.buyButton.lastChild);
          }
          if (this.sellButton.children.length === 1 ) {
            this.sellButton.removeChild(this.sellButton.lastChild);
          }
        }
      });
    }

    if (this.appConfig.Settings.common.AppName !== 'Zetradex') {

      this.buttonDropDown = this.addButton(
        'left',
        ChartStrings.OpenSymbolSearch,
        '',
        () => this.tvWidget.activeChart().executeActionById('symbolSearch'),
        false,
        'DropDown'
      );

    } else {

      if (!Environment.IsMobileVersion) {
        this.buttonDropDown = this.addButton(
          'left',
          ChartStrings.OpenSymbolSearch,
          '',
          () => this.openCustomSearchSymbol(),
          false,
          'DropDown'
        );
      }

    }
  }

  // при торгах в один клик для кнопок открытия ордеров создается дополнительный тэг молнии
  private iconCreateElement(theme: string) {
    const div = document.createElement('div');
    div.setAttribute('class', 'icon-flash');

    if (this.appConfig.Settings.common.AppName === 'Zetradex') {
      div.setAttribute('style', `background-color: ${theme === 'dark' ? '#131722' : '#ffffff'};`);
    } else {
      div.setAttribute('style', `background-color: #ffffff;`);
    }

    return div;
  }

  public openChart(symbol: string, resolution: string , type: PriceDirection): Promise<void> {
    this.interval = resolution || this.interval;
    this.symbolName = symbol;
    this.PriceDirection = type;

    return this.refresh();
  }

  public setSymbol(symbol: string): Promise<void> {
    console.log('Set new Symbol: ', symbol);
    this.symbolName = symbol;
    let resolveFunc: any;

    const retPromise = new Promise<void>(resolve => {
      resolveFunc = resolve;
    });

    this.tvWidget.setSymbol(symbol, this.interval as ResolutionString, () => {
      resolveFunc();
    });

    return retPromise;
  }

  private setCurrentSymbolVariable(symbolName: string) {
    this.symbol =  this.symbolStorage.findSymbolByNameSync(symbolName);
    // проверка на подписку изменения цен, если её нет, то подписывается
    if (this.symbol !== undefined && this.symbol.LastQuote === undefined) {
      this.symbolService.subscribeToSymbol(this.symbol.SymbolId).then();
    }
  }

  private async refresh(): Promise<void> {
    this.reset();
    this.tvWidget.setSymbol(this.symbolName, this.interval as ResolutionString, () => {
    });
  }

  private initializeContextMenu() {
    this.tvWidget.onContextMenu((unixTime, price) => {
      const result: CMI[] = [];
      const items = this.createContextMenu(price);

      items.items.forEach(item => {
        let text = item.label;
        if (item.description !== undefined) {
          text += ' ' + item.description;
        }

        result.push({
          position: 'top',
          text: text,
          click: () => {
            this.resolveClickByActionType(item.actionType, price).then();
          }
        });
      });

      result.push({
        position: 'top',
        text: '-',
        click: () => {
        }
      });

      result.push({
        position: 'top',
        text: ChartStrings.SecurityInfo,
        click: () => { this.tvWidget.activeChart().executeActionById('showSymbolInfoDialog');
        }
      });

      return result;
    });
  }

  private plusClickEvent(params: PlusClickParams) {
    const items = this.createContextMenu(params.price);

    if (this.IsReadOnlyAccount) {
      items.items = items.items.slice(-1);
    }

    const value: PlusClickEventInterface = {
      paramsContextMenu: items,
      paramsPosition: params,
    };

    this.onPlusClickEvent.emit(value);
  }

  private createContextMenu(price: number): IChartContextMenu {
    const symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());
    return this.chartContextMenuComposerService.composeMenu(
      symbol,
      price,
      this.getLastPrice(symbol),
      this.getVolumeForTrading(symbol, price),
    );
  }

  public goClickActionCustomContextMenu(item: IChartContextMenuItem, price: number): void {
    this.resolveClickByActionType(item.actionType, price).then();
  }

  private async resolveClickByActionType(actionType: ContextMenuActionType, price: number) {
    switch (+actionType) {
      case ContextMenuActionType.SellStop:
        await this.buttonClickAction(TradeType.SellStop, price, this.settingsService.Settings.OneClickTrading);
        break;
      case ContextMenuActionType.SellLimit:
        await this.buttonClickAction(TradeType.SellLimit, price, this.settingsService.Settings.OneClickTrading);
        break;
      case ContextMenuActionType.BuyStop:
        await this.buttonClickAction(TradeType.BuyStop, price, this.settingsService.Settings.OneClickTrading);
        break;
      case ContextMenuActionType.BuyLimit:
        await this.buttonClickAction(TradeType.BuyLimit, price, this.settingsService.Settings.OneClickTrading);
        break;
      case ContextMenuActionType.MarketSellQuick:
        await this.buttonClickAction(TradeType.Sell, price);
        break;
      case ContextMenuActionType.MarketBuyQuick:
        await this.buttonClickAction(TradeType.Buy, price);
        break;
      case ContextMenuActionType.MarketSellDialog:
        await this.buttonClickAction(TradeType.Sell, price);
        break;
      case ContextMenuActionType.MarketBuyDialog:
        await this.buttonClickAction(TradeType.Buy, price);
        break;
      case ContextMenuActionType.TradeSettings:
        if (Environment.IsMobileVersion) {
          await this.router.navigate(['/terminal', 'account']);
        } else {
          await this.router.navigate(['/terminal', {outlets: {modal: 'settings'}}]);
        }
        break;
    }
  }

  private async buttonClickAction(tradeType: TradeType, price: number, isQuickClick = false) {
    const symbol = await this.symbolStorage.findSymbolByName(this.ActiveChart.symbol());
    let priceSymbol = price;

    if (priceSymbol === 0) {
      if (this.priceDirection === PriceDirection.Bid) {
        priceSymbol = symbol.LastQuoteInfo.Bid.Price;
      }

      if (this.priceDirection === PriceDirection.Ask) {
        priceSymbol = symbol.LastQuoteInfo.Ask.Price;
      }
    }

    let tradeVolume: number;
    const minLength = OperationsWithVolume.numberOfDigitsAfterDot(symbol.TradingStep);

    if (this.settingsService.Settings.UseLotTrading) {
      tradeVolume = OperationsWithVolume.checkVolume(this.getVolumeForTrading(symbol, priceSymbol, tradeType) * symbol.ContractSize, minLength)  / this.trader.LotDelimeter;
    } else {
      tradeVolume = OperationsWithVolume.checkVolume(this.getVolumeForTrading(symbol, priceSymbol, tradeType), minLength);
    }

    if (this.settingsService.Settings.OneClickTrading || isQuickClick) {
      const tradeDto = new TradeDTO();
      tradeDto.Symbol = symbol;
      tradeDto.Volume = tradeVolume;
      tradeDto.OpenPrice = priceSymbol;
      tradeDto.Type = tradeType;
      await this.oneClickTradeStrategy.openOrder(tradeDto);
    } else {
      if (Environment.IsMobileVersion) {
        await this.router.navigate(['/terminal/trading/multi', tradeType, symbol.SymbolId, priceSymbol.toFixed(symbol.DecimalPlaces)]);
      } else {
        let outlets;

        if (tradeType > 1) {
          outlets = {
            outlets: {
              modal: ['multi-order', tradeType,
                {
                  symbolId: symbol.SymbolId,
                  volume: this.getVolumeForTrading(symbol, priceSymbol, tradeType),
                  prices: priceSymbol.toFixed(symbol.DecimalPlaces)
                }]
            }
          };
        } else {
          outlets = {
            outlets: {
              modal: ['multi-order', tradeType,
                {
                  symbolId: symbol.SymbolId,
                  volume: this.getVolumeForTrading(symbol, priceSymbol, tradeType),
                }]
            }
          };
        }
        await this.router.navigate(['/terminal', outlets]);
      }
    }
  }

  // метод определяет значения для торгов
  private getVolumeForTrading(symbol: Symbol, price: number, tradeType?: TradeType ) {
    return this.tradeService.getVolumeForOpenOrders(symbol, price, tradeType );
  }

  private getLastPrice(symbol: Symbol): number {
    return this.priceDirection === PriceDirection.Ask ? symbol.LastQuote.Ask : symbol.LastQuote.Bid;
  }

  private addButton(align: 'left' | 'right', tooltip: string, caption: string,
                    onClick: (event: Event) => void, buttonForOpenOrder: boolean = false, typeButton?: string): HTMLElement {

    const button = this.tvWidget.createButton({align: align, useTradingViewStyle: false, });

    button.setAttribute('title', tooltip);
    button.addEventListener('click', onClick);
    button.textContent = caption;
    button.setAttribute('class', this._classNameForCustomButton);

    // вариант отображения кнопок для ордеров в самом чарте

    if (typeButton === 'PriceDirectionAsk' && this.PriceDirection === PriceDirection.Ask) {
      button.setAttribute('style', this.selectedStyle);
    }

    if (typeButton === 'PriceDirectionBid' && this.PriceDirection === PriceDirection.Bid) {
      button.setAttribute('style', this.selectedStyle);
    }

    if (buttonForOpenOrder) {

      if (this.settingsService.Settings.OneClickTrading && (typeButton === 'Buy' || typeButton === 'Sell')) {
        button.append(this.iconCreateElement(this.tvWidget.getTheme()));
      }

      if (typeButton === 'Buy') {
        if (this.appConfig.Settings.common.AppName === 'Zetradex') {
          button.setAttribute('class', 'z-buyButton');
          button.setAttribute('style', this.getColorBtnBuySell(this.tvWidget.getTheme()));
        } else {
          button.setAttribute('class', this._buyButton);
        }
      }

      if (typeButton === 'Sell') {
        if (this.appConfig.Settings.common.AppName === 'Zetradex') {
          button.setAttribute('class', 'z-sellButton');
          button.setAttribute('style', this.getColorBtnBuySell(this.tvWidget.getTheme()));
        } else {
          button.setAttribute('class', this._sellButton);
        }

      }

      if (typeButton === 'Spread') {
        button.setAttribute('style', this.getSpreadStyle(this.tvWidget.getTheme()));
        button.append(this.spreadCreateElement());
      }

      if (this.IsReadOnlyAccount) {
        button.setAttribute('style', 'display: none;');
      }
    }

    if (typeButton === 'DropDown') {
      button.setAttribute('class', `${this._classNameForCustomButton} ${this._dropDownButton}` );
    }

    if (typeButton === 'Visibility') {
      button.append(this.visibilityCreateElement());
    }

    if (typeButton === 'OnOffAskBidShowLine') {
      button.append(this.onOffAskBidCreateElement());
    }

    if (typeButton === 'PriceChangeButton') {
      button.append(this.alertPriceChangeButtonCreateElement());
    }

    return button;
  }

  private getSpreadStyle(theme: string): string {
    return  'position: absolute; ' +
            'z-index: 5; ' +
            'display: flex; ' +
            'align-items: center;' +
            'justify-content: center;' +
            'width: 15px; ' +
            'height: 20px; ' +
            'right: 210px; ' +
            `background-color: ${theme === 'dark' ? '#131722' : '#ffffff'};` +
            `color: ${theme === 'dark' ? '#ffffff' : '#131722'};` +
            'border-radius: 1rem;';
  }

  private getColorBtnBuySell(theme: string): string {
    return  `color: ${theme === 'dark' ? '#202121' : '#E3E5EB'};`;
  }

  private spreadCreateElement() {
    const spread = document.createElement('span');
    spread.innerText = this.getSpreadSymbol();
    return spread;
  }

  private getSpreadSymbol(): string {
    const symbol: Symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());
    if (!symbol || !symbol.LastQuote) {
      return '0';
    }

    let temp = CustomNumber.roundForPlace(symbol.LastQuote.Spread / symbol.PipSize, 1);

    if (temp === -0) {
      temp = 0;
    }

    return temp.toFixed(1);
  }

  private spreadShowLive() {
    if (this.spreedButton && this.spreedButton.children.length === 1) {
      this.spreedButton.removeChild(this.spreedButton.lastChild);
      this.spreedButton.append(this.spreadCreateElement());
    }
  }

  private changeColorThemeSpread(theme: string) {
    this.spreedButton.removeAttribute('style');
    this.spreedButton.setAttribute('style', this.getSpreadStyle(theme));
  }

  private changeColorBtnBuySell(element: HTMLElement, theme: string) {
    element.removeAttribute('style');
    element.setAttribute('style', this.getColorBtnBuySell(theme));
  }

  private changColorAlertPriceChangeButton(colorTheme: string) {
    if (this.appConfig.Settings.alertPriceChange !== undefined && this.appConfig.Settings.alertPriceChange) {
      if (this.alertPriceChangeButton && this.alertPriceChangeButton.children.length === 1) {
        this.alertPriceChangeButton.removeChild(this.alertPriceChangeButton.lastChild);
        this.alertPriceChangeButton.append(this.alertPriceChangeButtonCreateElement(colorTheme));
      }
    }

  }

  private alertPriceChangeButtonCreateElement(colorTheme?: string) {
    const block = document.createElement('span');
    const text = document.createElement('span');
    const icon = document.createElement('span');
    let colorButton: string;

    if (colorTheme === undefined) {
      colorButton = this.tvWidget.getTheme() === 'dark' ? '#c5d4dc' : '#131722';
    } else {
      colorButton = colorTheme === 'dark' ? '#c5d4dc' : '#131722';
    }

    const style =
      'display: flex; ' +
      'align-items: center;' +
      'justify-content: center;' +
      `color: ${colorButton};`;

    const styleIcon =
      '-webkit-mask-image: url(/assets/img/clock-alert.svg);' +
      '-webkit-mask-repeat: no-repeat;' +
      '-webkit-mask-position: center;' +
      '-webkit-mask-size: contain;' +
      `background-color: ${colorButton};` +
      'margin-right: 5px;' +
      'width: 20px; ' +
      'height: 20px; ';

    text.innerText = ChartStrings.OpenAlertChartButtonTitle;
    block.setAttribute('style', style);
    icon.setAttribute('style', styleIcon);
    block.append(icon);
    block.append(text);

    return block;
  }

  private onOffAskBidCreateElement() {
    const block = document.createElement('span');
    const type = this.PriceDirection === PriceDirection.Ask ? ChartStrings.BidOnChartButtonTitle : ChartStrings.AskOnChartButtonTitle;

    const on = document.createElement('span');
    on.innerText = `${ChartStrings.ShowOnChartButtonTitle} ${type} ${ChartStrings.LineOnChartButtonTitle}`;

    const separator = document.createElement('span');
    separator.innerText = ' / ';

    const off = document.createElement('span');
    off.innerText = `${ChartStrings.HideOnChartButtonTitle} ${type} ${ChartStrings.LineOnChartButtonTitle}`;

    if (this.showAskBidLineChart) {
      on.setAttribute('style', this.selectedStyle);
    } else {
      off.setAttribute('style', this.selectedStyle);
    }

    block.append(on);
    block.append(separator);
    block.append(off);

    return block;
  }

  // отрисовка иконки глаза включенного или нет
  private visibilityCreateElement() {
    return this.iconImgCreateElement('/assets/img/visibility.svg', );
  }

  private visibilityOffCreateElement() {
    return this.iconImgCreateElement('/assets/img/visibility_off.svg', );
  }

  private iconImgCreateElement(src: string) {
    const img = document.createElement('img');
    img.src = src;
    img.style.height = '20px';
    img.style.width = '20px';
    if (this.orderLine) {
      img.style.opacity = '.7';
    } else {
      img.style.opacity = '.3';
    }
    img.addEventListener('mousemove', () => {
      if (this.orderLine) {
        img.style.opacity = '1';
      }
    });
    img.addEventListener('mouseout', () => {
      if (this.orderLine) {
        img.style.opacity = '.7';
      }
    });

    return img;
  }

  private reset(changeLanguage = false): void {
    this.datafeed.resetData(changeLanguage);

    if (this.tvWidget) {
      this.ActiveChart.resetData();
    }
  }

  public setLanguage(v: string): void {
    this.language = this.checkingLanguageCode(v.slice(0, 2));
    this._tvWidgetChartReady = false;
    ChartStrings.update();
    this.reset(true);
    this.tvWidget.remove();
    this.configureChart();
  }

  private checkingLanguageCode(language: string): string {
    switch (language) {
      case 'ar' :
      case 'zh' :
      case 'cs' :
      case 'en' :
      case 'fr' :
      case 'de' :
      case 'el' :
      case 'it' :
      case 'ja' :
      case 'ko' :
      case 'fa' :
      case 'pl' :
      case 'pt' :
      case 'ro' :
      case 'ru' :
      case 'es' :
      case 'sv' :
      case 'th' :
      case 'tr' :
      case 'vi' :
      case 'no' :
        return language;
      case 'sk' :
      case 'sk_SK':
        return 'sk_SK';
      case 'da' :
      case 'da_DK' :
        return  'da_DK';
      case 'ca' :
      case 'ca_ES' :
        return  'ca_ES';
      case 'nl' :
      case 'nl_NL' :
        return  'nl_NL';
      case  'ms' :
      case  'ms_MY' :
        return 'ms_MY';
      case  'tw' :
      case  'zh_TW' :
        return 'zh_TW';
      case 'he' :
      case 'he_IL' :
        return 'he_IL';
      case  'hu' :
      case  'hu_HU' :
        return 'hu_HU';
      case  'id' :
      case  'id_ID' :
        return 'id_ID';
      case 'et' :
      case 'et_EE' :
        return 'et_EE';
      default :
          return 'en';
    }
  }

  public get PriceDirection(): PriceDirection {
    return this.datafeed.PriceDirection;
  }

  public set PriceDirection(v: PriceDirection) {
    if (this.PriceDirection === v) {
      return;
    }
    this.priceDirection = v;

    if (v === PriceDirection.Ask) {
      this.askButton.setAttribute('style', this.selectedStyle);
      this.bidButton.removeAttribute('style');
    } else if (v === PriceDirection.Bid) {
      this.askButton.removeAttribute('style');
      this.bidButton.setAttribute('style', this.selectedStyle);
    }

    this.datafeed.PriceDirection = v;
    this.delLineForCharts(this.linesAskBid);

    if (this.onOffAskBidButton !== undefined && this.onOffAskBidButton.children.length === 1) {
      this.onOffAskBidButton.removeChild(this.onOffAskBidButton.lastChild);
      this.onOffAskBidButton.append(this.onOffAskBidCreateElement());
    }

    this.reset();
  }

  private get ActiveChart(): IChartWidgetApi {
    if (!this.tvWidget) {
      return null;
    }

    return this.tvWidget.activeChart();
  }

  public loadTvLayout(state: object, saveColorChartState: string, saveInterval: string, saveAskBidLine: boolean, viewName: string): void {
    if (this._tvWidgetChartReady) {

      if (saveAskBidLine) {
        this.showAskBidLineChart = saveAskBidLine;
        this.showHiddenLineAskBid(true);
      }

      this.tvWidget.load(state);

      if (viewName === undefined && state['charts'][0]['timeScale']['m_barSpacing'] <= 6 ) {
        // при загрузке шаблона даже если zoom был сведен к минимуму, устанавливает его стандартный размер для чарта, чтоб не было большого запроса по истории свечей и задержки
        this.ActiveChart.getTimeScale().setBarSpacing(10);
      }

      if (saveInterval) {
        this.changeIntervalCharts(saveInterval);
      }

      const currentTheme: Theme = this.themeService.getActiveTheme();

      if ( saveColorChartState !== currentTheme.category ) {
        this.checkThemeCharts();
      }
    }
  }

  public getThemeChart() {
    return this.tvWidget.getTheme();
  }

  public getCurrentInterval() {
    return this.interval;
  }

  public getAskBidLineChart() {
    return this.showAskBidLineChart;
  }

  private checkThemeCharts() {
    const saveLocalTheme = localStorage.getItem('theme');
    if (saveLocalTheme !== null) {
      if (saveLocalTheme === 'tvZetradexDarkTheme' || saveLocalTheme === 'tvDefaultTheme' || saveLocalTheme === 'tvDarkTheme') {
        this.tvWidget.changeTheme('dark').then();
      } else if (saveLocalTheme === 'tvZetradexLightTheme' || saveLocalTheme === 'tvLightTheme') {
        this.tvWidget.changeTheme('light').then();
      }
    } else {
      this.tvWidget.changeTheme('dark').then();
    }
  }

  public async getCurrentLayoutObject(): Promise<object> {
    let resolveFunc: (value: object | PromiseLike<object>) => void;
    const layoutObjPromise = new Promise<object>(resolve => {
      resolveFunc = resolve;
    });

    if (this._tvWidgetChartReady) {
      try {
        this.tvWidget.save(state => {
          resolveFunc(state);
        });
      } catch (e) {
        console.error('Error library:', e);
      }
    }
    return layoutObjPromise;
  }

  // public getIndicatorsObject(): object {
  //   return this.tvWidget.activeChart().createStudyTemplate({saveInterval: true});
  // }

  public SetInterval(interval: string) {
    if (['1', '5', '15', '30', '60', '240', '1D', 'D', '1W', 'W', '1M', 'M'].indexOf(interval) >= 0) {
      this.ActiveChart.setResolution(interval as ResolutionString, () => {
      });
    } else {
      const interval2 = this.FixNotSupportedInterval(interval as ResolutionString);
      this.ActiveChart.setResolution(interval2 as ResolutionString, () => {
      });
    }
  }

  public GetInterval(): string {
    const interval = this.ActiveChart.resolution();

    if (['1', '5', '15', '30', '60', '240'].indexOf(interval) >= 0) {
      return interval;
    } else {
      return this.FixNotSupportedInterval(interval as ResolutionString);
    }
  }

  public saveLocalStorageSymbolNameForTrader(symbol: string): void {
    this.saveInStorageSymbolNameForTrader(symbol, this.localStorageService, 'chartSymbolNameForTrader');
    // this.saveInStorageSymbolNameForTrader(symbol, this.sessionStorageService, 'chartSymbolNameForTrader');
  }

  // метод сохраняет в локали для пользователя symbolName и interval, чтобы в следующий раз показать график с последним символом
  private saveInStorageSymbolNameForTrader(symbol: string, storage: LocalStorageService | SessionStorageService, keyStorage: string, interval?: string) {

    const saveInterval = interval || this.interval;

    const chartSymbolNameForTrader = [{traderID: this.trader.TraderId, symbol: symbol, interval: saveInterval}];

    const dataForStorage = JSON.parse(storage.getDataFromStorage(keyStorage));

    if (dataForStorage == null) {

      storage.saveDataInStorage(keyStorage, JSON.stringify(chartSymbolNameForTrader));

    } else {
      let tempArrayChart: any[] = dataForStorage;

      let check = false;

      tempArrayChart.forEach((e: any) => {
        if (e.traderID === this.trader.TraderId) {
          e.symbol = symbol;
          e.interval = saveInterval;
          check = true;
        }
      });

      if (!check) {

        const newChartSymbolNameForTrader: any = {traderID: this.trader.TraderId, symbol: symbol, interval: saveInterval};

        tempArrayChart = [...tempArrayChart, newChartSymbolNameForTrader];

        storage.saveDataInStorage(keyStorage, JSON.stringify(tempArrayChart));

      } else {

        storage.saveDataInStorage(keyStorage, JSON.stringify(tempArrayChart));

      }
    }
  }

  // метод проверяет в локали для пользователя symbolName и interval, чтобы в следующий раз показать график с последним символом
  private getSymbolNameIntervalForTrader(defaultSymbol: string, keyStorage: string, resolution?: string) {

    const dataForStorage = this.sessionStorageService.getDataFromStorage(keyStorage) || this.localStorageService.getDataFromStorage(keyStorage);

    if (dataForStorage !== null) {

      const tempArrayChart: any[] = JSON.parse(dataForStorage);
      let check = false;

      tempArrayChart.forEach((e) => {
        if (e.traderID === this.trader.TraderId) {
          this.symbolName = e.symbol;
          this.interval = e.interval !== undefined ? e.interval : this.interval;
          check = true;
        }
      });

      if (!check) {
        this.symbolName = defaultSymbol;
        this.interval = resolution || this.interval;
      }
    }
  }

  private async alertPriceChange() {
    const symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());

    if (Environment.IsMobileVersion) {
      await this.router.navigate(['/terminal/trading/multi', symbol.SymbolId]);
    } else {

      const outlets = {
        outlets: {
          modal: ['alert-price',
            {
              symbolId: symbol.SymbolId,
              prices: this.PriceDirection === PriceDirection.Ask ? symbol.LastQuote.Ask.toFixed(symbol.DecimalPlaces) : symbol.LastQuote.Bid.toFixed(symbol.DecimalPlaces)
            }]
        }
      };

      await this.router.navigate(['/terminal', outlets]);
    }
  }

  private async openCustomSearchSymbol() {
    const symbol = this.symbolStorage.findSymbolByNameSync(this.ActiveChart.symbol());
    const outlets = {
      outlets: {
        modal: ['search-symbol',
          {
            symbolId: symbol.SymbolId,
            symbolName: symbol.SymbolName,
            to: 'TradingViewDesktop'
          }]
      }
    };

    await this.router.navigate(['/terminal', outlets]);
  }

  private showHiddenLineAskBid(toggle = false) {
    this.onOffAskBidButton.removeChild(this.onOffAskBidButton.lastChild);

    if (!toggle) {
      this.showAskBidLineChart = !this.showAskBidLineChart;
    }

    this.onOffAskBidButton.append(this.onOffAskBidCreateElement());

    if (this.showAskBidLineChart) {
      this.lineForAskBidInChart();
    } else {
      this.delLineForCharts(this.linesAskBid);
    }
  }

  // метод изменение иконки глаза и отрисовки или удаления линий на графике
  private visibilityLineCharts(click?: string) {
    if (click === 'click') {
      this.visibility = !this.visibility;
    }
    this.changeVisibilityButton(this.visibility);
    if (this.visibility) {
      this.delLineForCharts(this.linesHorizontal);
    } else {
      this.orderLineForOpenOrders();
    }
  }

  private changeVisibilityButton(val: boolean) {

    if (this.visibilityButton !== undefined && this.visibilityButton.children !== undefined) {
      if (this.visibilityButton.children.length === 1) {
        this.visibilityButton.removeChild(this.visibilityButton.lastChild);
      }

      if (val) {
        this.visibilityButton.append(this.visibilityOffCreateElement());
      } else {
        this.visibilityButton.append(this.visibilityCreateElement());
      }
    }
  }

  // при отключении отображении линий на графике через секцию position иконка глаза меняется
  private checkVisibilityOrdersForCharts(array: any[] ) {
    let count = 0;
    array.forEach((e: Trade) => {
      if (e.VisibilityLine === false) {
        count ++;
      }
    });

    if (array.length === count) {
      this.changeVisibilityButton(true);
    } else {
      this.changeVisibilityButton(false);
    }
  }

  private intervalForSave(interval: string): string {
    switch (interval) {
      case '1D':
        return 'D';
      case '1W':
        return 'W';
      case '1M':
        return 'M';
    }

    return interval;
  }

  private showOrderLineChartsForMobile(): boolean {
    if (this.appConfig.Settings.orderLineCharts) {
      if (Environment.IsMobileVersion && this.appConfig.Settings.showOrderLineChartsForMobile !== undefined) {
        return this.appConfig.Settings.showOrderLineChartsForMobile;
      }
      return this.appConfig.Settings.orderLineCharts;
    }
    return false;
  }

  private showLineAskBid(): boolean {
    if (this.appConfig.Settings.showLineAskBid !== undefined) {
      return this.appConfig.Settings.showLineAskBid;
    }
    return false;
  }

  private changeColorTheme(theme: ThemeName, colorButton: string): void {
    this.tvWidget.changeTheme(theme).then();
    this.askButton.setAttribute('class', colorButton);
    this.bidButton.setAttribute('class', colorButton);
    this.visibilityButton.setAttribute('class', colorButton);
    this.buttonDropDown.setAttribute('class',  `${colorButton} ${this._dropDownButton}` );
    this.onOffAskBidButton.setAttribute('class', colorButton);
    this.alertPriceChangeButton.setAttribute('class', colorButton);
  }

  public closeChartForMobile() {
    this.datafeed.closeChartForMobile();
  }

}


// @ts-ignore
class CMI implements ContextMenuItem {
  click: EmptyCallback;
  position: 'top' | 'bottom';
  text: string;
}
