import {Answer_TicketAccept, Notification_TicketDecline} from '@common/communication/connection/classes.g';

export type TradeCallback = (message: any, dealID: number) => void;

export interface ITradePromise {
  id: number;
  success(callback: TradeCallback): ITradePromise;
  error(callback: TradeCallback): ITradePromise;
  finally(callback: () => void): ITradePromise;
}

export class TradePromise implements ITradePromise {
  private _id: number;

  public get id(): number {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  private onSuccess: TradeCallback[] = [];
  private onError: TradeCallback[] = [];
  private onFinaly: (() => void)[] = [];

/*  public constructor(id: number) {
    this._id = id;
  }*/

  public constructor() {

  }

  error(callback: TradeCallback): ITradePromise {
    this.onError.push(callback);
    return this;
  }

  success(callback: TradeCallback): ITradePromise {
    this.onSuccess.push(callback);
    return this;
  }

  finally(callback: () => void): ITradePromise {
    this.onFinaly.push(callback);
    return this;
  }

  releaseAccept(answer: Answer_TicketAccept) {
    console.log('release accept', this.onSuccess);
    console.log('length', this.onSuccess.length);
    this.onSuccess.forEach(callback => {
      // console.log('callback', callback);
      callback(answer, this.id);
    });
  }
  releaseDecline(answer: Notification_TicketDecline) {
    this.onError.forEach(callback => callback(answer, this.id));
  }
  doFinaly() {
    this.onFinaly.forEach(callback => callback());
  }
}
