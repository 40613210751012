import {Locale, LocaleEnum} from './locale';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

// @dynamic
export class Translator {
  public get CurrentLocale(): LocaleEnum {
    return this.currentLocale;
  }

  public get Locales(): Locale[] {
    return this.locales;
  }
  public set Locales(v: Locale[]) {
    this.locales = v;
  }

  public get LocaleChanger(): ISmartObserver<void> {
    return this.localeChanger;
  }
  public static get Instance(): Translator {
    if (!Translator._instance) {
      Translator._instance = new Translator();
    }

    return Translator._instance;
  }

  public static _instance: Translator;
  private currentLocale: LocaleEnum = undefined;

  private locales: Locale[] = [
    { code: LocaleEnum.English, name: 'English'},
    { code: LocaleEnum.Russian, name: 'Russian'},
    { code: LocaleEnum.Chinese, name: 'Chinese'},
    { code: LocaleEnum.Italian, name: 'Italian'},
    { code: LocaleEnum.Czech, name: 'Czech'},
    { code: LocaleEnum.CzechEvertrader, name: 'Czech'},
    { code: LocaleEnum.Slovak, name: 'Slovak'},
    { code: LocaleEnum.Thai, name: 'Thai'},
    { code: LocaleEnum.Vietnamese, name: 'Vietnamese'},
    { code: LocaleEnum.Portuguese, name: 'Portuguese'},
    { code: LocaleEnum.Spanish, name: 'Spanish'},
    { code: LocaleEnum.Swahili, name: 'Swahili'},
    { code: LocaleEnum.French, name: 'French'}
  ];

  private localeChanger: SmartEmitter<void> = new SmartEmitter();

  private langDictionary: Map<string, Map<string, string>>;

  private localDict: Map<string, string>;

  public static init(dictionary: Map<string, Map<string, string>>, locale: LocaleEnum) {
    Translator.Instance.langDictionary = dictionary;
    Translator.Instance.changeLocale(locale);
  }

  public changeLocale(locale: LocaleEnum): void {
    this.currentLocale = locale;
    this.localDict = this.getLangDictionary();

    this.localeChanger.emit();
  }

  public getLocalPhrase(key: string): string {
    return this.localDict[key];
  }

  private getLangDictionary(): Map<string, string> {
    return this.langDictionary[this.currentLocale];
  }
}
