import {Quote} from './quote';
import {JsonProperty} from '@common/shared/utils/json-decorators';


export class PriceDirection {
  private isUp: boolean;
  private isDown: boolean;
  private lastPrice: number;

  public pushNewValue(price: number){
    if(!this.lastPrice){
      this.init(price);
    }

    this.compute(price);
    this.lastPrice = price;
  }

  private init(price: number){
    this.isDown = false;
    this.isUp = false;
    this.lastPrice = price;
  }

  private compute(price: number){
    if(this.lastPrice > price){
      this.isDown = true;
      this.isUp = false;
    }

    if(this.lastPrice < price){
      this.isUp = true;
      this.isDown = false;
    }
  }

  public get Price(): number{
    return this.lastPrice;
  }

  public get IsDown(): boolean {
    return this.isDown;
  }

  public get IsUp(): boolean {
    return this.isUp;
  }

  public static empty(): PriceDirection {
    const pd = new PriceDirection();
    pd.init(0);
    return pd;
  }
}

export class LastQuoteInfo {

  @JsonProperty(PriceDirection)
  private bid = new PriceDirection();
  @JsonProperty(PriceDirection)
  private ask = new PriceDirection();

  @JsonProperty(Quote)
  private lastQuote: Quote;

  public get LastQuote(): Quote {
    return this.lastQuote;
  }

  public resetLastQuote() {
    this.lastQuote = undefined;
  }

  public get Ask(): PriceDirection {
    return this.ask;
  }

  public get Bid(): PriceDirection {
    return this.bid;
  }

  public pushNewQuote(quote: Quote): void {
    this.lastQuote = quote;
    this.ask.pushNewValue(quote.Ask);
    this.bid.pushNewValue(quote.Bid);

    this.lastQuote = quote;
  }
}
