import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Trade} from '@common/trade/models/trade';
import {OrderHistoryReport} from '@common/shared/services/Report/report.service';
import {TradeHistoryService} from '@common/shared/services/History/trade-history.service';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import { Subscription } from 'rxjs';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {LocaleModule} from '@common/locale/locale.module';
import {CurrencyModule} from '@common/currency/currency.module';
import {NgIf} from '@angular/common';

export const SortingItemsObj = {
  Order: {
    up: false,
    down: false,
  },
  OpenPrice: {
    up: false,
    down: false,
  },
  CurrentPrice: {
    up: false,
    down: false,
  },
  ClosePrice: {
    up: false,
    down: false,
  },
  Total: {
    up: false,
    down: false,
  },
};

@Component({
  selector: ' lib-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  imports: [
    LocaleModule,
    CurrencyModule,
    NgIf
  ],
  standalone: true
})

export class TableHeaderComponent implements OnInit, OnDestroy {

  @Input() appName: string;
  @Input() section: string;

  @Output() tradesArrayShow: EventEmitter<Trade[]> = new EventEmitter<Trade[]>();
  @Output() ordersArrayShow: EventEmitter<OrderHistoryReport[]> = new EventEmitter<OrderHistoryReport[]>();

  private updateArrayOrdersHistorySubscription: Subscription;
  private changeTradeSubscription: Subscription;

  private activePositionSort: string;

  private _trades: Trade[];
  private _tradesShow: Trade[] = [];

  private _orders: OrderHistoryReport[];
  private _ordersShow: OrderHistoryReport[] = [];

  @Input()
  public set trades(v: Trade[]) {
    this._trades = v;
    if (this._trades.length !== this._tradesShow.length) {
      if (this.activePositionSort) {
        this.sortArrayTrades(this.activePositionSort);
        this.tradesArrayShow.emit(this._tradesShow);
      } else {
        this._tradesShow = this._trades;
        this.tradesArrayShow.emit(this._tradesShow);
      }
    }
  }

  @Input()
  public set orders(v: OrderHistoryReport[]) {
    this._orders = v;
    if (this._orders.length !== this._ordersShow.length) {
      if (this.activePositionSort) {
        this.sortArrayOrders(this.activePositionSort).then(() => {
          this.ordersArrayShow.emit(this._ordersShow);
        });
      } else {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => new Date(a.commandTime).getTime() < new Date(b.commandTime).getTime() ? 1 : -1);
        this.ordersArrayShow.emit(this._ordersShow);
      }
    }
  }

  constructor(private tradeHistoryService: TradeHistoryService,
              private tradeStorage: TradeStorage) {
  }

  ngOnInit() {
    if (this.IsSectionHistory) {
      if (this.updateArrayOrdersHistorySubscription === undefined) {
        this.updateArrayOrdersHistorySubscription = this.tradeHistoryService.UpdateArrayOrdersHistory.subscribe((data: OrderHistoryReport[]) => {
          this._ordersShow = data;
          this.ordersArrayShow.emit(this._ordersShow);
          this.resetActiveSortIcon();
        });
      }

      if (this.changeTradeSubscription === undefined) {
        this.changeTradeSubscription = this.tradeStorage.updateTradeEvent.subscribe((trade: Trade) => {
          if (trade.IsOpen || trade.IsClosed) {
            this.tradeHistoryService.run('UpdateStoriesTrader', 100);
          }
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.IsSectionHistory) {
      this._ordersShow = [];
      this.updateArrayOrdersHistorySubscription.unsubscribe();
      this.updateArrayOrdersHistorySubscription = undefined;
      this.changeTradeSubscription.unsubscribe();
      this.changeTradeSubscription = undefined;
    }

    this.resetActiveSortIcon();
  }

  public get IsZeTradex() {
    return this.appName === 'Zetradex';
  }

  public get IsSectionHistory(): boolean {
    return this.section === 'History';
  }

  public sortingItemsPage(key: string) {
    this.changeActiveColorSortIcon(key);
    this.resetActiveSortIcon(key);
    this.activePositionSort = key;

    if (this.section === 'History') {

      this.sortArrayOrders(this.activePositionSort).then(() => {
        this.ordersArrayShow.emit(this._ordersShow);
      });

    } else {

      this.sortArrayTrades(this.activePositionSort);
      this.tradesArrayShow.emit(this._tradesShow);
    }

  }

  public getActiveSortIcon(key: string, sort: string) {
    return SortingItemsObj[key][sort];
  }

  private changeActiveColorSortIcon(key: string) {
    if (!SortingItemsObj[key].up && !SortingItemsObj[key].down) {
      SortingItemsObj[key].up = true;
      return;
    }
    if (SortingItemsObj[key].up) {
      SortingItemsObj[key].down = true;
      SortingItemsObj[key].up = false;
      return;
    }
    if (SortingItemsObj[key].down) {
      SortingItemsObj[key].up = true;
      SortingItemsObj[key].down = false;
      return;
    }
  }

  private resetActiveSortIcon(items: string = ''): void {
    Object.keys(SortingItemsObj).forEach((key: string) => {
      if (items !== key) {
        SortingItemsObj[key].up = false;
        SortingItemsObj[key].down = false;
      }
    });
  }

  private sortArrayTrades(key: string): void {
    if (key === 'Order') {
      if (SortingItemsObj[key].up) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.OpenTime.getTime() < b.OpenTime.getTime() ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.OpenTime.getTime() > b.OpenTime.getTime() ? 1 : -1);
      }
    }

    if (key === 'OpenPrice') {
      if (SortingItemsObj[key].up) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.OpenPrice < b.OpenPrice ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.OpenPrice > b.OpenPrice ? 1 : -1);
      }
    }

    if (key === 'CurrentPrice') {
      if (SortingItemsObj[key].up) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.CurrentPrice < b.CurrentPrice ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.CurrentPrice > b.CurrentPrice ? 1 : -1);
      }
    }

    if (key === 'Total') {
      if (SortingItemsObj[key].up) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.Total < b.Total ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._tradesShow = this._trades.sort( (a: Trade, b: Trade) => a.Total > b.Total ? 1 : -1);
      }
    }
  }

  private async sortArrayOrders(key: string): Promise<void> {
    if (key === 'Order') {
      if (SortingItemsObj[key].up) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => new Date(a.commandTime).getTime() < new Date(b.commandTime).getTime() ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => new Date(a.commandTime).getTime() > new Date(b.commandTime).getTime() ? 1 : -1);
      }
    }

    if (key === 'OpenPrice') {
      if (SortingItemsObj[key].up) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => a.openPrice < b.openPrice ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => a.openPrice > b.openPrice ? 1 : -1);
      }
    }

    if (key === 'ClosePrice') {
      if (SortingItemsObj[key].up) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => a.commandPrice < b.commandPrice ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => a.commandPrice > b.commandPrice ? 1 : -1);
      }
    }

    if (key === 'Total') {
      if (SortingItemsObj[key].up) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => this.getTotalOrdersHistory(a) < this.getTotalOrdersHistory(b) ? 1 : -1);
      }

      if (SortingItemsObj[key].down) {
        this._ordersShow = this._orders.sort( (a: OrderHistoryReport, b: OrderHistoryReport) => this.getTotalOrdersHistory(a) > this.getTotalOrdersHistory(b) ? 1 : -1);
      }
    }

  }

  private  getTotalOrdersHistory(order: OrderHistoryReport): number {
    const expenses = Math.abs(order.transactionFee) - order.swaps;
    return order.profit - expenses;
  }

}
