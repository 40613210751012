<div class="connection-internet-status-container" [class.connection-internet-status-container-mobile]="IsMobile" [hidden]="IsConnectedInternet">
  <div *ngIf="noInternetConnection" class="connection-internet-status-box">
    <span class="internet-icon internet-off" ></span>
    <span style="font-size: 10px; line-height: 12px; font-weight: bold;" ><l-unit [upperCase]="true" [key]="'NotificationProvider_ConnectionNotification_ThereIsNoInternetConnection'"></l-unit></span>
  </div>
  <div *ngIf="!noInternetConnection" class="connection-internet-status-box">
    <span class="internet-icon internet-on" ></span>
    <span style="font-size: 10px; line-height: 12px; font-weight: bold;"><l-unit [upperCase]="true" [key]="'NotificationProvider_ConnectionNotification_InternetConnectionIsThere'"></l-unit></span>
  </div>
</div>

<!--этот блок нужен чтоб прогрузились ссылки на иконы статуса интернета-->
<div style="opacity: 0; position: absolute; bottom: 0; right: 0">
  <span class="internet-icon internet-on" ></span>
  <span class="internet-icon internet-off" ></span>
</div>
