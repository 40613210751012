import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OrderHistoryReport} from '@common/shared/services/Report/report.service';
import {StateType} from '@common/trade/models/trade-state-enum';
import {SettingsService} from '@common/trader/services/settings.service';
import {Settings} from '@common/trader/models/settings';
import {TradeHistoryService} from '@common/shared/services/History/trade-history.service';
import {AppConfig} from '@common/configuration/app-config';
import {Trade} from '@common/trade/models/trade';
import {LayoutService} from '@desktop-core/terminal/services/layout.service';
import {TradeModule} from '@common/trade/trade.module';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {LocaleModule} from '@common/locale/locale.module';
import {NgForOf, NgIf} from '@angular/common';
import {TableHeaderComponent} from '@desktop-core/trade/components/content-tabs/table-header/table-header.component';

@Component({
  selector: 'lib-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss'],
  imports: [
    TradeModule,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    LocaleModule,
    NgIf,
    TradeModule,
    CdkFixedSizeVirtualScroll,
    NgForOf,
    TableHeaderComponent
  ],
  standalone: true
})
export class HistoryPageComponent implements OnInit, OnDestroy {

  @Input() enableShowMoreInformation: boolean;
  protected isOpened = false;
  private indexOpenMoreInformation: number;
  public showOrderArray: OrderHistoryReport[] = [];

  private ordersSortingShow: OrderHistoryReport[] = [];

  public appName = '';

  private indexToLoad = 10;

  constructor(private settingsService: SettingsService,
              private tradeHistoryService: TradeHistoryService,
              private appConfig: AppConfig,
              private layoutService: LayoutService) {

    this.appName = this.appConfig.Settings.common.AppName;

  }

  ngOnInit() {
    this.tradeHistoryService.run('WebTrader', 100, true);
    this.ordersSortingShow = this.OrderHistory;
    this.checkElementHeight();
  }

  ngOnDestroy() {
    this.tradeHistoryService.destroyArray();
  }

  get OrderHistory(): OrderHistoryReport[] {
    return this.tradeHistoryService.OrdersHistory;
  }

  get OrdersSortingShow(): OrderHistoryReport[] {
    return this.ordersSortingShow;
  }

  getSymbolName(symbolID: number): string {
    return this.tradeHistoryService.getSymbolName(symbolID);
  }

  getOrderType(tradeType: number): string {
    return this.tradeHistoryService.getOrderType(tradeType);
  }

  public OpenTime(date): Date {
    return this.tradeHistoryService.OpenTime(date);
  }

  public BaseCurrency(symbolName: string): string {
    return this.tradeHistoryService.BaseCurrency(symbolName);
  }

  public showVolume(v: number, symbolID: number) {
    return this.tradeHistoryService.showVolume(v, symbolID);
  }

  public ShowValue(value: number): boolean {
    return this.tradeHistoryService.ShowValue(value);
  }

  public ShowValueMargin(value: number): boolean {
    return this.tradeHistoryService.ShowValueMargin(value);
  }

  orderTypeIsBuy(tradeType: number): boolean {
    return this.tradeHistoryService.orderTypeIsBuy(tradeType);
  }

  public checkingPipsResult(profit: number, openPrice: number, closePrice: number, symbolID: number) {
    return this.tradeHistoryService.checkingPipsResult(profit, openPrice, closePrice, symbolID);
  }

  public getTotal(profit: number, order: OrderHistoryReport) {
    return this.tradeHistoryService.getTotal(profit, order);
  }

  public ShowValueForIsOpen(value: string): boolean {
    return this.tradeHistoryService.ShowValueForIsOpen(value);
  }

  public getCommandSign(volume: number) {
    return this.tradeHistoryService.getCommandSign(volume);
  }

  public getStateName(type: StateType): string {
    return this.tradeHistoryService.getStateName(type);
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public toggle(index: number) {
    this.showOrderArray = [];
    if (index === this.indexOpenMoreInformation) {
      this.indexOpenMoreInformation = undefined;
    } else {
      this.indexOpenMoreInformation = index;
    }
  }

  private IsOpenedIcon(index: number) {
    if (this.indexOpenMoreInformation === index) {
      return '-';
    } else {
      return '+';
    }
  }

  public IsOpened(index: number, order: OrderHistoryReport): boolean {
    if (this.indexOpenMoreInformation === index) {
      if (this.showOrderArray.length === 0) {
        this.pairOfOrders(order);
      }
      return true;
    }
  }

  // при открытии подробной информации об ордере в OrdersForOpen ищется с таким же orderID и создается массив из двух ордеров
  public pairOfOrders(order: OrderHistoryReport) {
    this.tradeHistoryService.OrdersForOpen.forEach((e: OrderHistoryReport) => {
      if (e.orderID === order.orderID) {
        this.showOrderArray.push(e);
      }
    });

    this.showOrderArray.sort((a, b) => Date.parse(a.commandTime) > Date.parse(b.commandTime) ? 1 : -1);

    console.log('this.showOrderArray', this.showOrderArray);

  }

  public get BufferSize(): number {
    return this.PositionsOnlyMode ? 75 : 150;
  }

  public get PositionsOnlyMode(): boolean {
    return this.layoutService.ShowPositions === true && this.layoutService.ShowChart === false;
  }

  public trackById(index: any, item: Trade) {
    return item.TradeId;
  }

  public isZeTradex() {
    return this.appConfig.Settings.common.AppName === 'Zetradex';

  }

  public historyOrdersShowEvent(orders: OrderHistoryReport[]) {
    this.ordersSortingShow = [...orders];
  }

  nextBatch(index: number) {
    if (index >= this.indexToLoad) {
      this.loadReportPage().then();
      this.indexToLoad += 25;
    }
  }

  async loadReportPage() {
    this.tradeHistoryService.run('WebTrader', 100);
  }

  checkElementHeight() {
    const el = document.documentElement.querySelector('.content-tabs-contents');
    const vh = el.clientHeight * 0.01;
    el.setAttribute('style', `--vh: ${vh};`);
  }

  getSymbolRowHeightPX() {
    const vh = parseFloat(getComputedStyle(document.documentElement.querySelector('.content-tabs-contents')).getPropertyValue('--vh'));
    return Math.round(vh * 6.8 + vh * 0.1);
  }

}
