<div theme style="height: 100%; width: 100%">

  <router-outlet name="test-panel" *ngIf="IsTesting"></router-outlet>
  <router-outlet></router-outlet>

  <div class="connection-status-container" [hidden]="IsConnected">
    <img ngSrc="./assets/img/spinner.svg" width="32" height="32" alt="spinner">
    <span class="reconnecting-text">Reconnecting...</span>
  </div>

  <app-internet-connection></app-internet-connection>

</div>


