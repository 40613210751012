import {Injectable} from '@angular/core';
import {SymbolCommandSender} from '../../communication/command-sender/symbol-command-sender';
import {Symbol} from '../models/symbol';
import {Answer_GetItems} from '@common/communication/connection/classes.g';
import {SymbolParser} from '@common/symbol/utils/symbol-parser';
import {AppConfig} from '@common/configuration/app-config';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';

@Injectable({
  providedIn: 'root'
})
export class SymbolService {

  private readonly _logger = LoggerFactory.getLogger('SymbolService');

  private _internalSymbols: Symbol[] = [];

  constructor(private symbolCommandSender: SymbolCommandSender,
              private appConfig: AppConfig,
              private symbolsRepository: SymbolStorageService) { }

  public unsubscribeFromSymbol(symbol: Symbol): Promise<any> {
    return this.symbolCommandSender.unsubscribeFromSymbol(symbol).toNativePromise();
  }
  public async unsubscribeFromSymbols(symbol: Symbol[]): Promise<void> {
    const promises = this.symbolCommandSender.unsubscribeFromSymbols(symbol);

    const result = promises.map(item => item.toNativePromise());

    await Promise.all(result);
  }

  public unsubscribeFromSymbolHistory(symbol: Symbol): Promise<any> {
    return this.symbolCommandSender.unsubscribeFromSymbolHistory(symbol).toNativePromise();
  }
  public async unsubscribeFromSymbolsHistories(symbol: Symbol[]): Promise<void> {
    const promises = this.symbolCommandSender.unsubscribeFromSymbolsHistories(symbol);

    const result = promises.map(item => item.toNativePromise());

    await Promise.all(result);
  }


  public subscribeToSymbol(symbolId: number): Promise<any> {
    const result = this.symbolCommandSender.subscribeToSymbolQuotes(symbolId);

    return result.toNativePromise();
  }
  public subscribeToFullMarketDepth(symbolId: number): Promise<any> {
    const result = this.symbolCommandSender.subscribeToFullMarketDepth(symbolId);

    return result.toNativePromise();
  }

  public unsubscribeFullMarketDepth(symbolId: number): Promise<any> {
    const result = this.symbolCommandSender.unsubscribeFullMarketDepth(symbolId);

    return result.toNativePromise();
  }

  public async subscribeToSymbols(symbolIds: number[]): Promise<void> {
    const promises = symbolIds.map(item => this.subscribeToSymbol(item));

    await Promise.all(promises);
  }

  public async loadSymbols(): Promise<Symbol[]> {
    const answer = await this.symbolCommandSender.loadSymbols().toTypedNativePromise<Answer_GetItems>();

    const result = new SymbolParser(this.symbolsRepository).parseSymbols(answer);

    this._internalSymbols = new SymbolParser(this.symbolsRepository).parseInternalSymbols(answer);

    if (this.appConfig.Settings.connection.SymbolSubscriptionOnDemand) {
      for (const symbol of result) {

        symbol.SubscribeRequest.subscribe(async () => {
          this._logger.debug('Subscribe requested for SymbolID: ' + symbol.SymbolId);

          try {
            await this.symbolCommandSender.subscribeToSymbolQuotes(symbol.SymbolId);
          } catch (ex) {
            this._logger.error('Could not subscribe to symbol. Symbol ID: ' + symbol.SymbolId);
            symbol.SubscribeEmitted = false;
            return;
          }

          symbol.IsSubscribed = true;
        });
      }
    } else {
      for (const symbol of result) {
        symbol.IsSubscribed = true;
        symbol.SubscribeEmitted = true;
      }
    }

    return result;
  }

  get InternalSymbols(): Symbol[] {
    return this._internalSymbols;
  }

  // проверка на подписку изменения цен, если её нет, то подписывается
  public checkSubscribePrice(v: Symbol) {
    if (v.LastQuote === undefined) {
      this.subscribeToSymbol(v.SymbolId).then();
    }

    // проверка на подписку для конвертирующей валюты
    if (!v.ProfitCalcSymbol.getCCConvertSymbol().getIsValidQuote()) {
      const convertSymbol = v.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'];
      // console.log( `Subscription for converting currency ${convertSymbol._symbolMetadata['_symbolName']}`);
      // подписка на конвертирующую валюту
      this.subscribeToSymbol(convertSymbol._symbolId).then();
    }
  }
}
