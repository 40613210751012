/**
 * QuoteTick is a wrapper class around Notification_ItemTick
 * which contains raw values (e.g. prices without decimals)
 * and should be converted to [Quote]
 */
export class QuoteTick {
  get Bid(): number {
    return this._bid;
  }

  get Ask(): number {
    return this._ask;
  }

  get SymbolId(): number {
    return this._symbolId;
  }

  get ServerTime(): Date {
    return this._serverTime;
  }

  get AskMarketDepth(): number {
    return this._askMarketDepth;
  }

  get BidMarketDepth(): number {
    return this._bidMarketDepth;
  }

  get PriceToPointMul(): number {
    return this._priceToPointMul;
  }

  get ConversionToUSD(): number {
    return this._conversionToUSD;
  }

  private readonly _bid: number;
  private readonly _ask: number;
  private readonly _symbolId: number;
  private readonly _serverTime: Date;
  private readonly _askMarketDepth: number;
  private readonly _bidMarketDepth: number;
  private readonly _priceToPointMul: number;
  private readonly _conversionToUSD: number;

  constructor(symbolId: number,
              bid: number,
              ask: number,
              bidMarketDepth: number,
              askMarketDepth: number,
              priceToPointMul: number,
              conversionToUSD: number,
              serverTime: Date) {
    this._symbolId = symbolId;
    this._bid = bid;
    this._ask = ask;
    this._bidMarketDepth = bidMarketDepth;
    this._askMarketDepth = askMarketDepth;
    this._priceToPointMul = priceToPointMul;
    this._serverTime = serverTime;
    this._conversionToUSD = conversionToUSD;
  }
}
