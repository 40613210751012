import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Trader} from '@common/trader/models/trader';
import {TradeType} from '@common/trade/models/trade-type';
import {AuthenticationService} from '@common/auth/services/authentication.service';
import {AppConfig} from '@common/configuration/app-config';
import {SaveViewService} from '@common/shared/services/save-view.service';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {Notification} from '@common/notification/models/notification';
import {Settings} from '@common/trader/models/settings';
import {ThemeService} from '@common/shared/services/theme';
import {CheckingLifeOfSessionService} from '@common/shared/services/checking-life-of-session.service';
import {OperationsWithUrl} from '@common/trade/utils/operations-with-url';
import {AlertChangesPriceService} from '@common/shared/services/alert-changes-price.service';
import {TradeService} from '@common/trade/services/trade.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import { Symbol } from '@common/symbol/models/symbol';
import {UpdatingDataByTime} from '@common/shared/services/updating-data-by-time';
import {TradeHistoryService} from '@common/shared/services/History/trade-history.service';
import {TransactionsService} from '@common/shared/services/Transactions/transactions-service';
import {Accounting} from '@common/trader/models/accounting.service';
import {TerminalService} from '@common/trade/services/terminal.service';
import {ReportService} from '@common/shared/services/Report/report.service';

@Component({
  selector: 'terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
  providers: [SaveViewService]
})
export class TerminalComponent implements OnInit, OnDestroy {

  private newMessage: Notification[] = [];
  private hideMessage = false;
  private textBodyChallenge = [];
  private isShowWindowModeDebugUsers = false;
  private readonly _showButtonTheme: boolean ;
  private _showButtonModeDebugUsers = false;
  private _showButtonBOAnalytics = false;
  public appName: string;
  private readonly hiddenIconTopMenu: boolean;
  private readonly _showChangePassword: boolean;
  private readonly _topUpYourDeposit: string;
  private readonly _showTradeGroupName: boolean;

  constructor(private router: Router,
              private auth: AuthenticationService,
              private localStorage: LocalStorageService,
              private trader: Trader,
              private appConfig: AppConfig,
              private saveViewService: SaveViewService,
              private translatorService: TranslatorService,
              protected settings: Settings,
              private themeService: ThemeService,
              private checkingSleepModeService: CheckingLifeOfSessionService,
              private alertChangesPriceService: AlertChangesPriceService,
              private tradeService: TradeService,
              private symbolStorage: SymbolStorageService,
              private updatingDataByTime: UpdatingDataByTime,
              private tradeHistoryService: TradeHistoryService,
              private transactionsService: TransactionsService,
              public accounting: Accounting,
              private reportService: ReportService,
              private terminalService: TerminalService) {

    this.terminalService.initServices(this.trader.TraderName, this.checkingSleepModeService, this.tradeHistoryService, this.transactionsService);
    this.terminalService.checkNewVersionApp();
    this.terminalService.shouldChangePassword(this.trader);
    this._showButtonBOAnalytics = this.trader.checkUsersWhoHaveAccess('showButtonBOAnalytics', 'listOfUsersWhoHaveAccessBOAnalytics');
    this._showButtonModeDebugUsers = this.trader.checkUsersWhoHaveAccess('modeDebugUsers', 'listUsersAccessModeDebug');
    sessionStorage.setItem('ModeDebugUsers', JSON.stringify(this._showButtonModeDebugUsers));
    this.appName = this.terminalService.getAppName();
    this.hiddenIconTopMenu = this.terminalService.isHiddenIconTopMenu();
    this._showButtonTheme = this.terminalService.isShowButtonTheme();
    this._showChangePassword = this.terminalService.isShowChangePassword();
    this._showTradeGroupName = this.terminalService.isShowTradeGroupName();
    this._topUpYourDeposit = this.terminalService.getUrlTopUpYourDeposit();
    this.terminalService.initReRequestBalance(this.accounting);
    this.terminalService.initUserInactivityTime(this.auth);
  }

  public get IsShowChangePassword(): boolean {
    return this._showChangePassword;
  }

  get IsReadOnlyAccount(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  get TraderName(): string {
    return this.trader.TraderName;
  }

  get TradeGroupName(): string {
    return this.trader.TradeGroupName;
  }

  get ShowTradeGroupName(): boolean {
    return this._showTradeGroupName;
  }

  get IsViewsMenuAvailable(): boolean {
    return this.appConfig.Settings.platformLayout.IsViewsMenuAvailable;
  }

  get ShowButtonModeDebugUsers(): boolean {
    return this._showButtonModeDebugUsers;
  }

  get ShowButtonBOAnalytics(): boolean {
    return this._showButtonBOAnalytics;
  }

  get IsShowWindowModeDebugUsers(): boolean {
    return this.isShowWindowModeDebugUsers;
  }

  public ShowWindowModeDebugUsers() {
    this.isShowWindowModeDebugUsers = !this.isShowWindowModeDebugUsers;
  }

  public  get IsHiddenIconTopMenu(): boolean {
    return !this.hiddenIconTopMenu;
  }

  openBuyModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.Buy, {volume: this.getVolumeForOpenOrders(TradeType.Buy)}]}}]).then() ;
  }

  openSellModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.Sell, {volume: this.getVolumeForOpenOrders(TradeType.Sell)}]}}]).then() ;
  }

  openSettingsModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['settings']}}]).then();
  }

  openPendingOrderModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.BuyLimit, {volume: this.getVolumeForOpenOrders(TradeType.BuyLimit)}]}}]).then() ;
  }

  private getVolumeForOpenOrders(type: TradeType) {
    const symbol = this.getSymbolForTrader();
    const price = symbol.Bid;
    return this.tradeService.getVolumeForOpenOrders(symbol, price, type );
  }

  private getSymbolForTrader() {
    const tempArrayChart: any[] = JSON.parse(localStorage.getItem('chartSymbolNameForTrader'));
    let check = false;
    let symbol: Symbol;

    if (tempArrayChart !== null) {
      tempArrayChart.forEach((e) => {
        if (e.traderID === this.trader.TraderId) {
          symbol = this.symbolStorage.findSymbolByNameSync(e.symbol);
          check = true;
        }
      });

      if (!check) {
        symbol = this.symbolStorage.getFirstSymbol();
      }

    } else {
      symbol = this.symbolStorage.getFirstSymbol();
    }

    return symbol;
  }

  openBackoffice($event: MouseEvent, key: string) {
    $event.stopImmediatePropagation();
    const sessionID = this.localStorage.getSessionKey();
    OperationsWithUrl.goToBackoffice(this.appConfig.Settings, this.trader, this.translatorService.CurrentLocale, sessionID, key);
  }

  topUpYourDeposit() {
    if (this._topUpYourDeposit) {
      const sessionID = this.localStorage.getSessionKey();
      let url = this._topUpYourDeposit;
      if (!this.IsZeTradex) {
        url = url + `?SessionID=${sessionID}`;
      }
      OperationsWithUrl.openWindow(url);
    }
  }

  get IsUrlPathDeposit(): boolean {
    return this._topUpYourDeposit && !this.IsReadOnlyAccount ;
  }

  async signOut(event: MouseEvent) {
    event.stopPropagation();
    this.localStorage.clearTemplatesToDisplay();
    this.reportService.clearReports();
    await this.terminalService.signOut();
  }

  async changePassword(event: MouseEvent) {
    event.stopPropagation();
    await this.router.navigate(['/terminal', {outlets: {modal: ['change-password']}}]);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.auth.sendLogout().then();
  }

  ngOnInit() {
    if (this.appConfig.Settings.alertPriceChange !== undefined && this.appConfig.Settings.alertPriceChange ) {
      this.alertChangesPriceService.init(true);
    }

    this.checkingSleepModeService.run();
    this.tradeHistoryService.run('WebTrader', 100);
    this.transactionsService.run('WebTrader', 100);

    this.updatingDataByTime.init();
    localStorage.removeItem('ArrayConnectionUrlsBalancedLengths');
    this.terminalService.initCount();
  }

  ngOnDestroy(): void {
    this.terminalService.closeWindow();
    this.saveViewService.destroy();
  }

  // отображение данных сообщений
  public get Header(): string {
    return this.newMessage['header'];
  }

  public get Date(): Date {
    return this.newMessage['time'];
  }

  public get Body(): any {
    return this.textBodyChallenge;
  }

  // закрыть модальное окно

  public onCloseMessageClick() {
    this.textBodyChallenge = [];
    this.hideMessage = false;
  }

  // метод для проверки показа сообщения

  public showMessage(): boolean {
    if (sessionStorage.getItem('MessageChallenge') !== null) {
      const messageChallenge = JSON.parse(sessionStorage.getItem('MessageChallenge'));
      this.showMessageChallenge(messageChallenge);
      sessionStorage.removeItem('MessageChallenge');
    }
    return this.hideMessage;
  }

  public showMessageChallenge(message) {

    const textBody = message.body.split('Your');
    textBody.splice(0, 1);
    textBody.forEach((e) => {
      this.textBodyChallenge.push('Your' + e);
    });

    this.newMessage = message;
    this.hideMessage = true;
  }

  public stopPropagationForMessageChallenge(event: MouseEvent): void {
    event.stopPropagation();
  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    const pageState = {
      traderName: this.trader.TraderName,
      pageReload: true
    };
    sessionStorage.setItem('pageReloadEvent', JSON.stringify(pageState));
    // $event.returnValue = 'Your data will be lost!';
    this.auth.logout().then();
  }

  // theme
  get ShowButtonTheme() {
    return this._showButtonTheme;
  }

  public changeThemeOrShowOptions() {
    if (this.IsZeTradex) {
      const active = this.themeService.getActiveTheme();
      if (active.name === 'tvZetradexDarkTheme') {
        this.changeTheme('tvZetradexLightTheme');
      } else if (active.name === 'tvZetradexLightTheme') {
        this.changeTheme('tvZetradexDarkTheme');
      }
    }
  }

  public changeTheme (theme: string) {
    this.themeService.setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  get ShowNotificationCheckConnection() {
    return this.checkingSleepModeService.ShowNotificationCheckConnection;
  }

  public  getLogoCurrentThemeColor() {
    if ( this.themeService.getActiveTheme().category === 'dark' ) {
      return 'logo-dark-theme';
    } else {
      return 'logo-light-theme';
    }
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  get IsFXLiveMarketsTrader(): boolean {
    return this.appName === 'FXLiveMarkets Trader' ;
  }

}
