import {Trade} from '../models/trade';
import {TradeDTO} from '../models/trade-d-t-o';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {Answer_GetOpenOrderStates} from '../../communication/connection/classes.g';
import {EventEmitter, Injectable} from '@angular/core';
import {TradeValidator} from '../utils/trade.validator';
import {TradeCommandSender} from '../../communication/command-sender/trade-command-sender';
import {isPendingTrade, TradeType} from '../models/trade-type';
import {ITradePromise, TradePromise} from '@common/trade/models/trade-promise';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Trader} from '@common/trader/models/trader';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {Accounting} from '../../trader/models/accounting.service';
import {TradeParser} from '@common/trade/utils/trade.parser';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {NettedTrade} from '@common/trade/models/netted-trade';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {SettingsService} from '@common/trader/services/settings.service';
import {Symbol} from '@common/symbol/models/symbol';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {AppConfig} from '@common/configuration/app-config';
import {CustomValidationError} from '@common/trade/models/exceptions/custom-validation.error';
import {translate} from '@common/locale/servises/translator.service';

export type returnType = Promise<ITradePromise> | string;

@Injectable({
  providedIn: 'root'
})
export class TradeService {
  private logger = LoggerFactory.getLogger('TradeService');

  private closeAllTradersEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  private closeTradeEvent: EventEmitter<void> = new EventEmitter<void>();
  private tempPendingOrderId = 0;

  private responseHasBeenReceived = false;

  constructor(private tradeCommandSender: TradeCommandSender,
              private tradeStorage: TradeStorage,
              private trader: Trader,
              private notificationProvider: NotificationProviderService,
              private accounting: Accounting,
              private symbolStorage: SymbolStorageService,
              private settingsService: SettingsService,
              private config: AppConfig) {

  }

  public async loadAll(): Promise<Trade[]> {
    const answer = await this.tradeCommandSender.loadAll().toTypedNativePromise<Answer_GetOpenOrderStates>();
    console.log(answer);
    return new TradeParser(this.symbolStorage).parseTrades(answer.OpenOrderStates);
  }


  public async openOrder(order: TradeDTO): Promise<ITradePromise> {
    return await this.openOrderWithValidateError(order, null);
  }
/*
* const result = new Promise<ITradePromise>((resolve, reject) => {

      this.sendCommand(command)
        .success(answer =>
          resolve(this.saveDeal(answer.DealID))
        )
        .failed(message =>
          reject(message)
        );
    });

    return result;
* */

  public async openOrderWithValidateErrorAndTradePromise(order: TradeDTO, tradePromise: TradePromise): Promise<TradePromise> {
    console.log('openOrderWithValidateErrorAndTradePromise');
    try {
      this.validateAction(order);
    } catch (ex) {
      if (isPendingTrade(order.Type)) {
        this.notificationProvider.onTradePendingCreateError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate);
      } else {
        this.notificationProvider.onTradeOpenError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate, (ex as CustomValidationError).additionalDataObj);
      }

      const errPromise = new Promise<TradePromise>((success, error) => {
        error((ex as Error).message);
      });

      return errPromise;
      // return Promise.reject((ex as Error).message);
    }
    tradePromise.error(msg  => {
      if (isPendingTrade(order.Type)) {
        this.notificationProvider.onTradePendingCreateError(msg.Message);
      } else {
        this.notificationProvider.onTradeOpenError(msg.Message);
      }
    });
    tradePromise.success(() => {
        if (isPendingTrade(order.Type)) {
          this.notificationProvider.onTradeOpen(order);
        } else {
          this.notificationProvider.onTradeOpen(order);
        }
      });

    const answer = await this.tradeCommandSender.openTradeWithTradePromise(order, tradePromise);

    return answer;

  }

  public async openOrderWithValidateError2(order: TradeDTO): Promise<ITradePromise> {
    console.log('openOrderWithValidateError2');
    try {
      this.validateAction(order);
    } catch (ex) {
      if (isPendingTrade(order.Type)) {
        this.notificationProvider.onTradePendingCreateError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate);
      } else {
        this.notificationProvider.onTradeOpenError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate, (ex as CustomValidationError).additionalDataObj);
      }

      const errPromise = new Promise<ITradePromise>((success, error) => {
        error((ex as Error).message);
      });

      return errPromise;
      // return Promise.reject((ex as Error).message);
    }
    console.log('Order opening');
    this.logger.debug('Order opening');
    const answer = await this.tradeCommandSender.openTrade(order);

    console.log('answer is ready');
    answer
      .error(msg  => {
        console.log('msg', msg);
        this.responseHasBeenReceived = true;
        if (isPendingTrade(order.Type)) {
          this.notificationProvider.onTradePendingCreateError(msg.Message);
        } else {
          this.notificationProvider.onTradeOpenError(msg.Message);
        }
      })
      .success(() => {
        this.responseHasBeenReceived = true;
        if (isPendingTrade(order.Type)) {
          this.notificationProvider.onTradeOpen(order);
        } else {
          this.notificationProvider.onTradeOpen(order);
        }
      });


    this.useAlternativeNotificationOpenOrder(order, 'open');

    return answer;
  }

  public async openOrderWithValidateError(order: TradeDTO, errorCallback?: (msg: string) => void): Promise<ITradePromise> {
    try {
      this.validateAction(order);
    } catch (ex) {
      if (isPendingTrade(order.Type)) {
        this.notificationProvider.onTradePendingCreateError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate);
      } else {
        this.notificationProvider.onTradeOpenError((ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate, (ex as CustomValidationError).additionalDataObj);
      }

      if (errorCallback) {
        errorCallback((ex as Error).message);
      }

      return;
    }

    this.logger.debug('Order opening');
    const answer = await this.tradeCommandSender.openTrade(order);

    answer
      .error(msg  => {
        console.log('msg', msg);
        this.responseHasBeenReceived = true;
        if (isPendingTrade(order.Type)) {
          this.notificationProvider.onTradePendingCreateError(msg.Message);
        } else {
          this.notificationProvider.onTradeOpenError(msg.Message);
        }
      })
      .success(() => {
        this.responseHasBeenReceived = true;
        if (isPendingTrade(order.Type)) {
          this.notificationProvider.onTradeOpen(order);
        } else {
          console.log('np on trade open');
          this.notificationProvider.onTradeOpen(order);
        }
      });

    this.useAlternativeNotificationOpenOrder(order, 'open');

    return answer;
  }

  private validateAction(order: TradeDTO) {
    this.checkTrader();
    this.checkTrade(order);
  }

  private checkTrade(order: TradeDTO) {
    new TradeValidator()
      .checkPrice(order)
      .checkBounds(order)
      .checkVolume(order)
      .checkMargin(order, this.accounting);
  }

  private checkTrader() {
    if (this.trader.IsReadOnlyAccount) {
      throw new CustomValidationError(translate(`NotificationProvider_TradeValidator_YouHaveReadOnlyAccount`), 'YouHaveReadOnlyAccount');
    }
  }

  public async closeMarketOrder(trade: Trade): Promise<ITradePromise> {
        const temp = new TradeDTO();
        temp.readFromTrade(trade);
        return await this.closeTrade(temp);
  }

  public async deletePendingOrder(trade: Trade): Promise<ITradePromise> {
    if (!isPendingTrade(trade.Type)) {
      throw new CustomValidationError(translate(`NotificationProvider_TradeValidator_IsNotPendingOrder`), 'IsNotPendingOrder');
    }

    const temp = new TradeDTO();
    temp.readFromTrade(trade);
    return await this.closeTrade(temp);
  }

  public async closeTrade(trade: TradeDTO): Promise<ITradePromise> {
    if (trade.IsClosed) {
      throw new CustomValidationError(translate(`NotificationProvider_TradeValidator_TradeAlreadyClosed`), 'TradeAlreadyClosed');
    }

    if (isPendingTrade(trade.Type)) {
      return  await this.deletePending(trade, 'Closing of one pending from the method closeTrade');
    } else {
      return await this.closeSimpleTrade(trade);
    }
  }

  public async closeTradeWithTradePromise(trade: TradeDTO, tradePromise: TradePromise): Promise<ITradePromise> {
    if (trade.IsClosed) {
      throw new CustomValidationError(translate(`NotificationProvider_TradeValidator_TradeAlreadyClosed`), 'TradeAlreadyClosed');
    }

    if (isPendingTrade(trade.Type)) {
      return  await this.deletePending(trade, 'Closing of one pending from the method closeTrade');
    } else {
      return await this.closeSimpleTradeWithTradePromise(trade, tradePromise);
    }
  }

  private async deletePending(trade: TradeDTO, comment: string): Promise<ITradePromise> {
    if (this.tempPendingOrderId !== trade.TradeId) {
      this.tempPendingOrderId = trade.TradeId;

      this.useAlternativeNotificationOpenOrder(trade, 'deletePending');

      console.log('trade', 23, trade);

      return (await this.tradeCommandSender.deletePendingOrder(trade, comment))
        .error(msg => {
          console.log('error', 23, msg);
          this.responseHasBeenReceived = true;
          this.notificationProvider.onTradePendingDeleteError(trade, msg.Message);
          this.tempPendingOrderId = 0;
        })
        .success(() => {
          console.log('success', 23);
          this.responseHasBeenReceived = true;
          this.notificationProvider.onTradePendingDelete(trade);
          this.tempPendingOrderId = 0;
        });
    }
  }

  private async closeSimpleTrade(trade: TradeDTO): Promise<ITradePromise> {
    this.useAlternativeNotificationOpenOrder(trade, 'close');

    console.log('trade', 23, trade);

    return (await this.tradeCommandSender.closeTrade(trade))
      .error(msg => {
        console.log('error', 23, msg);
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradeCloseError(trade, msg.Message, 'CP');
      })
      .success(() => {
        console.log('success', 23);
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradeClose(trade);
        this.closeTradeEvent.emit();
      });
  }

  private async closeSimpleTradeWithTradePromise(trade: TradeDTO, tradePromise: TradePromise): Promise<ITradePromise> {
    tradePromise.error(msg => this.notificationProvider.onTradeCloseError(trade, msg.Message));
    tradePromise.success(() => {
      this.notificationProvider.onTradeClose(trade);
      this.closeTradeEvent.emit();
    });
    return (await this.tradeCommandSender.closeTradeWithTradePromise(trade, tradePromise));
  }

  public async closeTrades(trades: Trade[]): Promise<number> {
    const count = trades.length;
    trades.forEach(async (trade) => {
      if (!trade.IsPending) {
        const temp = new TradeDTO();
        temp.readFromTrade(trade);
        await this.closeTrade(temp);
        this.closeAllTradersEventEmitter.emit('step');
      }
    });

    return count;
  }

  public async updatePosition(order: TradeDTO): Promise<ITradePromise> {
    try {
      this.validateAction(order);
    } catch (ex) {
      if (isPendingTrade(order.Type)) {
        this.notificationProvider.onTradePendingUpdateError(order, (ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate);
      } else {
        this.notificationProvider.onTradeUpdateError(order, (ex as CustomValidationError).message, (ex as CustomValidationError).keyTranslate);
      }
      return;
    }

    if (isPendingTrade(order.Type)) {
      return await this.updatePendingTrade(order);
    } else {
      return await this.updateSimpleTrade(order);
    }
  }

  private async updateSimpleTrade(trade: TradeDTO): Promise<ITradePromise> {
    this.useAlternativeNotificationOpenOrder(trade, 'updateSimpleTrade');

    return (await this.tradeCommandSender.updateTrade(trade))
      .error(msg => {
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradeUpdateError(trade, msg.Message);
      })
      .success(msg => {
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradeUpdate(trade);
      });
  }

  private async updatePendingTrade(trade: TradeDTO): Promise<ITradePromise> {
    this.useAlternativeNotificationOpenOrder(trade, 'updatePendingTrade');

    return (await this.tradeCommandSender.updatePendingOrder(trade))
      .error(msg => {
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradePendingUpdateError(trade, msg.Message);
      })
      .success(msg => {
        this.responseHasBeenReceived = true;
        this.notificationProvider.onTradePendingUpdate(trade);
      });
  }

  public async closeAllOrNegativeOrPositiveTrades(typeChecking?: string): Promise<void> {
    let trades = this.tradeStorage.OpenedTrades;

    if (typeChecking === 'all') {
      this.closeAllTradersEventEmitter.emit('OpenTraders');
    }

    if (typeChecking === 'negative') {
      trades = this.tradeStorage.OpenedTrades.filter((item) => item.UPL < 0);
      this.closeAllTradersEventEmitter.emit('NegativeTraders');
    }

    if (typeChecking === 'positive') {
      trades = this.tradeStorage.OpenedTrades.filter((item) => item.UPL > 0);
      this.closeAllTradersEventEmitter.emit('PositiveTraders');
    }

    await this.closeTrades(trades);
  }

  public async cancelPendings(): Promise<void> {
    this.closeAllTradersEventEmitter.emit('Pendings');
    this.tradeStorage.OpenedTrades
      .filter(value => value.IsPending)
      .map(value => {
        const dto = new TradeDTO();
        dto.TradeId = value.TradeId;
        dto.Symbol = value.Symbol;
        return dto;
      })
      .forEach(value => {
        this.closeAllTradersEventEmitter.emit('step');
        this.deletePending(value, 'Closing of all pendings from the method cancelPendings');
      });
  }

  public getNettedTrades(): NettedTrade[] {
    return this.tradeStorage.nettedTrades;
  }

  public get PositionClosedSubscription(): SmartEmitter<string> {
    return this.tradeStorage.PositionClosedSubscription;
  }

  public get CloseAllTradersEventEmitter() {
    return this.closeAllTradersEventEmitter;
  }

  public get CloseTradeEvent() {
    return this.closeTradeEvent;
  }

  public getLotDelimiter(): number| null {
    return this.trader.LotDelimeter;
  }

  public getVolumeForOpenOrdersForOneClick(symbol: Symbol, openPrice: number, type: TradeType = TradeType.Sell): number {
    let volume = this.getVolume(symbol, openPrice, type);

    if (this.settingsService.Settings.UseLotTrading) {
      volume = OperationsWithVolume.convertFromLotToVolume(volume, symbol.ContractSize);
    }

    return volume;
  }

  public getVolumeForOpenOrders(symbol: Symbol, openPrice: number, type: TradeType = TradeType.Sell): number {
    let volume = this.getVolume(symbol, openPrice, type);
    const step = this.getStep(symbol);

    if (volume) {
      volume = OperationsWithVolume.toFixedHard(volume, OperationsWithVolume.numberOfDigitsAfterDot(step));
    } else {
      volume = 0;
    }

    const resultRemainderOfDivision = OperationsWithVolume.remainderOfDivision(volume, step);
    const remainderOfDivision = OperationsWithVolume.checkVolume(resultRemainderOfDivision, 1);

    if (remainderOfDivision !== 0) {
      if (step < volume) {
        volume = volume - remainderOfDivision;
      } else {
        volume = step;
      }
    }

    if (volume === 0 || volume < 0) {
      volume = step;
    }

    return volume;
  }

  private getVolume(symbol: Symbol, openPrice: number, type: TradeType): number {
    let volume: number;

    if (this.settingsService.Settings.UseLotTrading) {
      if (this.settingsService.Settings.UseAutoTradeSizing) {
        volume = this.volumeTakingIntoAccountRiskPercentage(symbol, openPrice, type);
        volume = OperationsWithVolume.convertFromVolumeToLots(volume, symbol.ContractSize);
      } else {
        volume = this.settingsService.Settings.OneClickLot;
      }
    } else {
      if (this.settingsService.Settings.UseAutoTradeSizing) {
        volume = this.volumeTakingIntoAccountRiskPercentage(symbol, openPrice, type);
      } else {
        volume = this.settingsService.Settings.OneClickAmount;
      }
    }

    return Math.abs(volume);
  }

  private volumeTakingIntoAccountRiskPercentage(symbol: Symbol, openPrice: number, type: TradeType): number {
    let volume = 0;
    const amountOfRisk = this.accounting.Equity / 100 * this.settingsService.Settings.PendingOrderAccountRiskPercentage;
    const SLPrice = this.getSLPrice(symbol, type);

    if (type === TradeType.Buy || type === TradeType.BuyLimit || type === TradeType.BuyStop) {
      volume = amountOfRisk / (openPrice - SLPrice );
    } else {
      volume = amountOfRisk / (SLPrice - openPrice);
    }

    volume = volume / symbol.LastQuote.ConversionToUSD;

    volume = OperationsWithVolume.toFixedHard(volume, 2);
    return volume;
  }

  private getStep(symbol: Symbol): number {
    let step: number;

    if (this.settingsService.Settings.UseLotTrading) {
      step = OperationsWithVolume.convertFromVolumeToLots(symbol.TradingStep, symbol.ContractSize, this.trader.LotDelimeter);
    } else {
      step = symbol.TradingStep;
    }

    return step;
  }

  private getSLPrice(symbol: Symbol, type: TradeType): number {
    const SLPips = this.settingsService.Settings.OneClickSL;
    const SLPoint = SLPips * symbol.PipSize;
    let SLPrice = 0;

    if (type === TradeType.Buy || type === TradeType.BuyLimit || type === TradeType.BuyStop) {
      SLPrice = symbol.LastQuote.Ask - SLPoint;
    } else {
      SLPrice = symbol.LastQuote.Bid + SLPoint;
    }

    return SLPrice;
  }

  private useAlternativeNotificationOpenOrder(order: TradeDTO, type: string) {

    if (this.config.Settings.alternativeOrderNotification !== undefined && this.config.Settings.alternativeOrderNotification) {

      console.log('useAlternativeNotificationOpenOrder');
      console.log('type', type);
      console.log('responseHasBeenReceived', this.responseHasBeenReceived);

      setTimeout(() => {
        if (!this.responseHasBeenReceived) {

          if (type === 'close') {
            this.notificationProvider.onTradeClose(order);
            this.closeTradeEvent.emit();
          }

          if (type === 'open') {
            this.notificationProvider.onTradeOpen(order);
          }

          if (type === 'deletePending') {
            this.notificationProvider.onTradePendingDelete(order);
          }

          if ( type === 'updateSimpleTrade') {
            this.notificationProvider.onTradeUpdate(order);
          }

          if ( type === 'updatePendingTrade') {
            this.notificationProvider.onTradePendingUpdate(order);
          }

        } else {
          this.responseHasBeenReceived = false;
        }
      }, 500);


    }
  }

  public getDecimalPlaces(symbol: Symbol): number {
    if (this.settingsService.Settings.UseLotTrading) {
      return OperationsWithVolume.numberOfDigitsAfterDot(this.getStep(symbol));
    }
    return symbol.VolumeDecimalPlaces;
  }

}
