import {StopConverter} from '../utils/calculations/sltp/stop-converter';
import {StopType} from '../utils/calculations/sltp/stop-type';
import {TradeDTO} from './trade-d-t-o';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {getTradeSign} from '@common/trade/models/trade-type';
import {TradeProfitSign} from '@common/trade/models/trade-profit-sign';
import {Accounting} from '../../trader/models/accounting.service';

export abstract class TradeBound {
  protected stopConverter: StopConverter;

  protected value = 0;
  protected isClear = true;

  private logger = LoggerFactory.getLogger(this);

  public get StopType(): StopType {
    return this.type;
  }

  protected abstract getPercent(): number;
  protected abstract getProfit(): number;
  protected abstract getPrice(): number;
  protected abstract getPips(): number;


  public get Price(): number {
    if (this.isClear) {
      return 0;
    }
    return this.getPrice();
  }

  public set Price(v: number) {
    if (this.value === this.stopConverter.convertPrice(v)) { return; }

    this.value = Math.abs(this.stopConverter.convertPrice(v));
    this.isClear = false;
  }

  public get Pips(): number {
    if (this.isClear) {
      return 0;
    }
    return Math.abs(this.getPips());
  }

  public get Profit(): number {
    if (this.isClear) {
      return 0;
    }
    return Math.abs(this.getProfit());
  }

  public get Percent(): number {
    return Math.abs(this.getPercent());
  }

  public set NativeValue(v: number) {
    if (this.value === v) { return; }

    this.logger.debug(`Native: ${v}`);
    this.value = v;
    this.isClear = false;

  }

  public get NativeValue(): number {
    if (this.isClear) { return 0; }
    return this.value;
  }

  protected getValue() {
    return this.value;
  }

  protected get Equity(): number {
    return this.accounting.Equity;
  }

  protected constructor(protected trade: TradeDTO, private readonly type: StopType, private accounting: Accounting) {
    this.stopConverter = new StopConverter(trade, type, accounting);
  }

  public clear() {
    this.isClear = true;
  }

  public get IsClear(): boolean {
    return this.isClear;
  }

  protected get TradeSign(): TradeProfitSign {
    return getTradeSign(this.trade.Type);
  }
}

export class PriceBound extends TradeBound {
  public constructor(trade: TradeDTO, accounting: Accounting) {
    super(trade, StopType.ByPrice, accounting);
  }

  protected getPrice(): number {
    return this.stopConverter.getPrice(this.value);
  }

  protected getPips(): number {
    return this.stopConverter.getPips(this.value);
  }

  protected getProfit(): number {
    return this.stopConverter.getProfit(this.value);
  }

  protected getPercent(): number {
    return this.stopConverter.getPercent(this.value);
  }
}

abstract class PipsBound extends TradeBound {
  protected constructor(trade: TradeDTO, accounting: Accounting) {
    super(trade, StopType.ByPips, accounting);
  }

  protected getPrice(): number {
    return this.stopConverter.getPrice(this.getValue() * this.TradeSign);
  }

  protected getPips(): number {
    return this.stopConverter.getPips(this.getValue() * this.TradeSign);
  }

  protected getProfit(): number {
    return this.stopConverter.getProfit(this.getValue() * this.TradeSign);
  }

  protected getPercent(): number {
    return this.stopConverter.getPercent(this.getValue() * this.TradeSign);
  }
}

export class TakeProfitPipsBound extends PipsBound {
  protected get TradeSign(): TradeProfitSign {
    return getTradeSign(this.trade.Type);
  }
  public constructor(trade: TradeDTO, accounting?: Accounting) {
    super(trade, accounting);
  }
}

export class StopLossPipsBound extends PipsBound {
  public constructor(trade: TradeDTO, accounting?: Accounting) {
    super(trade, accounting);
  }

  protected getValue(): number {
    return -super.getValue();
  }
}

export abstract class ProfitBound extends TradeBound {
  protected constructor(trade: TradeDTO, accounting: Accounting, type = StopType.ByProfit) {
    super(trade, type, accounting);
  }

  protected getPrice(): number {
    return this.stopConverter.getPrice(this.getValue());
  }

  protected getPips(): number {
    return this.stopConverter.getPips(this.getValue());
  }

  protected getProfit(): number {
    return this.stopConverter.getProfit(this.getValue());
  }

  protected getPercent(): number {
    return this.stopConverter.getPercent(this.getValue());
  }
}

export class TakeProfitProfitBound extends ProfitBound {
  public constructor(trade: TradeDTO, accounting: Accounting) {
    super(trade, accounting);
  }
}

export class StopLossProfitBound extends ProfitBound {
  public constructor(trade: TradeDTO, accounting: Accounting) {
    super(trade, accounting);
  }

  protected getValue(): number {
    return -super.getValue();
  }
}

export abstract class PercentBound extends ProfitBound {
  public constructor(trade: TradeDTO, accounting: Accounting) {
    super(trade, accounting, StopType.ByPercent);
  }
}

export class TakeProfitPercentBound extends PercentBound {
}

export class StopLossPercentBound extends PercentBound {
  protected getValue(): number {
    return -super.getValue();
  }
}

export class TradeBoundFactory {
  public static GetBound(type: StopType, trade: TradeDTO, accounting: Accounting, isTp: boolean = true) {
    switch (type) {
      case StopType.ByProfit: {
        if (isTp) {
          return new TakeProfitProfitBound(trade, accounting);
        } else {
          return new StopLossProfitBound(trade, accounting);
        }
      }
      case StopType.ByPips: {
        if (isTp) {
          return new TakeProfitPipsBound(trade, accounting);
        } else {
          return new StopLossPipsBound(trade, accounting);
        }
      }
      case StopType.ByPrice: {
        return new PriceBound(trade, accounting);
      }
    }
  }

  public GetBound(type: StopType, trade: TradeDTO, isTp: boolean = true) {
    switch (type) {
      case StopType.ByPercent: {
        if (isTp) {
          return new TakeProfitPercentBound(trade, this.accounting);
        } else {
          return new StopLossPercentBound(trade, this.accounting);
        }
      }
      case StopType.ByProfit: {
        if (isTp) {
          return new TakeProfitProfitBound(trade, this.accounting);
        } else {
          return new StopLossProfitBound(trade, this.accounting);
        }
      }
      case StopType.ByPips: {
        if (isTp) {
          return new TakeProfitPipsBound(trade, this.accounting);
        } else {
          return new StopLossPipsBound(trade, this.accounting);
        }
      }
      case StopType.ByPrice: {
        return new PriceBound(trade, this.accounting);
      }
    }
  }

  constructor(private accounting: Accounting) {

  }
}
