import {ProfitCalculator} from '../../../profit-calculator';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {StopType} from '@common/trade/utils/calculations/sltp/stop-type';
import {PriceCalculator} from '@common/trade/utils/price-calculator';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeType} from '@common/trade/models/trade-type';
import {Accounting} from '../../../../../../trader/models/accounting.service';

export abstract class StopCalculator {

  readonly useLotTrading: boolean;
  readonly pageModify: boolean;

  public constructor(protected trade: TradeDTO, private accounting: Accounting){

    // получаем значение в каком формате идут торги (не понял как прочитат setting поэтому решил так передавать занчение UseLotTrading)
    if (sessionStorage.getItem('UseLotTrading') != null) {
      this.useLotTrading = JSON.parse(sessionStorage.getItem('UseLotTrading'));
    } else {
      this.useLotTrading = true;
    }

    // для окна модификации ордера при его открытии создается в sessionStorage запись и передается не текущая цена, а цена открытия

    if (sessionStorage.getItem('PageModify') != null) {
      this.pageModify = JSON.parse(sessionStorage.getItem('PageModify'));
    } else {
      this.pageModify = false;
    }
  }

  protected get Symbol(): Symbol {
    return this.trade.Symbol;
  }

  protected get Type(): TradeType {
    return this.trade.Type;
  }

  protected getProfit(openPrice: number, closePrice: number): number {
    return this.ProfitCalculator.calculateProfit(this.Volume, openPrice, closePrice);
  }

  protected profitToPercent(profit: number): number {
    return profit / this.accounting.Equity * 100;
  }

  protected percentToProfit(percent: number): number {
    return  this.Equity * percent / 100;
  }

  protected priceToPips(price: number): number {
    return PriceCalculator.priceToPips(price, this.Symbol);
  }

  protected addPipsToPrice(pips: number, price: number): number {
    return PriceCalculator.addPipsToPrice(price, pips, this.Symbol);
  }

  protected priceAtProfit(profit: number): number {
    return  this.ProfitCalculator.calculatePriceAtProfit(this.Volume, this.CurrentPrice, profit);
  }

  protected get ProfitCalculator(): ProfitCalculator {
    return new ProfitCalculator(this.Symbol, this.Type)
  }

  protected get CurrentPips(): number {
    return this.priceToPips(this.CurrentPrice);
  }

  protected get CurrentPrice(): number {
    if (this.pageModify) {
      return this.trade.OpenPrice;
    }
    return this.trade.CurrentPrice;
  }

  protected get Volume(): number {
    // в зависимости от условий торгов значение умножается на размер контракта для корректного отображения T/P и S/L
    if (this.useLotTrading) {
      return this.trade.Volume * this.Symbol.ContractSize;
    } else {
      return this.trade.Volume;
    }
  }

  protected get Equity(): number {
    return this.accounting.Equity;
  }

  public abstract calculateToPrice(value: number): number;
  public abstract calculateToPips(value: number): number;
  public abstract calculateToProfit(value: number): number;
  public abstract calculateToPercent(value: number): number;
  public abstract convertPrice(closePrice: number): number;
  public abstract convertPips(pips: number): number;
  public abstract convertProfit(profit: number): number;
  public abstract convertPercent(percent: number): number;
}

class StopFromProfitCalculator extends StopCalculator {
  convertPips(pips: number): number {
    const openPrice = this.CurrentPrice;

    const closePrice = PriceCalculator.addPipsToPrice(openPrice, pips, this.trade.Symbol);

    return this.convertPrice(closePrice);
  }

  convertPrice(closePrice: number): number {
    return this.getProfit(this.CurrentPrice, closePrice);
  }

  convertProfit(profit: number): number {
    return profit;
  }

  convertPercent(percent: number): number {
    return this.percentToProfit(percent);
  }

  calculateToProfit(profit: number): number {
    return profit;
  }

  calculateToPips(profit: number): number {
    const closePrice = this.priceAtProfit(profit);

    const closePip = this.priceToPips(closePrice);

    const openPip = this.priceToPips(this.CurrentPrice);

    const pips = closePip - openPip;

    const closePrice2 = PriceCalculator.pipsToPrice(openPip + pips, this.Symbol);

    return pips;
  }

  calculateToPrice(profit: number): number {
    const result = this.priceAtProfit(profit);

    return result;
  }

  calculateToPercent(profit: number): number {
    return (profit / this.Equity) * 100;
  }
}

class StopFromPriceCalculator extends StopCalculator {
  convertPips(pips: number): number {
    const result = this.addPipsToPrice(pips, this.CurrentPrice);

    return result;
  }

  convertPrice(closePrice: number): number {
    return closePrice;
  }

  convertProfit(profit: number): number {
    const result = this.priceAtProfit(profit);
    return result;
  }

  convertPercent(percent: number): number {
    const profit = this.percentToProfit(percent);
    return this.priceAtProfit(profit);
  }

  calculateToPips(closePrice: number): number {
    const closePips = this.priceToPips(closePrice);

    return closePips - this.CurrentPips;
  }

  calculateToPrice(closePrice: number): number {
    return closePrice;
  }

  calculateToProfit(closePrice: number): number {
    return this.getProfit(this.CurrentPrice, closePrice);
  }

  calculateToPercent(closePrice: number): number {
    const profit = this.calculateToProfit(closePrice);

    return this.profitToPercent(profit);
  }
}

class StopFromPipsCalculator extends StopCalculator {
  convertPips(pips: number): number {
    return pips;
  }

  convertPrice(closePrice: number): number {
    const result = closePrice - this.CurrentPrice;

    return PriceCalculator.priceToPips(result, this.trade.Symbol);
  }

  convertProfit(profit: number): number {
    const price = this.priceAtProfit(profit);

    return this.convertPrice(price);
  }

  convertPercent(percent: number): number {
    const profit = this.percentToProfit(percent);

    return this.convertProfit(profit);
  }

  calculateToPips(pips: number): number {
    return pips;
  }

  calculateToPrice(pips: number): number {
    const result = this.addPipsToPrice(pips, this.CurrentPrice);

    return result;
  }

  calculateToProfit(pips: number): number {
    const closePrice = this.addPipsToPrice(pips, this.CurrentPrice);

    return this.getProfit(this.CurrentPrice, closePrice);
  }

  calculateToPercent(pips: number): number {
    const profit = this.calculateToProfit(pips);

    return this.profitToPercent(profit);
  }
}

class StopFromPercentCalculator extends StopCalculator {
  convertPips(pips: number): number {
    const closePrice = this.addPipsToPrice(pips, this.CurrentPrice);

    const profit = this.getProfit(this.CurrentPrice, closePrice);

    return this.profitToPercent(profit)
  }

  convertPrice(closePrice: number): number {
    const profit = this.getProfit(this.CurrentPrice, closePrice);

    return this.profitToPercent(profit);
  }

  convertProfit(profit: number): number {
    return this.profitToPercent(profit)
  }

  convertPercent(percent: number): number {
    return percent
  }

  calculateToPips(percent: number): number {
    const profit = this.percentToProfit(percent);
    const price = this.priceAtProfit(profit);

    const pips = this.priceToPips(price);

    return pips - this.CurrentPips;
  }

  calculateToPrice(percent: number): number {
    const profit = this.percentToProfit(percent);
    const price = this.priceAtProfit(profit);

    return price;
  }

  calculateToProfit(percent: number): number {
    const profit = this.percentToProfit(percent);
    return profit;
  }

  calculateToPercent(percent: number): number {
    return percent;
  }
}

export class StopCalculatorsFactory {
  public static getCalculator(trade: TradeDTO, type: StopType, accounting): StopCalculator {
    switch (type) {
      case StopType.ByPips: {
        return new StopFromPipsCalculator(trade, accounting);
      }
      case StopType.ByPrice: {
        return new StopFromPriceCalculator(trade, accounting);
      }
      case StopType.ByProfit: {
        return new StopFromProfitCalculator(trade, accounting);
      }
      case StopType.ByPercent: {
        return new StopFromPercentCalculator(trade, accounting);
      }
    }
  }
}
