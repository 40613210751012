import {Injectable} from '@angular/core';
import {TradeService} from '@common/trade/services/trade.service';
import {AbstractTradeStrategy} from '@common/actions/strategies/trade/abstract-trade.strategy';
import {OneClickTradeStrategy} from '@common/actions/strategies/trade/one-click-trade.strategy';
import {SimpleTradeStrategy} from './simple-trade.strategy';
import {AbstractTradeStrategyFactory} from '@common/actions/strategies/trade/abstract-trade-strategy.factory';
import {SettingsService} from '@common/trader/services/settings.service';
import {Router} from '@angular/router';
import {Accounting} from '@common/trader/models/accounting.service';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';

@Injectable({
  providedIn: 'root'
})
export class TradeStrategyFactory extends AbstractTradeStrategyFactory {
  public constructor(private router: Router,
                     private tradeService: TradeService,
                     private settingsService: SettingsService,
                     private notificationProvider: NotificationProviderService,
                     private accounting: Accounting) {
    super();
  }

  public getStrategy(): AbstractTradeStrategy {
    const oneClickEnabled = this.settingsService.Settings.OneClickTrading;

    if (oneClickEnabled) {
      return new OneClickTradeStrategy(this.tradeService, this.settingsService, this.notificationProvider, this.accounting);
    } else {
      return new SimpleTradeStrategy(this.router);
    }
  }
}
