<form
  name="loginForm"
  action="#"
  class="c-form"
  #form="ngForm"
  (keydown.enter)="tryLogin(form)"
  (ngSubmit)="tryLogin(form)"
  *ngIf="!isZeTradex(); else zetradex"
  (keydown)="resetErrorText()"
>
  <div class="c-form-group">
    <input
      type="text"
      [placeholder]="'AuthModule_SignInComponent_LoginInput' | translation"
      class="c-input form-input-userid"
      name="login"
      [ngModel]="login"
    />
  </div>

  <div class="c-form-group">
    <input
      type="password"
      [placeholder]="'AuthModule_SignInComponent_PasswordInput' | translation"
      class="c-input form-input-password"
      name="password"
      [ngModel]="password"
    />
  </div>

  <div class="c-form-group" *ngIf="IsShowSwitchRegionConnect">
    <app-l-switch-region-connect></app-l-switch-region-connect>
  </div>



  <div class="c-form-group error-output" *ngIf="ErrorText">
    <span>{{ErrorText}}</span>
  </div>
  <div class="c-form-group error-output" *ngIf="ErrorConnectServer">
    <span>{{ErrorConnectServer}}</span>
  </div>
  <span>
    <button
      class="button-success submit login-button button-success-blue-color"
      (click)="saveLocale()"
      [attr.disabled]="LoginDisabled ? 'disabled' : null"
      type="submit"
    ><l-unit [key]="'AuthModule_SignInComponent_login-button'"></l-unit>
    </button>
  </span>

  <div class="c-form-group connecting-string" [hidden]="!isConnecting && !isReconnecting">
    <span class="user-profile-reconnecting"></span>
    <span [hidden]="!isConnecting"><l-unit [key]="'AuthModule_SignInComponent_connecting-string'"></l-unit>...</span>
    <span [hidden]="!isReconnecting"><l-unit [key]="'AuthModule_SignInComponent_Reconnectiong'"></l-unit></span>
  </div>

  <div class="abort-connection-group">
    <a
      class="abort-connection-btn"
      (click)="abortConnection()"
      [hidden]="!isConnecting && !isReconnecting"
    ><l-unit [key]="'AuthModule_SignInComponent_AbortConnection'"></l-unit>
    </a>
  </div>

  <a
    class="forgot-password-button"
    *ngIf="IsShowForgotPassword"
    [hidden]="isConnecting || isReconnecting || !isForgotPasswordLinkAvailable"
    (click)="onForgotPassword($event)"
  ><l-unit [key]="'AuthModule_SignInComponent_forgot-password-button'"></l-unit>
  </a>

  <a
    class="login-as-guest-button"
    [hidden]="!IsSignInAsGuestAvailable || isConnecting || isReconnecting"
    (click)="loginAsGuest()"
  ><l-unit [key]="'AuthModule_SignInComponent_LoginAsGuest'"></l-unit>
  </a>

  <div class="c-form-group" *ngIf="isShowAnswerLoginSuccess">
    <span class="inform-text-loaded" *ngIf="isAnswerLoginSuccessReceived()" ><l-unit [key]="'AuthModule_SignInComponent_AnswerLoginSuccessReceived'"></l-unit></span>
    <span class="inform-text-loaded" *ngIf="isTraderInfoLoaded()" ><l-unit [key]="'AuthModule_SignInComponent_TraderInfoLoaded'"></l-unit></span>
    <span class="inform-text-loaded">{{ShowTextAnswerLoginSuccess}}</span>
  </div>

</form>


<ng-template #zetradex>

  <div class="z-form-container" [class.z-form-container-mobile]="IsMobile" >

    <h1 class="z-title-text" ><l-unit [key]="'AuthModule_SignInComponent_SignInToYourAccount'"></l-unit></h1>
    <form
      name="loginForm"
      action="#"
      class="c-form z-form"
      #form="ngForm"
      (keydown.enter)="tryLogin(form)"
      (keydown)="resetErrorText()"
      (ngSubmit)="tryLogin(form)"
    >
      <div class="c-form-group">
        <input
          type="text"
          [placeholder]="'AuthModule_SignInComponent_LoginInput' | translation"
          class="c-input form-input-userid z-input login-z-input z-input-color-text"
          name="login"
          ngModel
        />
      </div>

      <div class="c-form-group z-form-group">
        <input
          [type]="hide ? 'password' : 'text'"
          [placeholder]="'AuthModule_SignInComponent_PasswordInput' | translation"
          class="c-input form-input-password z-input login-z-input z-input-color-text"
          name="password"
          ngModel
        />
        <span class="z-visibility-icon" (click)="hide = !hide" [ngClass]="getVisibilityIcon()"></span>
      </div>

      <div class="c-form-group error-output z-error-color" *ngIf="ErrorText">
        <span>{{ErrorText}}</span>
      </div>
      <div class="c-form-group error-output z-error-color" *ngIf="ErrorConnectServer">
        <span>{{ErrorConnectServer}}</span>
      </div>

      <div class="z-button-group">
        <span class="z-checkbox-container">
          <input
            id="z-checkbox"
            type="checkbox"
            [attr.checked]="staySignedIn"
            (click)="changeStateCheckbox()"
            class="z-checkbox-input"
          />
          <label for="z-checkbox" class="z-checkbox-text"><l-unit [key]="'AuthModule_SignInComponent_StaySignedIn'"></l-unit></label>
        </span>
        <a
          class="z-forgot-password-button"
          [hidden]="isConnecting || isReconnecting || !isForgotPasswordLinkAvailable"
          (click)="onForgotPassword($event)"
        ><l-unit [key]="'AuthModule_SignInComponent_forgot-password-button'"></l-unit></a>
      </div>

      <span class="z-button-submit">
        <button
          class="button-success submit login-button z-button-success"
          (click)="saveLocale()"
          [attr.disabled]="LoginDisabled ? 'disabled' : null"
          type="submit"
        ><l-unit [key]="'AuthModule_SignInComponent_login-button'"></l-unit>
        </button>
      </span>

      <div class="c-form-group connecting-string" [hidden]="!isConnecting && !isReconnecting">
        <span class="user-profile-reconnecting"></span>
        <span [hidden]="!isConnecting"><l-unit [key]="'AuthModule_SignInComponent_connecting-string'"></l-unit>...</span>
        <span [hidden]="!isReconnecting"><l-unit [key]="'AuthModule_SignInComponent_Reconnectiong'"></l-unit></span>
      </div>

      <div class="abort-connection-group">
        <a
          class="abort-connection-btn"
          (click)="abortConnection()"
          [hidden]="!isConnecting && !isReconnecting"
        ><l-unit [key]="'AuthModule_SignInComponent_AbortConnection'"></l-unit>
        </a>
      </div>

      <a
        class="login-as-guest-button z-input-color-text"
        [hidden]="!IsSignInAsGuestAvailable || isConnecting || isReconnecting"
        (click)="loginAsGuest()"
      ><l-unit [key]="'AuthModule_SignInComponent_LoginAsGuest'"></l-unit>
      </a>

      <div class="c-form-group" *ngIf="isShowAnswerLoginSuccess">
        <span class="inform-text-loaded" *ngIf="isAnswerLoginSuccessReceived()" ><l-unit [key]="'AuthModule_SignInComponent_AnswerLoginSuccessReceived'"></l-unit></span>
        <span class="inform-text-loaded" *ngIf="isTraderInfoLoaded()" ><l-unit [key]="'AuthModule_SignInComponent_TraderInfoLoaded'"></l-unit></span>
        <span class="inform-text-loaded">{{ShowTextAnswerLoginSuccess}}</span>
      </div>

    </form>

  </div>

</ng-template>




