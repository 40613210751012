import {ConnectionState} from './connection-state.enum';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

declare const jQuery: any;

interface StartingObj {
  isStarting: boolean;
  startDate: Date | null;
}

export abstract class ConnectionBase {
  private _connectionState: ConnectionState;
  private _connection: any;
  private _hubProxy: any;
  private _reconnectionInterval: any;
  protected _logger = LoggerFactory.getLogger(this);
  private _onConnection: SmartEmitter<void> = new SmartEmitter<void>();
  private _connectionUrl: string;
  private readonly _hubProxyName: string;
  private _isStarting = false;

  protected constructor(connectionUrl: string, hubProxyName: string) {
    this._connectionState = ConnectionState.Disconnected;
    this._connectionUrl = connectionUrl;
    this._hubProxyName = hubProxyName;
  }

  public get State(): ConnectionState {
    return this._connectionState;
  }

  set ConnectionUrl(url: string) {
    this._connectionUrl = url;
  }

  public async run(): Promise<void> {
    if (this._isStarting) {
      return;
    }

    this._isStarting = true;

    await this.beginConnection();
    await this.createConnection();
    await this.setConnectionData(this._connection);
    await this.createHubProxy();
    await this.initializeHubProxy(this._hubProxy);

    await this.initializeConnection();

    await this._connection.start(() => {
      clearTimeout(this._reconnectionInterval);
    }).done(() => {
      this.onConnectionStart();
      this.onInitializationDone();
      this._connectionState = ConnectionState.Connected;
      this._isStarting = false;
    });
  }

  private async createConnection() {
    this._connection = jQuery.hubConnection();
    this._connection.url = this._connectionUrl;
  }

  protected async setConnectionData(connection: any) { }

  private async createHubProxy() {
    this._hubProxy = this._connection.createHubProxy(this._hubProxyName);
    this._logger.debug('hubproxy created: ', this._hubProxy);
  }

  protected async beginConnection() {
  }

  protected abstract /*async*/ initializeHubProxy(hubProxy: any);

  private async initializeConnection() {
    this._connection.error(async (error) => {
      console.log('error', error);
      this._connection.stop();
      this._connectionState = ConnectionState.Disconnected;
      await this.tryConnect();
    });
  }

  private onConnectionStart() {
    this._onConnection.emit();
  }

  private async tryConnect() {
    try {
      await this._connection.start();
      clearInterval(this._reconnectionInterval);
    } catch (err) {
      this._reconnectionInterval = setTimeout(() => this.tryConnect(), 5000);
    }
  }

  public addMessageHandler(messageName: string, handler: (answer) => void): void {
    this._hubProxy.on(messageName, handler);
  }

  protected onInitializationDone(): void { }

  public async invokeMessage(messageName: string,
                             param1?: any, param2?: any, param3?: any,
                             param4?: any, param5?: any, param6?: any): Promise<any> {
    this._logger.debug('Try use hubproxy:', this._hubProxy);
    let invokeResult: any;

    // TODO: Re-implement this by using a parent class for all requests

    if (param6) {
      invokeResult = this._hubProxy.invoke(messageName, param1, param2, param3, param4, param5, param6);
    } else if (param5) {
      invokeResult = this._hubProxy.invoke(messageName, param1, param2, param3, param4, param5);
    } else if (param4) {
      invokeResult = this._hubProxy.invoke(messageName, param1, param2, param3, param4);
    } else if (param3) {
      invokeResult = this._hubProxy.invoke(messageName, param1, param2, param3);
    } else if (param2) {
      invokeResult = this._hubProxy.invoke(messageName, param1, param2);
    } else if (param1) {
      invokeResult = this._hubProxy.invoke(messageName, param1);
    } else {
      invokeResult = this._hubProxy.invoke(messageName);
    }

    const answer = await invokeResult;

    return answer;
  }

  public async sendMessage(messageSerialized: string) {
    return this.invokeMessage('Message', messageSerialized);
  }

  public stopConnect() {
    if (this._connection) {
      this._connection.stop();
      this._connectionState = ConnectionState.Disconnected;
      console.log('ConnectionState', 23, this._connectionState);
    }
  }

}
