import {Injectable} from '@angular/core';
import {Trader} from '@common/trader/models/trader';
import {AppConfig} from '@common/configuration/app-config';
import {Router} from '@angular/router';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Quote} from '@common/symbol/models/quote';
import {TradeSoundPlayerService} from '@common/player/trade-sound-player.service';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {OperationsWithText} from '@common/trade/utils/operations-with-text';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';

export interface AlertPricesObj {
  alertId: number;
  symbolId: number;
  symbolName: string;
  prices: number;
  priceRange: number[];
  trigger: string;
  conditions: ConditionEnum;
  expiration: Date;
  alertName: string | undefined;
  message: string | undefined;
}

export interface InfoAlertPrices {
  traderId: number;
  alertPricesArray: AlertPricesObj[];
}
export enum ConditionEnum {
  MoreBid = 'MoreBid',
  LessBid = 'LessBid',
  MoreAsk = 'MoreAsk',
  LessAsk = 'LessAsk',
}

export interface Condition {
  code: ConditionEnum;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class AlertChangesPriceService {

  private limitAlertPrices = 5;
  private localData: InfoAlertPrices[] = [];
  private arrayAlertPrices: AlertPricesObj[] = [];
  private subscriptionsMap = new Map<number, ISmartSubscription>();

  constructor(private trader: Trader,
              private router: Router,
              private appConfig: AppConfig,
              private symbolStorage: SymbolStorageService,
              private tradeSoundPlayer: TradeSoundPlayerService) {
    if (this.appConfig.Settings.limitAlertPrices !== undefined) {
      this.limitAlertPrices = this.appConfig.Settings.limitAlertPrices;
    }

    this.init();
  }

  public init(firstInit = false) {
    const data = JSON.parse(localStorage.getItem('alertPricesArray'));
    if (data !== null) {
      this.localData = this.checkCorrectDate(data);
      this.localData.forEach((e: InfoAlertPrices) => {
        if (this.trader.TraderId === e.traderId) {
          this.arrayAlertPrices = e.alertPricesArray;
        }
      });

      if (firstInit) {
        this.allSubscribeChangePrices(this.arrayAlertPrices);
      }
    }
  }

  public createNewAlertPrices(obj: AlertPricesObj) {
    this.saveDataAlertPrices(obj);
    this.init();
  }

  private checkCorrectDate(data: InfoAlertPrices[]): InfoAlertPrices[] {
    data.forEach((e: InfoAlertPrices) => {
      if (this.trader.TraderId === e.traderId) {
        e.alertPricesArray.forEach((item: AlertPricesObj) => {
          const ex = new Date(item.expiration);
          if (ex.getTime() < Date.now()) {
            this.changeArrayAlertPrices(e.alertPricesArray, item.alertId);
          }
        });
      }
    });
    return data;
  }

  public getArrayAlertPrices(): AlertPricesObj[] {
    return this.arrayAlertPrices;
  }

  private changeArrayAlertPrices(arr: AlertPricesObj[], id: number) {
    const index = arr.findIndex(n => n.alertId === id);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }

  private firstSave(traderId: number, data: AlertPricesObj) {
    const firstSave: InfoAlertPrices = {
      traderId: traderId,
      alertPricesArray: [data],
    };
    this.localData.push(firstSave);
  }

  private saveDataAlertPrices(data: AlertPricesObj) {

    const traderId = this.trader.TraderId;
    let match = false;
    let saveEventData = true;

    if (this.localData.length === 0) {
      this.firstSave(traderId, data);
      match = true;
    } else {
      this.localData.forEach((item) => {
        if (item.traderId === traderId) {
          match = true;
          if (item.alertPricesArray.length < this.limitAlertPrices) {
            item.alertPricesArray.push(data);
          } else {
            this.redirectModalAlertPriceNotification(item.alertPricesArray[0].alertId, traderId, true, false);
            this.tradeSoundPlayer.errorMessage().then();
            saveEventData = false;
          }
        }
      });
    }

    if (!match) {
      this.firstSave(traderId, data);
    }

    if (saveEventData) {
      this.saveDataInLocalStorage();
      this.redirectModalAlertPriceNotification(data.alertId, traderId);
      this.alertPricesSubscribe(data.symbolId);
      this.tradeSoundPlayer.newMessage().then();
    }
  }

  private saveDataInLocalStorage() {
    localStorage.setItem('alertPricesArray', JSON.stringify(this.localData));
  }

  private allSubscribeChangePrices(array: AlertPricesObj[]) {

    if (this.subscriptionsMap.size > 0) {
      array.forEach((item: AlertPricesObj) => {
        this.unsubscribeChangePrices(item.symbolId);
      });
    }

    array.forEach((item: AlertPricesObj) => {
      this.alertPricesSubscribe(item.symbolId);
    });
  }

  private unsubscribeChangePrices(symbolId: number): void {
    const sub = this.subscriptionsMap.get(symbolId);
    if (sub) {
      sub.unsubscribe();
      this.subscriptionsMap.delete(symbolId);
    }
  }

  private alertPricesSubscribe(symbolId: number) {
    const symbol = this.symbolStorage.findSymbolById(symbolId);
    console.log('symbol', 23, symbol);
    console.log('symbol', 23, symbol.TickSize);
    const subscribeChangePricesSymbol = symbol.PriceChange.subscribe((quote: Quote) => this.onChangePrices(quote, symbolId));
    this.subscriptionsMap.set(symbolId, subscribeChangePricesSymbol);
  }

  private onChangePrices(quote: Quote, symbolId: number) {
    this.arrayAlertPrices.forEach((e: AlertPricesObj) => {

      if (this.checkIfPrices(quote, e)) {
        if (e.trigger === 'Only') {
          this.unsubscribeChangePrices(symbolId);
        }
        this.redirectModalAlertPriceNotification(e.alertId, this.trader.TraderId, false, false);
        this.tradeSoundPlayer.newMessage().then();
      }
    });
  }

  private checkIfPrices(quote: Quote, e: AlertPricesObj): boolean {
    let prices: number;

    if (e.conditions === ConditionEnum.LessBid || e.conditions === ConditionEnum.MoreBid) {
      prices = quote.Bid;
    } else {
      prices = quote.Ask;
    }

    if (e.priceRange) {
      return e.priceRange[0] <= prices && prices <= e.priceRange[1];
    }

    return e.prices === prices;
  }

  public createPriceRange(price: number, tickSize: number): number[] {
    const count = OperationsWithVolume.numberOfDigitsAfterDot(tickSize);
    let multiplyBy = 1;

    for (let i = 0; i < count; i++) {
      multiplyBy = multiplyBy * 10;
    }

    const tempPrice = price * multiplyBy;
    const firstPrice = (tempPrice - 5) / multiplyBy;
    const secondPrices = (tempPrice + 5) / multiplyBy;

    return [firstPrice, secondPrices];
  }

  private redirectModalAlertPriceNotification(alertId: number, traderId: number, limitExceeded = false, creatingRecord = true) {
    this.router.navigate(['/terminal', {
      outlets: {
        modal: ['alert-price-notification',
          {
            alertId: alertId,
            traderId: traderId,
            limitAlertPrices: this.limitAlertPrices,
            limitExceeded: limitExceeded,
            creatingRecord: creatingRecord
          }]
      }
    }]).then();
  }

  public deleteAlertPricesObj(alertId: number) {
    this.localData.forEach((item: InfoAlertPrices) => {
      if (item.traderId === this.trader.TraderId) {
        const index = item.alertPricesArray.findIndex(n => n.alertId === alertId);
        if (index !== -1) {
          item.alertPricesArray.splice(index, 1);
        }
      }
    });
    this.saveDataInLocalStorage();
  }


  public getTextNeedLength(text: string, type: string) {
    const length: number = type === 'title' ? 20 : 30;
    return OperationsWithText.getTextNeedLength(text, length);
  }


}
