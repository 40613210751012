import {Settings} from '@common/trader/models/settings';
import {TradeDTO} from '../../models/trade-d-t-o';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {ActivatedRoute} from '@angular/router';
import {AbstractOrderForm} from '@common/trade/components/abstaract/abstract-order-form';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {TradeService} from '@common/trade/services/trade.service';

export class OpenOrderForm extends AbstractOrderForm {

  private showVolumeBoolean = false;
  private showVolumeNumber: number;

  public get Amount(): number | string {
    if (this.amount == '') {
      return this.amount;
    }

    if (this.settings.UseLotTrading) {
      return this.amount || this.settings.OneClickLot || 0.01;
    } else {
      return this.amount || this.settings.OneClickAmount || 10000;
    }

  }

  public set Amount(v: number | string) {
    if (v === undefined || this.Amount === v) {
      return;
    }

    this.amount = v.toString().trim();

    if (v !== '' && !isNaN(Number(v))) {
      this.order.Volume = Number(v);
    }
  }

  public get AmountValid(): boolean {
    return this.Amount.toString() !== '';
  }

  protected resetAmount() {
    this.amount = undefined;
  }

  public get Rate(): number {
    return TradePriceProvider.getTradeCurrentPrice(this.Symbol, this.TradeType);
  }

  public constructor(protected settings: Settings, protected route?: ActivatedRoute) {
    super();
    this.order = new TradeDTO();

    this.readOrderFromRoute();

    // подписка на изменения торгов с лотов на объёмы, сбрасывает значение amount
    this.settings.UseTradingChangedEmitter.subscribe(() => {
      this.amount = undefined;
    });
  }

  protected readOrderFromRoute() {
    if (!this.route) {
      return;
    }

    const amount = this.route.snapshot.params['amount'];

    this.Order.Volume = amount ? Number(amount) : Number(this.Amount);
  }

  public compileOrder(tradeService: TradeService): TradeDTO {

    const minLength = tradeService.getDecimalPlaces(this.Symbol);
    const lotDelimiter = tradeService.getLotDelimiter();

    if (this.settings.OneClickTrading && this.settings.UseLotTrading) {
      this.order.Volume = OperationsWithVolume.checkVolume(Number(this.Amount) * this.Symbol.ContractSize, minLength) / lotDelimiter;
    } else {
      this.order.Volume = OperationsWithVolume.checkVolume(Number(this.Amount), minLength);
    }

    this.order.OpenPrice = this.Rate;
    this.order.Symbol = this.Symbol;
    this.order.Type = this.TradeType;

    return this.order;
  }

}
