import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppInitializer, SettingsFactory} from '@common/configuration/app-initializer';
import {TerminalModule} from '@desktop-core/terminal/terminal.module';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AuthModule} from '@common/auth/auth.module';
import {FormsModule} from '@angular/forms';
import {AbstractTradeStrategyFactory} from '@common/actions/strategies/trade/abstract-trade-strategy.factory';
import {TradeStrategyFactory} from '@desktop-core/actions/trade/trade-strategy.factory';
import {TransportSignalRFactory} from '@common/communication/connection/transport/transport-signalr';
import {TransportFactory} from '@common/communication/connection/transport/transport-base';
import {environment} from '../../../../../src/environments/environment';
import {ChartService} from '@desktop-core/terminal/solve/chart-solve';
import {ThemeModule} from '@common/shared/services/theme';
import {LocaleModule} from '@common/locale/locale.module';
import {InternetConnectionComponent} from '@common/shared/components/internet-connection/internet-connection.component';

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        AuthModule,
        HttpClientModule,
        TerminalModule,
        RouterModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
        ThemeModule,
        NgOptimizedImage,
        LocaleModule,
        InternetConnectionComponent
    ],
  providers: [
    HttpClient,
    AppInitializer,
    {
      provide: AbstractTradeStrategyFactory,
      useClass: TradeStrategyFactory
    },
    {provide: TransportFactory, useClass: TransportSignalRFactory},
    SettingsFactory,
    // @ts-ignore
    ChartService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
