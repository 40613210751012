import {Injectable} from '@angular/core';
import {ConnectionBase} from '@common/charting/models/connection-base';
import {AppConfig} from '@common/configuration/app-config';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {RetrieveBarsResult} from '@common/charting/models/retrieve-bars-result';
import {ConnectionState} from '@common/charting/models/connection-state.enum';
import {HistoryRetrieveModeEnum} from '@common/trading-view-charts/services/HistoryRetrieveModeEnum';
import {BalanceLoaderService} from '@common/shared/balance-loader/balance-loader-service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UsersGeoData} from '@common/trade/services/location-position.service';

@Injectable({
  providedIn: 'root'
})
export class TradingViewItemHistoryConnectionService extends ConnectionBase {

  private _readyPromise: Promise<void>;

  private _resolveCallback: () => void;

  private arrayItemHistoryConnectionUrlsBalancedLengths = [];
  private arrayItemHistoryConnectionUrlsBalanced = [];

  private isUseNewItemHistoryConnect: boolean = null;
  private UrlHistoryConnection = '';

  constructor(private _balanceLoaderService: BalanceLoaderService, private _httpClient: HttpClient) {
    super(_balanceLoaderService.getHistoryConnectionString(), AppConfig.settings.connection.ItemHistoryHubProxyName);
    this.UrlHistoryConnection = _balanceLoaderService.getHistoryConnectionString();
  }

  private createArraysItemHistory(): void {
    const itemHistoryConnectionUrlsBalanced = this._balanceLoaderService.getItemHistoryConnectionUrlsBalanced();

    if (itemHistoryConnectionUrlsBalanced !== undefined && itemHistoryConnectionUrlsBalanced.length > 1 &&
      localStorage.getItem('BalancedHistoryConnectionNumber') !== null) {

      this.arrayItemHistoryConnectionUrlsBalanced = itemHistoryConnectionUrlsBalanced;
      const connectionUrlsBalancedLength = this.arrayItemHistoryConnectionUrlsBalanced.length;
      for (let i = 0; i < connectionUrlsBalancedLength; i++) {
        this.arrayItemHistoryConnectionUrlsBalancedLengths.push(i);
      }
    }
  }

  protected async initializeHubProxy(hubProxy: any): Promise<any> {
  }

  protected onInitializationDone(): void {
    this._resolveCallback();
  }

  protected async beginConnection(): Promise<any> {
    this._readyPromise = new Promise<void>((resolve, reject) => {
      this._resolveCallback = resolve;
    });
  }

  private changeUrlHistoryConnection(params: HttpParams) {
    const url = `${this.getNewUrlHistoryConnection()}/RetrieveBarsForPeriod`;

    console.log('NewUrlHistoryConnection', url);

    if (this.arrayItemHistoryConnectionUrlsBalancedLengths.length > 0) {
      return this._httpClient.get<RetrieveBarsResult>(url, { params: params }).pipe(
        catchError(() => this.changeUrlHistoryConnection(params))
      );
    } else {
      return this._httpClient.get<RetrieveBarsResult>(url, { params: params });
    }
  }

  private getNewUrlHistoryConnection(): string {
    if (this.arrayItemHistoryConnectionUrlsBalanced.length > 1 && localStorage.getItem('BalancedHistoryConnectionNumber') !== null) {

      const balancedConnectionNumber = Number(localStorage.getItem('BalancedHistoryConnectionNumber'));

      this.arrayItemHistoryConnectionUrlsBalancedLengths = this.arrayItemHistoryConnectionUrlsBalancedLengths.filter((item) => item !== balancedConnectionNumber);

      if (this.arrayItemHistoryConnectionUrlsBalancedLengths.length > 0) {
        localStorage.setItem('BalancedHistoryConnectionNumber', this.arrayItemHistoryConnectionUrlsBalancedLengths[0].toString());
      }

      return this.arrayItemHistoryConnectionUrlsBalanced[this.arrayItemHistoryConnectionUrlsBalancedLengths[0]];
    } else {
      return AppConfig.settings.connection.ItemHistoryConnectionUrl;
    }
  }

  private checkIsUseNewItemHistoryConnection(): boolean {
    if (this.isUseNewItemHistoryConnect === null) {

      let isUseNewItemHistoryConnect = AppConfig.settings.connection.UseNewItemHistoryConnection;
      const usersGeoData: UsersGeoData = JSON.parse(localStorage.getItem('usersGeoData'));

      if (usersGeoData != null && AppConfig.settings.connection.regionsToConnectTo) {
        if (AppConfig.settings.connection.regionsToConnectTo[usersGeoData.regionConnect].UseNewItemHistoryConnection) {
          isUseNewItemHistoryConnect = AppConfig.settings.connection.regionsToConnectTo[usersGeoData.regionConnect].UseNewItemHistoryConnection;
        }
      }

      console.log('UseNewItemHistoryConnection', isUseNewItemHistoryConnect);
      console.log('UrlHistoryConnection', this.UrlHistoryConnection);

      this.isUseNewItemHistoryConnect = isUseNewItemHistoryConnect;
    }
    return this.isUseNewItemHistoryConnect;
  }

  public get IsUseNewItemHistoryConnect(): boolean {
    return this.isUseNewItemHistoryConnect;
  }

  public async retrieveBars(symbolId: number,
                            tradeGroupId: number,
                            priceDirection: PriceDirection,
                            resolutionString: string,
                            rangeStartDate: number,
                            rangeEndDate: number,
                            isFirstCall: boolean,
                            retrieveMode: HistoryRetrieveModeEnum = 0): Promise<RetrieveBarsResult> {

    if (this.checkIsUseNewItemHistoryConnection()) {

      this.createArraysItemHistory();

      let params = new HttpParams();
      params = params.append('SymbolId', symbolId);
      params = params.append('TradeGroupId', tradeGroupId);
      params = params.append('PriceDirection', priceDirection);
      params = params.append('ResolutionString', resolutionString);
      params = params.append('RangeStartDate', rangeStartDate);
      params = params.append('RangeEndDate', rangeEndDate);
      params = params.append('IsFirstCall', isFirstCall);
      params = params.append('RetrieveMode', retrieveMode);

      const url = `${this._balanceLoaderService.getHistoryConnectionString()}/RetrieveBarsForPeriod`;
      const result = this._httpClient.get<RetrieveBarsResult>(url, { params: params }).pipe(
                                                  catchError(() => this.changeUrlHistoryConnection(params))
                                                );

      // @ts-ignore
      return result.toPromise();

    } else {

      if (this.State !== ConnectionState.Connected) {
        this.ConnectionUrl = this._balanceLoaderService.getHistoryConnectionString();
        await this.run();
      }

      await this._readyPromise;

      return await super.invokeMessage('RetrieveBarsForPeriod', {
        'SymbolId': symbolId,
        'TradeGroupId': tradeGroupId,
        'PriceDirection': priceDirection,
        'ResolutionString': resolutionString,
        'RangeStartDate': rangeStartDate,
        'RangeEndDate': rangeEndDate,
        'IsFirstCall': isFirstCall,
        'RetrieveMode': retrieveMode
      });

    }
  }
}

