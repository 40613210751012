import {AbstractTradeStrategy} from './abstract-trade.strategy';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {Trade} from '@common/trade/models/trade';
import {TradeService} from '@common/trade/services/trade.service';
import {Settings} from '@common/trader/models/settings';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {StopLossPipsBound, TakeProfitPipsBound} from '@common/trade/models/trade-bound';
import {Injectable} from '@angular/core';
import {SettingsService} from '@common/trader/services/settings.service';
import {Accounting} from '@common/trader/models/accounting.service';
import {MarginCalc, MarginCalcFactory} from '@common/trade/utils/calculations/margin/margin-calc-factory';
import {AdditionalData, NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {PriceFormatter} from '@common/trade/utils/price-formatter';
import {ITradePromise} from '@common/trade/models/trade-promise';
import {Factory} from '@common/shared/calculators/Margin/Factory.g';
import {IMarginCalc} from '@common/shared/calculators/Margin/IMarginCalc.g';
import {CurrencyConfig} from '@common/currency/utils/currency-config';
import {AccountCurrency} from '@common/currency/services/account.currency';

@Injectable({
  providedIn: 'root'
})
export class OneClickTradeStrategy extends AbstractTradeStrategy {
  private get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public constructor(private tradeService: TradeService,
                     private settingsService: SettingsService,
                     private notificationProvider: NotificationProviderService,
                     private accounting: Accounting) {
    super();
  }

  public async openOrder(order: TradeDTO, setStops: boolean = true) {

    // проверяет значения регулятора пользователя для выставления SL и TP
    if (this.Settings.RegularSLTP) {
      this.setStops(order, this.Settings.OneClickSL, this.Settings.OneClickTP, this.Settings.OneClickTS);
    }

    this.setPrice(order);

    if (order.Volume === 0) {
      this.setAmount(order);
    }


    const freeMargin = this.accounting.FreeMargin;
    // const marginCalculator: MarginCalc = new MarginCalcFactory().createCalculator(order.Symbol.MarginCalcType, order.Symbol.MarginCalcSymbol);
    const marginCalculator: IMarginCalc = new Factory().createCalculator(order.Symbol.MarginCalcType, order.Symbol.MarginCalcSymbol);
    const neededMargin = marginCalculator.Calculate(order.Volume, order.OpenPrice);

    if (neededMargin > freeMargin) {
      const additionalDataObj: AdditionalData = {
        icon: CurrencyConfig[AccountCurrency.Instance.ISOCurrencyName.toLowerCase()].icon,
        neededMargin: PriceFormatter.format(neededMargin, 2),
        freeMargin: PriceFormatter.format(freeMargin, 2),
      };

      this.notificationProvider.onTradeOpenError(
        `Not enough free margin, needed {${additionalDataObj.icon}}{${additionalDataObj.neededMargin}} but got {${additionalDataObj.icon}}{${additionalDataObj.freeMargin}}`,
        'NotEnoughFreeMarginNeededButGot',
        additionalDataObj);
      return;
    }

    await this.tradeService.openOrder(order);
  }

  public async closeTrade(trade: Trade): Promise<ITradePromise> {
    const dto = new TradeDTO().readFromTrade(trade);
    return  await this.tradeService.closeTrade(dto);
  }

  protected setStops(order: TradeDTO, sl: number, tp: number, ts: number) {
    if (sl) {
      const slBound = new StopLossPipsBound(order);
      slBound.NativeValue = sl;
      order.StopLoss = slBound.Price;
    }
    if (tp) {
      const tpBound = new TakeProfitPipsBound(order);
      tpBound.NativeValue = tp;
      order.TakeProfit = tpBound.Price;
    }
    if (ts) {
      order.TrailingStop = ts;
    }
  }

  protected setAmount(order: TradeDTO) {
    order.Volume = this.tradeService.getVolumeForOpenOrdersForOneClick(order.Symbol, order.OpenPrice, order.Type);
  }

  protected setPrice(order: TradeDTO) {
    order.OpenPrice = order.OpenPrice ? order.OpenPrice : TradePriceProvider.getTradeCurrentPrice(order.Symbol, order.Type);
  }
}
