import {TradeNotificationCreationArgs} from '@common/notification/services/notification-provider.service';

export enum NotificationType {
  Info = 'Info',
  Profit = 'Profit',
  Loss = 'Loss',
  Error = 'Error',
  BackOffice = 'BackOffice'
}

export enum ReadingType {
  Readable = 'Readable',
  AlwaysRead = 'AlwaysRead'
}

export class Notification {
  public readonly id: number;
  private readonly type: NotificationType;
  public header: string;
  public body: string;
  public time: Date;
  private readonly image: string;
  protected readingStatus: ReadingType;
  private unread: boolean;
  public from: string;
  public isRead: boolean; // new field instead of unread, need to get rid of the "unread" field later
  link: string;
  text: string;
  messageUrl: boolean;
  isOpen: boolean;

  private showMessage = '';
  private colorLastMessage = '';
  public headerInEnglish = '';
  private readonly args: TradeNotificationCreationArgs;

  constructor(header: string, headerInEnglish: string, text: string, image: string, type: NotificationType, readingStatus: ReadingType
    , isRead: boolean, SendTime: Date, id: number, args: TradeNotificationCreationArgs, from?: string) {
    // console.log(image);
    this.id = id; /*Notification.NextId;*/
    this.header = header;
    this.body = text;
    this.type = type;
    this.image = image;
    this.time = SendTime;
    this.readingStatus = readingStatus;
    this.unread = isRead;
    this.isRead = isRead;

    if (headerInEnglish) {
      this.headerInEnglish = headerInEnglish;
    }

    this.args = args;
    if (from) {
      this.from = from;
    } else {
      this.from = 'System';
    }
  }

  public get Id(): number {
    return this.id;
  }

  public get Header(): string {
    return this.header;
  }

  public get Body(): string {
    return this.body;
  }

  public get Type(): NotificationType {
    return this.type;
  }

  public get Image(): string {
    return this.image;
  }

  public get Time(): Date {
    return this.time;
  }

  public get Unread(): boolean {
    return this.unread && this.readingStatus !== ReadingType.AlwaysRead;
  }

  public set Unread(v: boolean) {
    this.unread = v;
  }

  public get ReadingStatus(): ReadingType {
    return this.readingStatus;
  }

  public get From(): string {
    return this.from;
  }

  public get IsRead(): boolean {
    return this.isRead;
  }

  public get HeaderInEnglish(): string {
    return this.headerInEnglish;
  }

  public get ShowMessage(): string {
    return this.showMessage;
  }

  public set ShowMessage(message: string) {
    this.showMessage = message;
  }

  public get ColorLastMessage(): string {
    return this.colorLastMessage;
  }

  public set ColorLastMessage(colorLastMessage: string) {
    this.colorLastMessage = colorLastMessage;
  }

  public get Args(): TradeNotificationCreationArgs {
    return this.args;
  }

  /* private static _nextId: number = 1;

   private static get NextId(): number {
     return this._nextId++;
   }*/
}
