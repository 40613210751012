import {Injectable} from '@angular/core';
import {GeneratorGuid} from '@common/trade/utils/generator-guid';
import {Environment} from '@common/environment';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {Title} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {Router} from '@angular/router';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {AppConfig} from '@common/configuration/app-config';
import {Trader} from '@common/trader/models/trader';
import {Accounting} from '@common/trader/models/accounting.service';
import {AuthenticationService} from '@common/auth/services/authentication.service';
import {CheckingLifeOfSessionService} from '@common/shared/services/checking-life-of-session.service';
import {TradeHistoryService} from '@common/shared/services/History/trade-history.service';
import {TransactionsService} from '@common/shared/services/Transactions/transactions-service';

export interface PageDetails {
  guidPage: string;
  timeCreate: string;
}

export interface PageDetailsAddEnter extends PageDetails {
  enter: string;
}

@Injectable({
  providedIn: 'root'
})

export class TerminalService {

  private userInactivityTime: number;
  private countingDown: number;
  private timeInactivity;
  private auth: AuthenticationService;
  private traderName: string;
  private checkingSleepModeService: CheckingLifeOfSessionService;
  private tradeHistoryService: TradeHistoryService;
  private transactionsService: TransactionsService;
  // сюда можно добавить любой ивент.
  private arrayEventListener = ['mousemove', 'keypress', 'click', 'touchstart', 'pointermove', 'pointerdown'];

  constructor(private titleService: Title,
              private http: HttpClient,
              private router: Router,
              private localStorage: LocalStorageService,
              private appConfig: AppConfig,
              private swUpdate: SwUpdate) {
  }

  static getGuidPageData(): string {
    const details = sessionStorage.getItem('LoginDetails');
    return details !== null ? details : '';
  }

  static getBrowserName(browserInfo: string | string[]) {
    let browser: string;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox';
    } else {
      browser = 'unknown';
    }
    return browser;
  }

  public createItem(): void {
    const guid = GeneratorGuid.createGuid();
    const platform = Environment.Platform;
    const LoginDetails: PageDetails = {
      guidPage: platform + ' ' + guid,
      timeCreate: OperationsWithDate.createTimeStamp(),
    };
    sessionStorage.setItem('LoginDetails', JSON.stringify(LoginDetails));
    console.log('guidPage: ', platform + ' ' + guid);
  }

  public addLoginDetails() {
    const LoginDetails = JSON.parse(sessionStorage.getItem('LoginDetails'));
    let guidPageData = '';

    if (LoginDetails !== null) {
      guidPageData = LoginDetails.guidPage;
    } else {
      guidPageData = GeneratorGuid.createGuid() + ' ' + Environment.Platform;
    }

    const LoginDetails2: PageDetailsAddEnter = {
      guidPage: guidPageData,
      timeCreate: OperationsWithDate.createTimeStamp(),
      enter: 'Login by login and password'
    };

    sessionStorage.setItem('LoginDetails', JSON.stringify(LoginDetails2));
  }

  private getNumberOfAppWindowsOpen(): number | null {
    return JSON.parse(localStorage.getItem('numberOfAppWindowsOpen'));
  }

  private setNumberOfAppWindowsOpen(count: number): void {
    localStorage.setItem('numberOfAppWindowsOpen', JSON.stringify(count));
  }


  public initCount(): void {
    if (Environment.Platform === 'tv-desktop') {
      let count = this.getNumberOfAppWindowsOpen();
      if (count === null) {
        this.setNumberOfAppWindowsOpen(1);
      } else {
        count ++;
        this.setNumberOfAppWindowsOpen(count);
      }
    }
  }

  public closeWindow(): void {
    let count = this.getNumberOfAppWindowsOpen();
    if (count !== null) {
      if (count === 1) {
        localStorage.removeItem('numberOfAppWindowsOpen');
      } else {
        count --;
        this.setNumberOfAppWindowsOpen(count);
      }
    }
  }

  public setTitle(symbolName: string): void {
    if (Environment.Platform === 'tv-desktop') {
      const count = this.getNumberOfAppWindowsOpen();
      const oldTitle = this.titleService.getTitle();
      const firstSplit = oldTitle.split(' - ');

      if (count > 1) {
        const newTitle = firstSplit[0] + ' - ' + symbolName;
        this.titleService.setTitle(newTitle);
      }

      if (count === 1 && firstSplit[0].length > 0) {
        this.titleService.setTitle(firstSplit[0]);
      }
    }
  }

  private getJSON(path: string): Observable<any> {
    return this.http.get(path);
  }

  public checkNewVersionApp() {
    if (this.swUpdate.isEnabled) {
      let dateRelease: number;
      this.getJSON('./ngsw.json').subscribe((data) => {
        if (data !== undefined && data.timestamp !== undefined) {
          dateRelease = data.timestamp;
        } else {
          dateRelease = new Date().getTime();
        }
      });
      const runTimer = setTimeout(() => {
        const result = JSON.parse(sessionStorage.getItem('needReloadAppNewVersion'));
        console.log('result', result);
        if (result !== null && result) {
          this.router.navigate(['/terminal', {outlets: {modal: ['update-app']}}]).then();
        }
      }, 5000);

      console.log('start runTimer()');

      this.swUpdate.versionUpdates.subscribe((ev: VersionEvent) => {
        console.log(ev);
        if (ev.type === 'VERSION_READY') {
          const version = this.appConfig.Settings.AppVersion ? this.appConfig.Settings.AppVersion : '1.0.0.0';
          this.localStorage.saveInfoUpdateApp(dateRelease, version);
          this.router.navigate(['/terminal', {outlets: {modal: ['update-app']}}]).then();
          clearTimeout(runTimer);
          console.log('stop runTimer()');
        }
      });
    }
  }

  public initServices(traderName: string,
                      checkingSleepModeService: CheckingLifeOfSessionService,
                      tradeHistoryService: TradeHistoryService,
                      transactionsService: TransactionsService): void {
    this.traderName = traderName;
    this.checkingSleepModeService = checkingSleepModeService;
    this.transactionsService = transactionsService;
    this.tradeHistoryService = tradeHistoryService;
  }

  public shouldChangePassword(trader: Trader) {
    if (trader.ShouldChangePassword) {
      setTimeout(() => {
        this.router.navigate(['/terminal', {outlets: {modal: ['change-password']}}]).then();

        trader.ChangePasswordEvent.subscribe((answer) => {
          if (answer === 'Success') {
            this.tryShowInstallPrompt();
          }
        });
      }, 200);
    } else {
      this.tryShowInstallPrompt();
    }
  }

  private tryShowInstallPrompt() {
    if (this.ShowInstallButton) {
      setTimeout(() => {
        if (Environment.Platform === 'tv-desktop') {
          this.router.navigate(['/terminal', {outlets: {modal: ['install']}}]).then();
        } else {
          this.router.navigate(['/terminal/install']).then();
        }
      }, 200);
    }
  }

  private get ShowInstallButton() {
    return this.ExtensionIsInstalled && !this.localStorage.getInstallDeclined();
  }

  public get ExtensionIsInstalled(): boolean {
    return window['deferredPrompt'];
  }

  public getAppName(): string {
    if (this.appConfig.Settings.common !== undefined && this.appConfig.Settings.common.AppName !== undefined) {
      return this.appConfig.Settings.common.AppName;
    }
  }

  public isHiddenIconTopMenu(): boolean {
    if (this.appConfig.Settings.hiddenIconTopMenu !== undefined) {
      return  this.appConfig.Settings.hiddenIconTopMenu;
    }
    return false;
  }

  public isShowButtonTheme() {
    return this.appConfig.Settings.colorThemeChangeMode !== undefined && this.appConfig.Settings.colorThemeChangeMode || this.appConfig.Settings.common.AppName === 'Zetradex';
  }

  public isShowChangePassword(): boolean {
    if (this.appConfig.Settings.showChangePassword !== undefined) {
      return this.appConfig.Settings.showChangePassword;
    }
    return true;
  }

  public isShowTradeGroupName(): boolean {
    if (this.appConfig.Settings.showTradeGroupName !== undefined) {
      return this.appConfig.Settings.showTradeGroupName;
    } else {
      return false;
    }
  }

  public initReRequestBalance(accounting: Accounting): void {
    if (this.appConfig.Settings.timeToReRequestBalance !== undefined && this.appConfig.Settings.timeToReRequestBalance !== 0 ) {
      setInterval(() => accounting.loadBalance(), this.appConfig.Settings.timeToReRequestBalance );
    }
  }

  public getUrlTopUpYourDeposit(): string {
    if (this.appConfig.Settings.customIntegrationSetting && this.appConfig.Settings.customIntegrationSetting.topUpYourDeposit ) {
      return  this.appConfig.Settings.customIntegrationSetting.topUpYourDeposit;
    }
    return undefined;
  }

  public getClaimBonusUrl(): string {
    if (this.appConfig.Settings.customIntegrationSetting && this.appConfig.Settings.customIntegrationSetting.claimBonusUrl ) {
      return  this.appConfig.Settings.customIntegrationSetting.claimBonusUrl;
    }
    return undefined;
  }

  public isHideDaysGainSection(): boolean {
    if (this.appConfig.Settings.hideDaysGainSection !== undefined ) {
      return this.appConfig.Settings.hideDaysGainSection;
    }
    return false;
  }

  public initUserInactivityTime(auth: AuthenticationService) {
    this.auth = auth;
    if (this.appConfig.Settings.userInactivityTime !== undefined && this.appConfig.Settings.userInactivityTime > 0) {
      this.userInactivityTime = this.appConfig.Settings.userInactivityTime;
      this.countingDown = 60000 * this.appConfig.Settings.userInactivityTime;
      this.actionEventListener('addEventListener');
      this.resetTimer();
    }
  }

  public checkYouNeedAnActivityCheck(): boolean {
    return this.countingDown !== undefined && this.countingDown > 0;
  }

  // события для проверки активности пользователя
  private actionEventListener(action: string) {
    this.arrayEventListener.forEach((typeEvent) => {
      window[action](typeEvent, this.resetTimer.bind(this), {capture: true});
    });
  }

  private resetTimer() {

    console.log('There is user activity in the app.');

    if (this.timeInactivity) {
      clearTimeout(this.timeInactivity);
      this.timeInactivity = undefined;
    }

    this.timeInactivity = setTimeout(() => {
      if (this.auth !== undefined) {
        console.log(`Logged out due to inactivity ${this.userInactivityTime} min(s)`);
        sessionStorage.setItem('LoggedOutDueToInactivity', this.userInactivityTime.toString());
        this.signOut().then();
        this.actionEventListener('removeEventListener');
      }
    }, this.countingDown);
  }

  public onEventIFrameBody(): void {

    if (this.userInactivityTime) {
      this.resetTimer();
    }
  }

  async signOut() {
    this.checkingSleepModeService.logout();
    this.tradeHistoryService.destroyArray();
    this.transactionsService.destroyArray();
    this.auth.clearUsersInSystem(this.traderName);
    await this.auth.logout();
  }

  public async install() {
    window['deferredPrompt'].prompt();
    window['deferredPrompt']['userChoice'].then((choiceResult) => {
      if (choiceResult['outcome'] === 'accepted') {
        this.localStorage.saveInstallDeclined(false);
      } else {
        this.localStorage.saveInstallDeclined(true);
      }
      window['deferredPrompt'] = null;
    });
  }

  public dontInstall() {
    this.localStorage.saveInstallDeclined(true);
  }

  public StateConnectionServerEvent() {
    return this.checkingSleepModeService.StateConnectionServerEvent();
  }

}
